import { LogoSmall } from '../../images/logo-small';

import {
  AppDescription,
  AppTitle,
  FormContainer,
  LoginFormContainer,
  LoginPageInner,
  LoginText,
  OuterLayout,
  TextContainer,
} from './styles';

type Props = {
  loginForm: JSX.Element;
};

export function LoginPageLayout({ loginForm }: Props) {
  return (
    <OuterLayout>
      <LoginFormContainer>
        <LoginPageInner>
          <LogoSmall />
          <TextContainer>
            <AppTitle>eTrace</AppTitle>
            <AppDescription>
              система мониторинга электротранспорта
            </AppDescription>
            <LoginText>Войти в аккаунт</LoginText>
          </TextContainer>
          <FormContainer>{loginForm}</FormContainer>
        </LoginPageInner>
      </LoginFormContainer>
    </OuterLayout>
  );
}
