import { InputHTMLAttributes } from 'react';
import { Checkmark, LabelContainer } from './styles';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  handleClick?: (val: boolean) => void;
};

export function UiRoundedCheckbox(props: Props) {
  const { handleClick, ...rest } = props;

  return (
    <LabelContainer>
      <input
        type="checkbox"
        {...rest}
        // {...(handleClick
        //   ? {
        //       onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        //         handleClick(e.target.checked);
        //       },
        //     }
        //   : {})}
      />
      <Checkmark />
    </LabelContainer>
  );
}
