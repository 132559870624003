import { FC } from 'react';
import { Tooltip } from 'antd';
import { Port } from '@shared/api/services/integration/api';
import { portStatusNamingMap } from '@entities/charging-station/consts/port-status-naming-map';
import { PortMarker, PortMarkersWrapper } from './styles';

type Props = {
  ports: Port[];
  stationId?: string | null;
  size: string;
};

export const PortsMarkers: FC<Props> = ({ ports, stationId, size }) => {
  return (
    <PortMarkersWrapper>
      {ports.map(({ number, status }) => {
        return (
          <Tooltip
            title={
              <>
                <p>УБЗС: {stationId ?? 'Нет данных'}</p>
                <p>Купол: {number}</p>
                <p>Статус: {portStatusNamingMap[status]}</p>
              </>
            }
          >
            <PortMarker portStatus={status} size={size} />
          </Tooltip>
        );
      })}
    </PortMarkersWrapper>
  );
};
