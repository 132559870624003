import { DatePicker } from 'antd';

import { StyledDateTimePicker } from './styles';

const CUSTOM_FORMAT = 'HH:mm, DD.MM.YY';
const SHOW_TIME_FORMAT = 'HH:mm';

type Props = React.ComponentProps<typeof DatePicker>;

export function CustomDatetimepicker(props: Props) {
  return (
    <StyledDateTimePicker
      {...props}
      showTime={{ format: SHOW_TIME_FORMAT }}
      format={CUSTOM_FORMAT}
      placeholder="Выбор даты"
    />
  );
}
