import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { AuthState, initialState } from '../../../model/auth';

const slice = createSlice({
  name: 'storage-slice',
  initialState,
  reducers: {
    setCredentials: (state, { payload }: PayloadAction<AuthState>) => {
      return { ...state, ...payload };
    },
    clearCredentials: (_state) => {
      return initialState;
    },
  },
});

export const { setCredentials, clearCredentials } = slice.actions;

export const selectAccessToken = (state: RootState) => state.auth.access;

export default slice.reducer;
