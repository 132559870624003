import { Divider, Select } from 'antd';
import styled from 'styled-components';

export const StyledAntdSelect = styled(Select)`
  color: #a3aec4;
  //
  height: unset !important;
  //

  &.ant-select-focused .ant-select-selector {
    box-shadow: none !important;
  }

  //
  //

  &.ant-select-open
    .ant-select-selector
    .ant-select-selection-overflow
    .ant-select-selection-overflow-item:not(:first-child) {
    display: none;
  }

  &:not(.ant-select-open)
    .ant-select-selector
    .ant-select-selection-overflow
    .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-rest):not(.ant-select-selection-overflow-item-suffix):not(:first-child)
    > span::before {
    content: ',';
  }

  //
  //
  & .ant-select-selection-overflow-item {
    color: white !important;
  }

  // Показывать один элемент "Выбрано: Х" при открытом селекте
  &.ant-select-open
    .ant-select-selector
    .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
    display: none;
  }

  & .ant-select-selector {
    border: 2px solid #666e7e !important;
    color: #f7faff;
    border-radius: 12px;
    padding: 10px !important;
    background-color: transparent !important;

    font-size: 15px;
    line-height: 120%;

    & .ant-select-selection-placeholder {
      line-height: 18px;
      color: #a3aec4;
    }

    & .ant-select-selection-overflow {
      height: 18px;

      & .ant-select-selection-overflow-item-suffix {
        display: none;
      }
    }
  }

  /* & .ant-select-selector.ant-select-focused {
    & .ant-select-selector {
      box-shadow: none !important;
      border: 2px solid #09fba8;
      border-radius: 12px 12px 0 0;
      border-color: #09fba8 !important;
    }

    & {
      border-radius: 12px 12px 0 0;
    }
  } */

  &:hover .ant-select-selector {
    border: 2px solid #a3aec4 !important;
    color: #f7faff;
    border-radius: 12px;
  }

  & .ant-select-selection-placeholder {
    color: #a3aec4;
  }

  /* &.ant-select-open:hover .ant-select-selector {
    border: none;
  } */

  &.ant-select-open .ant-select-selector {
    border: 2px solid #09fba8 !important;
    border-radius: 12px 12px 0 0;
  }

  &.ant-select-open span.ant-select-arrow {
    color: #09fba8;
    transform: rotate(180deg);
  }

  &.ant-select-open.ant-select-focused {
    box-shadow: none;

    & .ant-select-selector {
      box-shadow: none !important;
      border: 2px solid #09fba8;
      border-radius: 12px 12px 0 0;
      border-color: #09fba8 !important;
    }

    & {
      border-radius: 12px 12px 0 0;
    }
  }

  & .ant-select-selector span.ant-select-selection-item {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 18px */
    color: #f7faff;
  }

  & span.ant-select-arrow {
    color: #a3aec4;
  }

  &:hover {
    // border: 2px solid #a3aec4;
    color: #f7faff;
  }

  &:focus {
    // border: 2px solid #09fba8;
    color: #f7faff;
    outline: none;
  }

  & .ant-select-selector:after {
    display: none;
  }

  &.ant-select-open
    .ant-select-selector
    .ant-select-selection-overflow
    .ant-select-selection-overflow-item
    .ant-select-selection-item {
    padding: 0;
    margin: 0;
    background-color: #32353f;
  }

  &
    .ant-select-selector
    .ant-select-selection-overflow
    .ant-select-selection-overflow-item
    .ant-select-selection-item
    .ant-select-selection-item-content {
    margin-inline-end: 0;
  }

  &
    .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest
    .ant-select-selection-item {
    padding: 0;
    margin: 0;
    border: none;
    background-color: #32353f;
    display: flex;
    align-items: center;
  }
`;

export const ClearSelectButton = styled.button`
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #f7faff;
`;

export const CustomSelectContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const SelectedCount = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #a3aec4;
`;

export const StyledDivider = styled(Divider)`
  margin: 12px 0;
  background-color: rgba(163, 174, 196, 0.4);
`;

export const SelectOptionLabel = styled.p``;

export const StyledMultipleDropdownContainer = styled.div`
  & > div.rc-virtual-list > div.rc-virtual-list-holder {
  }
`;
