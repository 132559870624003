import styled from 'styled-components';

export const StyledLayout = styled.div<{ isStadardHeader: boolean }>`
  padding: 10px 12px;
  border-radius: 12px;
  background-color: #32353f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ isStadardHeader }) => {
    if (isStadardHeader) {
      return '';
    }

    return `
      margin-left: 32px;
      margin-right: 32px;  
    `;
  }}
`;
