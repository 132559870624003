import NiceModal from '@ebay/nice-modal-react';

import { AddIcon } from '@shared/icons/add';

import { AddVehicleModal } from '@features/vehicle/add/ui/modal';

import { ButtonLabel, StyledButton } from './styles';

export function AddVehicleButton() {
  const showAddModal = () => {
    NiceModal.show(AddVehicleModal, {});
  };

  return (
    <StyledButton
      onClick={() => {
        showAddModal();
      }}
    >
      <ButtonLabel>Добавить ТС</ButtonLabel>
      <AddIcon />
    </StyledButton>
  );
}
