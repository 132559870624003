import styled from 'styled-components';

const dynamicBgColor = (props: { status_id: number }) => {
  const { status_id } = props;

  switch (status_id) {
    case 1:
      return '#1FC48B';
    case 2:
      return '#FF9A24';
    case 3:
      return 'transparent';
    case 4:
      return 'transparent';
  }
};

const dynamicBorderColor = (props: { status_id: number }) => {
  const { status_id } = props;

  switch (status_id) {
    case 1:
      return '#1FC48B';
    case 2:
      return '#FF9A24';
    case 3:
      return '#FF9A24';
    case 4:
      return '#1FC48B';
  }
};

export const StyledBadge = styled.div`
  width: 164px;
  height: 32px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #f7faff;
  border-radius: 16px;
  background-color: ${dynamicBgColor};
  border: 1px solid ${dynamicBorderColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconWrapper = styled.div`
  color: #1fc48b;
  display: flex;
`;
