import { useEffect, useMemo } from 'react';
import ElectrobusPngImage from '../images/electrobus.png';

import {
  ElectrobusWrapper,
  DirectionIconWrapper,
  DirectionIconLayout,
  DirectionIconElement,
  ElectrobusImage,
  StyledPlacemark,
  ElectrobusRoute,
} from './styles';

type Props = {
  soc: number;
  selected: boolean;
  imei: string;
  latitude: number;
  longitude: number;
  preLatitude: number;
  preLongitude: number;
};

// Функция расчёта угла поворота в радианах:

const calcAngleRotation = ({
  preLatitude,
  preLongitude,
  latitude,
  longitude,
}: {
  preLatitude?: number | null;
  preLongitude?: number | null;
  latitude: number;
  longitude: number;
}): number => {
  let absoluteLatitude;
  let durationLatitude;
  let durationLongitude;

  if (
    preLatitude !== null &&
    preLatitude !== undefined &&
    preLongitude !== null &&
    preLongitude !== undefined
  ) {
    absoluteLatitude = preLatitude;
    durationLatitude = latitude - preLatitude;
    durationLongitude = longitude - preLongitude;
  } else {
    absoluteLatitude = latitude;
    durationLatitude = 0;
    durationLongitude = 0;
  }

  return Math.atan2(
    Math.cos(absoluteLatitude) * durationLongitude,
    durationLatitude
  );
};

export function ElectrobusPlacemarkBig({
  imei,
  soc,
  latitude,
  longitude,
  preLatitude,
  preLongitude,
  selected,
}: Props) {
  const ebusTitle = imei.slice(-3).split('').reverse().join('');

  const calculatedAngleRotation = useMemo(
    () =>
      calcAngleRotation({
        latitude,
        longitude,
        preLatitude,
        preLongitude,
      }),
    [latitude, longitude, preLatitude, preLongitude]
  );

  const isToBeRight: boolean = preLongitude > longitude;

  return (
    <ElectrobusWrapper>
      <DirectionIconWrapper angleRotation={calculatedAngleRotation}>
        <DirectionIconLayout>
          <DirectionIconElement percent={soc} />
        </DirectionIconLayout>
      </DirectionIconWrapper>
      <ElectrobusImage src={ElectrobusPngImage} />
      <StyledPlacemark
        isToBeRight={isToBeRight}
        percent={soc}
        selected={selected}
      >
        <ElectrobusRoute>{ebusTitle}</ElectrobusRoute>
      </StyledPlacemark>
    </ElectrobusWrapper>
  );
}
