import styled from 'styled-components';

export const StyledUiModalOverlay = styled.div`
  position: fixed;
  background-color: rgba(31, 33, 38, 0.7);
  inset: 0px;

  display: flex;
  align-items: center;
`;

export const StyledUiModal = styled.div`
  border-radius: 20px;
  background-color: #32353f;
  color: #f7faff;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-height: calc(100vh - 20px);
  /* max-width: 990px;
  max-height: 887px;
  width: 100%; */
`;

export const StyledUiModalHeader = styled.div`
  border-bottom: 1px solid rgba(163, 174, 196, 0.4);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: relative;

  margin-left: 40px;
  margin-right: 40px;
  padding-top: 40px;
  padding-bottom: 24px;
`;

export const StyledUiModalBody = styled.div`
  overflow-y: auto;
  margin-left: 40px;
  margin-right: 40px;
  padding-top: 24px;
  // padding-bottom: 64px;
`;

export const StyledUiModalFooter = styled.div`
  border-top: 1px solid rgba(163, 174, 196, 0.4);
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-left: 40px;
  margin-right: 40px;
  padding-top: 24px;
  padding-bottom: 40px;
`;

export const StyledCloseButton = styled.button`
  cursor: pointer;
  color: #f7faff;
  position: absolute;
  top: 40px;
  right: 40px;
`;

export const DeleteModalContainer = styled.div`
  padding: 24px;
  max-width: 318px;
`;

export const DeleteModalInfo = styled.div`
  margin-bottom: 24px;
`;

export const DeleteModalTitle = styled.h4`
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #f7faff;
  margin-bottom: 8px;
`;

export const DeleteModalText = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #a3aec4;
`;
