import { z } from 'zod';

const positiveInteger = z.coerce.number().int().min(0);

const positiveIntegerInString = z.preprocess((x) => {
  console.log('x', x);
  return x ? x : undefined;
}, positiveInteger.optional());

export const FormSchema = z.object({
  stationId: z.string().optional(),
  parkNames: z.array(z.string()).optional(),
  stationName: z.string().optional(),
  stationStatuses: z.string().optional(),
  portsCount: z.array(z.number()).optional(),
  dynamicBalancingMode: z.boolean().optional(),
  powerFrom: positiveIntegerInString,
  powerTo: positiveIntegerInString,
  type: z.string().optional(),
});

export type FormSchemaType = z.infer<typeof FormSchema>;
