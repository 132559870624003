import styled from 'styled-components';
import { Select } from 'antd';
import { StyledAntdSelect } from '@shared/ui/custom-select-new/styles';
import { VehicleStatusesRead } from '@shared/api/services/common/api';

const dynamicBorderColor = (props: { status_id?: number }) => {
  const { status_id } = props;

  switch (status_id) {
    case 1:
      return '#1FC48B';
    case 2:
      return '#FF9A24';
    case 3:
      return '#FF9A24';
    case 4:
      return '#1FC48B';
    default:
      return 'transparent';
  }
};

export const ThContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const StatusStyledSelect = styled(StyledAntdSelect)<{
  vehicleStatus?: VehicleStatusesRead;
}>`
  width: 164px;
  height: 30px !important;
  border-radius: 16px;

  & .ant-select-selector {
    border-radius: 16px 16px 16px 16px;

    background-color: ${({ vehicleStatus }) => {
      return dynamicBorderColor({ status_id: vehicleStatus?.id });
    }} !important;
  }
`;
