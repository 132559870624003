import { Collapse } from 'antd';
import styled from 'styled-components';

const dynamicColor = (props: { percent: number }) => {
  const { percent } = props;

  // No information - какой кейс ?

  if (percent <= 30) {
    return '#FE4D4D';
  } else if (percent >= 31 && percent <= 70) {
    return '#FF9A24';
  } else {
    return '#1FC48B';
  }
};

export const StyledContainer = styled.div`
  width: 411px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  background-color: #32353f;
  transform: translateX(100%);
  transition: transform 0.7s ease;

  &.open {
    transform: translateX(0%);
  }

  @media screen and (max-width: 1920px) {
    width: 378px;
  }

  @media screen and (max-width: 1440px) {
    width: 329px;
  }
`;

export const VehicleInfoHeader = styled.div`
  position: relative;
  padding: 60px 24px 20px 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: inherit;

  position: sticky;
  top: 0;
  z-index: 10000;
`;

export const VehicleRouteNumber = styled.p`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: #f7faff;
`;

export const VehicleRouteName = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  color: #a3aec4;
`;

export const VehicleParams = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-items: center;
`;

export const VehicleGrn = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: #f7faff;

  @media screen and (max-width: 1440px) {
    font-size: 17px;
  }
`;

export const VehicleSoc = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${dynamicColor};
`;

export const VehicleSocPercent = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media screen and (max-width: 1920px) {
    font-size: 17px;
  }
`;

export const VerticalDivider = styled.div<{ height?: number }>`
  width: 1px;
  height: 20px;
  height: ${({ height }) => height ?? 20}px;
  background-color: #a3aec4;
`;

// TODO
export const VehicleZapasHoda = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #f7faff;
  min-width: 35px;

  @media screen and (max-width: 1920px) {
    font-size: 17px;
  }

  @media screen and (max-width: 1440px) {
    font-size: 14px;
  }
`;

export const ZapasHodaValue = styled.span`
  color: ${dynamicColor};
`;
//

export const VehicleSpeed = styled(VehicleZapasHoda)``;

export const CloseButton = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  margin-left: auto;
  align-self: start;
  color: #a3aec4;

  &:hover {
    color: #f7faff;
  }
`;

export const VehicleInfoContent = styled.div`
  padding: 20px 10px;
`;

export const StyledCollapse = styled(Collapse)`
  border: none !important;
  background: transparent;

  & .ant-collapse-item {
    & .ant-collapse-header.ant-collapse-header-collapsible-only {
      border-radius: 16px;
      align-items: center;

      & .ant-collapse-expand-icon {
        padding: 0;
        height: unset;

        & svg {
          width: 16px;

          @media screen and (max-width: 1920px) {
            width: 14px;
          }

          @media screen and (max-width: 1440px) {
            width: 11px;
          }
        }
      }
    }

    & .ant-collapse-content {
      margin-top: 4px;
    }

    & .ant-collapse-content {
      background-color: transparent !important;
      border-top: none !important;

      & .ant-collapse-content-box {
        padding: 0 0 24px 0px;
      }
    }

    & .ant-collapse-content.ant-collapse-content-active {
      & .ant-collapse-content-box {
        padding: 0 0 24px 0;
      }
    }
  }

  & .ant-collapse-item.ant-collapse-item-active {
    & .ant-collapse-header.ant-collapse-header-collapsible-only {
      background-color: rgba(31, 196, 139, 0.4);
      color: #09fba8;

      & .ant-collapse-expand-icon {
        transform: rotate(180deg);

        & svg {
          width: 16px;

          @media screen and (max-width: 1920px) {
            width: 14px;
          }

          @media screen and (max-width: 1440px) {
            width: 11px;
          }
        }
      }
    }
  }

  & .ant-collapse-item {
    & .ant-collapse-header {
      padding: 12px 16px;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      color: #f7faff;
      border-radius: 16px;
    }
  }
`;

export const DriverInfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #f7faff;

  align-items: center;
`;

export const DriverInfoKey = styled.div`
  padding: 16px 44px 16px 0;
  font-weight: 600;

  @media screen and (max-width: 1440px) {
    padding: 16px 18px 16px 0;
  }
`;

export const DriverInfoValue = styled.div`
  padding: 16px 40px 16px 0;

  @media screen and (max-width: 1440px) {
    padding: 16px 16px 16px 0;
  }
`;

export const RowBorder = styled.div`
  border-top: 1px solid rgba(163, 174, 196, 0.4);
  grid-column: 1 / 3;
`;

export const SohValue = styled.span`
  color: ${dynamicColor};
`;

export const VehicleExtraInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: #f7faff;
`;

export const VehicleExtraInfoParam = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  color: #f7faff;
`;
