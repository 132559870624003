import styled from 'styled-components';

export const StyledLayout = styled.div`
  height: 100vh;
  min-width: 1280px;
  display: grid;
  grid-template-columns: 368px auto;
  grid-template-rows: 1fr;
  grid-template-areas: 'sider main';

  @media screen and (max-width: 1920px) {
    grid-template-columns: 318px auto;
  }

  @media screen and (max-width: 1440px) {
    grid-template-columns: 262px auto;
  }
`;
