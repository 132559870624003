import styled from 'styled-components';

export const ShowRowsControlContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: #a3aec4;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
`;
