import {
  useGetManufacturersQuery,
  useGetVehicleModelsQuery,
  useGetVehicleStatusesQuery,
  useGetVehicleTypesQuery,
  useGetGroupsQuery,
} from '@shared/api/services/common/enhanced';
import { Electricbus } from '@shared/api/services/electricbus/types';

import { FormSchemaType } from '@entities/vehicle/consts/schema';

import { VehicleForm } from '../form';

type Props = {
  handleFormSubmit: (data: Partial<FormSchemaType>) => void;
  submitButtonLabel: string;
  vehicle?: Electricbus;
  loading: boolean;
  deleteButton?: JSX.Element;
};

export function ConnectedVehicleForm(props: Props) {
  const {
    data: manufacturers,
    isLoading: isManufacturersLoading,
    error: manufacturersError,
    isSuccess: isManufacturersSuccess,
  } = useGetManufacturersQuery();

  const {
    data: vehicleTypes,
    isLoading: isVehicleTypesLoading,
    error: vehicleTypesError,
    isSuccess: isVehicleTypesSuccess,
  } = useGetVehicleTypesQuery();

  const {
    data: vehicleModels,
    isLoading: isVehicleModelsLoading,
    error: vehicleModelsError,
    isSuccess: isVehicleModelsSuccess,
  } = useGetVehicleModelsQuery();

  const {
    data: vehicleStatuses,
    isLoading: isVehicleStatusesLoading,
    error: vehicleStatusesError,
    isSuccess: isVehicleStatusesSuccess,
  } = useGetVehicleStatusesQuery();

  const {
    data: parks,
    isLoading: isGroupsLoading,
    error: groupsError,
    isSuccess: isGroupsSuccess,
  } = useGetGroupsQuery();

  if (
    isManufacturersLoading ||
    isVehicleModelsLoading ||
    isVehicleTypesLoading ||
    isVehicleStatusesLoading ||
    isGroupsLoading
  ) {
    return <div>Идет загрузка....</div>;
  }

  const error = [
    manufacturersError,
    vehicleTypesError,
    vehicleModelsError,
    vehicleStatusesError,
    groupsError,
  ].find((err) => err !== undefined);

  if (error) {
    return <div>Ошибка</div>;
  }

  if (
    isVehicleTypesSuccess &&
    isVehicleModelsSuccess &&
    isManufacturersSuccess &&
    isVehicleStatusesSuccess &&
    isGroupsSuccess
  ) {
    return (
      <VehicleForm
        manufacturers={manufacturers}
        models={vehicleModels}
        types={vehicleTypes}
        statuses={vehicleStatuses}
        parks={parks}
        {...props}
      />
    );
  }

  return null;
}
