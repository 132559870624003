export function AddIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1C9 0.447715 8.55228 0 8 0C7.44771 0 7 0.447715 7 1H9ZM7 15C7 15.5523 7.44771 16 8 16C8.55228 16 9 15.5523 9 15H7ZM15 9C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7L15 9ZM1 7C0.447715 7 0 7.44771 0 8C0 8.55228 0.447715 9 1 9L1 7ZM7 1V15H9V1H7ZM15 7L1 7L1 9L15 9L15 7Z"
        fill="#A3AEC4"
      />
    </svg>
  );
}
