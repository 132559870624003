import styled from 'styled-components';

export const StyledLayout = styled.aside`
  grid-area: sider;
  display: grid;
  grid-template-rows: 120px auto 100px;
  grid-template-columns: minmax(0, 1fr);
  width: 368px;
  border-right: 1px solid rgba(163, 174, 196, 0.4);
  box-sizing: border-box;

  @media screen and (max-width: 1920px) {
    width: 318px;
  }

  @media screen and (max-width: 1440px) {
    width: 262px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 40px 28px 40px;
  width: 100%;
  border-bottom: 1px solid rgba(163, 174, 196, 0.4);
`;

export const SiderFooter = styled.div`
  padding: 26px 56px 50px 56px;
  display: flex;
`;
