import { CellContext, ColumnHelper } from '@tanstack/table-core';
import { SettingOutlined } from '@ant-design/icons';
import { TableColumnType } from '@shared/model/table';
import { VisibleColumnsModal } from '../visible-columns-modal';

// import { COLUMN_ID, COLUMN_WIDTH } from '../settings/consts';
import NiceModal from '@ebay/nice-modal-react';

// T - модель (Packet, SessionDto, etc.)
// K - ключ для колонки (по нему хранится в редаксе - видима ли колонка)

export const COLUMN_ID = 'settings';
export const COLUMN_WIDTH = 50;

type Props<T, K extends string> = {
  columnHelper: ColumnHelper<T>; // Модель (типа Packet, Dto..)
  columns: TableColumnType[]; //
  settings: Record<K, boolean>;
  renderCell: (props: CellContext<T, unknown>) => React.ReactNode;
  setVisibleColumns: (cols: Record<K, boolean>) => void;
};

export const getTableSettingsColumn = <T, K extends string>({
  columnHelper,
  columns,
  settings,
  renderCell,
  setVisibleColumns,
}: Props<T, K>) => {
  const handleSetVisibleColumns = (columns: TableColumnType[]) => {
    const visibleColumns = columns.reduce(
      (acc, el) => {
        acc[el.key] = el.isChecked;

        return acc;
      },
      { ...settings } as Record<string, boolean> // мб нужна проверка на существование || {} ?
    );

    setVisibleColumns(visibleColumns);
  };

  const onHeaderClick = () => {
    NiceModal.show(VisibleColumnsModal, {
      columns,
      onConfirm: handleSetVisibleColumns,
    });
  };

  return columnHelper.display({
    id: COLUMN_ID,
    header: () => <SettingOutlined onClick={onHeaderClick} />,
    cell: (props) => renderCell(props),
    size: COLUMN_WIDTH,
    enableSorting: false,
  });
};
