import { PaginationButton } from './button';

import { ButtonsContainer } from './styles';

type Props = {
  prevDisabled: boolean;
  nextDisabled: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
};

export function PaginationButtons({
  prevDisabled,
  nextDisabled,
  onPrevClick,
  onNextClick,
}: Props) {
  return (
    <ButtonsContainer>
      <PaginationButton
        type="prev"
        onClick={onPrevClick}
        disabled={prevDisabled}
      />
      <PaginationButton
        type="next"
        onClick={onNextClick}
        disabled={nextDisabled}
      />
    </ButtonsContainer>
  );
}
