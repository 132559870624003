import {
  useGetGroupsQuery,
  useGetManufacturersQuery,
  useGetVehicleModelsQuery,
  useGetVehicleStatusesQuery,
  useGetVehicleTypesQuery,
} from '@shared/api/services/common/enhanced';
import { TrackerWithEvOutput } from '@shared/api/services/tracker/api';
import { useGetTrackerByImeiQuery } from '@shared/api/services/tracker/enhanced';

type Props = {
  imei: string;
};

export function useData({ imei }: Props) {
  const {
    data: vehicleManufacturers = [],
    isLoading: isManufacturersLoading,
    error: manufacturersError,
    isSuccess: isManufacturersSuccess,
  } = useGetManufacturersQuery();

  const {
    data: vehicleTypes = [],
    isLoading: isVehicleTypesLoading,
    error: vehicleTypesError,
    isSuccess: isVehicleTypesSuccess,
  } = useGetVehicleTypesQuery();

  const {
    data: vehicleModels = [],
    isLoading: isVehicleModelsLoading,
    error: vehicleModelsError,
    isSuccess: isVehicleModelsSuccess,
  } = useGetVehicleModelsQuery();

  const {
    data: vehicleStatuses = [],
    isLoading: isVehicleStatusesLoading,
    error: vehicleStatusesError,
    isSuccess: isVehicleStatusesSuccess,
  } = useGetVehicleStatusesQuery();

  const {
    data: parks = [],
    isLoading: isGroupsLoading,
    error: groupsError,
    isSuccess: isGroupsSuccess,
  } = useGetGroupsQuery();

  const {
    data: trackerWithEv,
    isLoading: isTrackerLoading,
    error: trackerError,
    isSuccess: isTrackerSucccess,
  } = useGetTrackerByImeiQuery(
    { imei, isWithEv: true },
    { skip: !Boolean(imei) }
  );

  const error = [
    manufacturersError,
    vehicleTypesError,
    vehicleModelsError,
    vehicleStatusesError,
    groupsError,
  ].find((err) => err !== undefined);

  const isLoading =
    isManufacturersLoading ||
    isVehicleModelsLoading ||
    isVehicleTypesLoading ||
    isVehicleStatusesLoading ||
    isGroupsLoading;

  return {
    isLoading,
    error,
    vehicleManufacturers,
    vehicleTypes,
    vehicleModels,
    vehicleStatuses,
    parks,
    trackerWithEv: trackerWithEv as TrackerWithEvOutput,
  };

  // if (
  //   isVehicleTypesSuccess &&
  //   isVehicleModelsSuccess &&
  //   isManufacturersSuccess &&
  //   isVehicleStatusesSuccess &&
  //   isTrackerSucccess
  // ) {
  //   return (
  //     <VehicleInfo
  //       trackerWithEv={data as TrackerWithEvOutput}
  //       handleClose={handleClose}
  //       manufacturers={manufacturers}
  //       models={vehicleModels}
  //       types={vehicleTypes}
  //       statuses={vehicleStatuses}
  //       telemetry={telemetry}
  //     />
  //   );
  // }

  // return null;
}
