import { useMemo } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';

import { QUERY_PARAM } from '@shared/consts/server';
import { usePaginationState, useSortingState } from '@shared/ui/table/hooks';
import { ServerTableLayout } from '@shared/ui/table/layout';
import {
  GroupOutput,
  VehicleManufacturesRead,
  VehicleModelsRead,
  VehicleStatusesRead,
  VehicleTypesRead,
} from '@shared/api/services/common/api';
import { Electricbus } from '@shared/api/services/electricbus/types';

import { useColumns } from '../../hooks/use-columns';
import { TRANSPORT } from '@shared/consts/routes';

type VehicleTableType = 'TRANSPORT' | 'PARK' | 'CONVOY';

type Props = {
  tableData: Electricbus[];
  loading: boolean;
  count: number | undefined;
  groupId?: string;
  types: VehicleTypesRead[];
  manufacturers: VehicleManufacturesRead[];
  models: VehicleModelsRead[];
  statuses: VehicleStatusesRead[];
  parks: GroupOutput[];
  tableType: VehicleTableType;
  defaultPageSize: number;
};

export function VehiclesListTableInstance({
  tableData,
  loading,
  count,
  manufacturers,
  types,
  models,
  statuses,
  parks,
  tableType,
  defaultPageSize,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const columns = useColumns({
    manufacturers,
    types,
    models,
    statuses,
    parks,
  });

  const size = searchParams.get(QUERY_PARAM.limit);

  const sorting = useSortingState();
  const pagination = usePaginationState();

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (size ? Number(size) : defaultPageSize))
      : undefined,
  });

  const handleRowDoubleClick = (row) => {
    const convoyId = row.original.group_id;
    const vehicleId = row.original.id;
    const parkId = parks.find((park) =>
      park.children?.some(({ id }) => id === convoyId)
    )?.id!;

    if (tableType === 'TRANSPORT') {
      navigate(`${TRANSPORT}/${parkId}/${convoyId}/${vehicleId}/details`);
      return;
    }

    if (tableType === 'PARK') {
      navigate(`${TRANSPORT}/${parkId}/${convoyId}/${vehicleId}/details`);
      return;
    }

    if (tableType === 'CONVOY') {
      navigate(`${TRANSPORT}/${parkId}/${convoyId}/${vehicleId}/details`);
      return;
    }
  };

  return (
    <ServerTableLayout
      handleRowDoubleClick={handleRowDoubleClick}
      loading={loading}
      table={table}
      rowsCount={count}
      defaultPageSize={defaultPageSize}
    />
  );
}
