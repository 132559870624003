export const CHARGE_POINTS = '/charge-points';

export const TRANSPORT = '/transport';
export const ELECTROBUS_PARK = `${TRANSPORT}/:eParkId`;
export const PARK_TABLE = `${ELECTROBUS_PARK}/details`;
export const CONVOY = `${ELECTROBUS_PARK}/:convoyId`;
export const CONVOY_TABLE = `${CONVOY}/details`;
export const ELECTROBUS = `${CONVOY}/:eBusId`;
export const ELECTROBUS_DETAILS = `${ELECTROBUS}/details`;

export const DRIVERS = '/drivers';

export const SCHEDULE = '/schedule';

export const ROUTES = '/routes';

export const ANALYTICS = '/analytics';

// Техническая информация:

export const SETTINGS = '/settings';
export const TRACKERS = `${SETTINGS}/trackers`;
export const TELEMETRY = `${SETTINGS}/telemetry`;
export const CHARGING_STATIONS = `${SETTINGS}/charging-stations`;
export const COUNTERPARTIES = `${SETTINGS}/counterparties`;
export const USERS = `${SETTINGS}/users`;
