import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from '@shared/ui/ui-modal';

import { FiltersForm } from '../form';

export const FiltersModal = NiceModal.create(() => {
  const modal = useModal();

  return (
    <UiModal isOpen={modal.visible} onClose={modal.hide}>
      <UiModal.Header onClose={modal.hide}>Фильтры</UiModal.Header>
      <UiModal.Body>
        <FiltersForm closeModal={modal.hide} />
      </UiModal.Body>
      {/* <UiModal.Footer>
        <UiButton variant="outline">Сбросить</UiButton>
        <UiButton variant="primary">Применить</UiButton>
      </UiModal.Footer> */}
    </UiModal>
  );
});
