import styled from 'styled-components';

export const StyledPaginationButton = styled.button`
  display: flex;
  width: 16px;
  height: 17px;
  color: #1fc48b;
  cursor: pointer;

  &:hover {
    color: #f7faff;
  }

  &:disabled {
    color: #666e7e;
    cursor: not-allowed;
  }
`;
