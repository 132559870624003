import styled from 'styled-components';

export const StyledContainer = styled.div``;

export const ElectrobusLeafTitle = styled.p`
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  //  line-height: 120%;
  color: #f7faff;
  // margin-bottom: 8px;

  @media screen and (max-width: 1440px) {
    font-size: 12px;
  }
`;

export const ElectrobusLeafExtraInfo = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #a3aec4;

  @media screen and (max-width: 1440px) {
    font-size: 10px;
  }
`;

export const ElectrobusLeafExtraInfoParam = styled.p``;

export const VerticalDivider = styled.div`
  width: 1px;
  height: 20px;
  background-color: #a3aec4;
`;
