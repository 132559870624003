import { StyledUiButton } from './styles';
import { UiButtonVariant } from './types';

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  variant: UiButtonVariant;
};

export function UiButton({ variant, children, ...props }: Props) {
  return (
    <StyledUiButton {...props} variant={variant}>
      {children}
    </StyledUiButton>
  );
}
