export const getWebSocketURL = () => {
  const isSecuredProtocol = window.location.protocol === 'https:';

  if (window.location.hostname === 'localhost') {
    return 'wss://etrace-dev.sitelectro.com/ws/';
  }

  return `${isSecuredProtocol ? 'wss' : 'ws'}://${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }/ws/`;
};
