import { Popover } from 'antd';

import { useGetUsersInfoQuery } from '@shared/api/services/user/enhanced';
import { openErrorNotification } from '@shared/lib/notification';
import { getServerErrorText } from '@shared/lib/http/get-server-error-text';
import { CustomSpin } from '@shared/ui/custom-spin';

import {
  AvatarMock,
  StyledUserInfo,
  UserFullName,
  UserInfoContainer,
  UserRole,
} from './styles';

import { UserSettings } from '../user-settings';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

export function UserInfo() {
  const { data, isLoading, isSuccess, error } = useGetUsersInfoQuery();

  const firstName = data?.first_name || 'Имя';
  const lastName = data?.last_name || 'Фамилия';

  const role = 'Роль';

  const avatarMock = `${firstName[0] ?? ''}${lastName[0] ?? ''}`;

  const fullName = `${firstName} ${lastName}`;

  if (error) {
    const isToHiddenErrorMessage =
      (error as FetchBaseQueryError).status === 401 &&
      ((error as FetchBaseQueryError).data?.code as string) ===
        'token_not_valid' &&
      (error as FetchBaseQueryError).data?.detail ===
        'Given token not valid for any token type';

    if (!isToHiddenErrorMessage) {
      openErrorNotification(getServerErrorText(error));
    }
  }

  const content = <UserSettings />;

  return (
    <CustomSpin spinning={isLoading} delay={500}>
      <Popover
        trigger="click"
        arrow={false}
        content={content}
        placement="bottom"
        overlayStyle={{
          padding: 0,
        }}
        overlayInnerStyle={{
          padding: 0,
          backgroundColor: 'transparent',
          marginTop: '20px',
        }}
      >
        <StyledUserInfo>
          <AvatarMock>{avatarMock}</AvatarMock>
          <UserInfoContainer>
            <UserFullName>{fullName}</UserFullName>
            <UserRole>{role}</UserRole>
          </UserInfoContainer>
        </StyledUserInfo>
      </Popover>
    </CustomSpin>
  );
}
