import { Select, Tooltip } from 'antd';
import { useState } from 'react';
import type { SelectProps } from 'antd';

import { UiRoundedCheckbox } from '@shared/ui/ui-rounded-checkbox';

import { useSelectAll } from '../lib/use-select-all';

import {
  ClearSelectButton,
  CustomSelectContainer,
  SelectedCount,
  SelectOptionLabel,
  StyledAntdSelect,
  StyledDivider,
  StyledMultipleDropdownContainer,
} from './styles';

// TODO
type Props = SelectProps<string[] | number[]>;

export function CustomSelectMultiple(props: Props) {
  const [open, setOpen] = useState(false);

  const { value, onChange, options } = props;

  const selectProps = useSelectAll({
    showSelectAll: true,
    value,
    onChange,
    options,
  });

  const multipleModeProps: Props = {
    mode: 'multiple',
    maxTagCount: 'responsive',
    maxTagPlaceholder: (omittedValues) => {
      if (open) {
        return null;
      }

      return (
        <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
          <span>{` ...`}</span>
        </Tooltip>
      );
    },
    tagRender: (props) => {
      if (open && value?.length) {
        return <SelectedCount>{`Выбрано: ${value.length}`}</SelectedCount>;
      }

      return <>{props.label}</>;
    },
  };

  const restProps: React.ComponentProps<typeof Select> = {
    open,
    onDropdownVisibleChange: (visible) => {
      setOpen(visible);
    },
    placeholder: 'Выберите из списка',
    showSearch: false,
    // Отступ между селектом и дропдауном
    dropdownAlign: { offset: [0, 0] },
    menuItemSelectedIcon: null,
    removeIcon: null,
    dropdownRender: (menu) => {
      return (
        <StyledMultipleDropdownContainer
          onMouseDown={(e) => {
            e.preventDefault();
            // e.stopPropagation();
          }}
        >
          {menu}
          {
            <>
              <StyledDivider />
              <ClearSelectButton
                onClick={
                  props.onClear ? props.onClear : selectProps.handleUnselectAll
                }
              >
                Сбросить
              </ClearSelectButton>
            </>
          }
        </StyledMultipleDropdownContainer>
      );
    },
    optionRender: (option) => {
      const checked = Boolean(value && value.includes(option.value));

      return (
        <CustomSelectContainer>
          <UiRoundedCheckbox checked={checked} disabled />
          <SelectOptionLabel>{option.label}</SelectOptionLabel>
        </CustomSelectContainer>
      );
    },
  };

  return (
    <StyledAntdSelect
      {...props}
      {...selectProps}
      {...multipleModeProps}
      {...restProps}
    />
  );
}
