import { DeleteIcon } from '@shared/icons/delete';

import { StyledButton, ButtonLabel } from './styles';

type Props = {
  toShowModal: () => void;
  showIcon: boolean;
  title?: string;
};

export function DeleteButton({ title, showIcon, toShowModal }: Props) {
  return (
    <StyledButton onClick={() => toShowModal()} type="button">
      {showIcon && <DeleteIcon />}
      <ButtonLabel>{title ?? 'Удалить'}</ButtonLabel>
    </StyledButton>
  );
}
