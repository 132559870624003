import styled from 'styled-components';

export const OuterLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const LoginFormContainer = styled.div`
  border-radius: 12px;
  background: #fff;
  padding: 44px 34px;
  width: 443px;
`;

export const LoginPageInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextContainer = styled.div`
  width: 223px;
  text-align: center;
  margin-top: 30px;
`;

export const AppTitle = styled.h1`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const AppDescription = styled.p`
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
`;

export const LoginText = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #8b8d97;
  margin-bottom: 60px;
`;

export const FormContainer = styled.div`
  width: 100%;
`;
