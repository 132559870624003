export function LogoSmall() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
    >
      <path
        d="M52.4045 24.3846L30.6344 30.8077L26.2568 45.9341L4 52.5L8.59548 36.6154L30.3656 30.1923L34.7432 15.0659L57 8.5L52.4045 24.3846ZM5.31761 33.6429L9.91309 17.7582L32.1699 11.1923L27.5744 27.0769L5.31761 33.6429ZM55.9002 27.1319L51.3047 43.0165L29.0479 49.5824L33.6434 33.6978L55.9002 27.1319Z"
        fill="url(#paint0_linear_192_27391)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_192_27391"
          x1="19.9906"
          y1="53.6549"
          x2="33.0582"
          y2="12.1112"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00211111" stopColor="#23E87B" />
          <stop offset="0.2005" stopColor="#1EE48D" />
          <stop offset="0.5388" stopColor="#14DBB3" />
          <stop offset="1" stopColor="#04DADD" />
        </linearGradient>
      </defs>
    </svg>
  );
}
