import styled from 'styled-components';

// Паддинг для ячеек первой и последней колонок
const BORDER_CELL_PADDING = '24px';

export const TablaLayoutContainer = styled.div``;

export const TableContainer = styled.div`
  border-radius: 20px;
  overflow: auto;
`;

export const StyledFixedTable = styled.table`
  min-width: 100%;
  width: max-content;
  border-spacing: 0;
  border-collapse: collapse;
  background-color: #32353f;

  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  color: #f7faff;

  table-layout: fixed;
  width: 100%;

  & thead {
    // position: sticky;
    top: 0;
    color: #f7faff;
    background-color: #666e7d;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  & thead tr {
    // TODO: check
    border-bottom: 1px solid rgba(163, 174, 196, 0.4);

    // display: block;
  }

  & th {
    text-align: left;
    vertical-align: bottom;
    box-sizing: border-box;
    padding: 8px 14px 8px 0;

    &:first-child {
      padding-left: ${BORDER_CELL_PADDING};
    }

    &:last-child {
      padding-right: ${BORDER_CELL_PADDING};
      padding-left: 10px;
    }

    & .cursor-pointer {
      cursor: pointer;
    }

    &.sorted {
      // bg ?
    }

    &.settings {
      vertical-align: top;
      outline: 1px dotted red;
    }
  }

  & td {
    // width: max-content;
    padding: 0 14px 0 0;
    font-size: 14px;
    height: 35px !important;
    max-height: 35px !important;
    vertical-align: middle;
    &.sorted {
      // bg ?
    }

    &:first-child {
      padding-left: ${BORDER_CELL_PADDING};
    }

    &:last-child {
      padding-right: ${BORDER_CELL_PADDING};
      padding-left: 10px;
    }

    & span {
      width: 100%;
    }
  }

  & tbody tr {
    border-bottom: 1px solid rgba(163, 174, 196, 0.4);

    &:hover {
      background-color: rgba(163, 174, 196, 0.2);
    }

    &.clickable:hover {
      cursor: pointer;
    }
  }
`;

// export const HeaderCellContent = styled.div`
//   display: flex;
//   align-items: flex-end;
//   justify-content: flex-start;
//   margin-bottom: 4px;
// `;

// export const HeaderCellContainer = styled.div``;

// export const HeaderCellText = styled.span``;

export const ThContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 5px 0px;

  width: max-content;
`;

export const ThTitle = styled.div`
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #f7faff;
`;

export const ControlsContainer = styled.div`
  margin-top: 10px;
`;
