export function DarkThemeIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_77_38259)">
        <path
          d="M9.16419 7.43433C6.6245 7.43433 4.56567 5.3755 4.56567 2.83581C4.56567 2.37123 4.63457 1.92274 4.7627 1.5C2.87448 2.0723 1.5 3.82638 1.5 5.90149C1.5 8.44118 3.55882 10.5 6.09851 10.5C8.17362 10.5 9.9277 9.12552 10.5 7.2373C10.0773 7.36543 9.62877 7.43433 9.16419 7.43433Z"
          stroke="currentColor"
          stroke-width="2"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_77_38259">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
