import { useGetParksFromEPark } from '@shared/api/services/integration/enhanced';

export function useData() {
  const {
    data: parksFromEPark = [],
    isLoading: isParksFromEParkLoading,
    error: parksFromEParkError,
    isSuccess: isManufacturersSuccess,
  } = useGetParksFromEPark();

  const error = [parksFromEParkError].find((err) => err !== undefined);

  const isLoading = isParksFromEParkLoading;

  return {
    isLoading,
    error,
    parksFromEPark,
  };
}
