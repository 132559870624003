import styled from 'styled-components';

export const StyledMenuItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 10px;

  font-style: normal;
  font-weight: 600;
  //  line-height: 120%;
  color: white;

  & .ant-collapse-header-text {
    font-size: 17px;
    line-height: normal;

    @media screen and (max-width: 1920px) {
      font-size: 15px;
    }

    @media screen and (max-width: 1440px) {
      font-size: 12px;
    }
  }

  & a.active > div {
    color: #09fba8;
  }
`;

export const MenuItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background-color: transparent;
  /* padding: 12px 16px; */

  /* &:hover {
    background-color: rgba(163, 174, 196, 0.2);
    transition: background-color 0.3s ease;
  } */

  & .menu-item-title {
    background-color: transparent;

    /* &:hover {
      background-color: rgba(163, 174, 196, 0.2);
      transition: background-color 0.3s ease;
    } */
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 8px;
`;

export const MenuItemLabel = styled.p`
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;
