import styled from 'styled-components';

export const LabelContainer = styled.label`
  height: 20px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ span {
      background-color: #1fc48b;
      border-color: #1fc48b;
    }

    &:checked ~ span:after {
      display: block;
    }
  }
`;

// .container:hover input ~ .checkmark {
//   background-color: #ccc;
// }

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 2px solid #a3aec4;
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }

  &:after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
