import { z } from 'zod';

const positiveInteger = z.coerce.number().int().min(0);
const percent = positiveInteger.max(100);

const percentNumberInString = z.preprocess(
  (x) => (x ? x : undefined),
  percent.optional()
);
const positiveIntegerInString = z.preprocess(
  (x) => (x ? x : undefined),
  positiveInteger.optional()
);

export const FormSchema = z.object({
  typeId: z.number().optional(),
  statusEvIds: z.array(z.number()),
  modelsIds: z.array(z.number()),
  manufactureIds: z.array(z.number()),
  // for dependant group_id field
  parkId: z.number().optional(),
  groupIds: z.array(z.number()),
  socFrom: percentNumberInString,
  socTo: percentNumberInString,
  sohFrom: percentNumberInString,
  sohTo: percentNumberInString,
  mileageFrom: positiveIntegerInString,
  mileageTo: positiveIntegerInString,
  powerReserveFrom: positiveIntegerInString,
  powerReserveTo: positiveIntegerInString,
});

export type FormSchemaType = z.infer<typeof FormSchema>;
