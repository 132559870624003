import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { retry } from '@reduxjs/toolkit/dist/query';

export const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: `${
      window.location.hostname === 'localhost'
        ? // @ts-ignore
          __API_BASE__
        : window.location.origin
    }`,
    prepareHeaders: (headers, { getState }) => {
      const token =
        (getState() as RootState).auth.access ||
        (getState() as RootState).authSession.access;

      if (token && !headers.has('Authorization')) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  { maxRetries: 0 }
);
