import { createPortal } from 'react-dom';

import { CloseIcon } from '@shared/icons/close';

import {
  StyledCloseButton,
  StyledUiModal,
  StyledUiModalBody,
  StyledUiModalFooter,
  StyledUiModalHeader,
  StyledUiModalOverlay,
} from './styles';

type Props = {
  children: React.ReactNode;
  onClose: () => void;
  isOpen?: boolean;
};

export function UiModal({ children, isOpen = false, onClose }: Props) {
  const handleClick = (e) => {
    const inModal = e.target.closest('[data-id=modal]');

    if (inModal) return;

    onClose();
  };

  if (!isOpen) {
    return null;
  }

  const modal = (
    <StyledUiModalOverlay onClick={handleClick}>
      <StyledUiModal data-id="modal" onClick={(e) => e.stopPropagation()}>
        {/* <StyledCloseButton onClick={() => onClose()}>
          <CloseIcon />
        </StyledCloseButton> */}
        {children}
      </StyledUiModal>
    </StyledUiModalOverlay>
  );

  return createPortal(modal, document.getElementById('modals') as HTMLElement);
}

type HeaderProps = {
  children: React.ReactNode;
  onClose: () => void;
  style?: React.CSSProperties;
};

type BodyProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

type FooterProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
};

UiModal.Header = function UiModalHeader({
  children,
  onClose,
  style = {},
}: HeaderProps) {
  return (
    <StyledUiModalHeader style={{ ...style }}>
      <StyledCloseButton onClick={() => onClose()}>
        <CloseIcon />
      </StyledCloseButton>
      {children}
    </StyledUiModalHeader>
  );
};

UiModal.Body = function UiModalBody({ children, style = {} }: BodyProps) {
  return <StyledUiModalBody style={{ ...style }}>{children}</StyledUiModalBody>;
};

UiModal.Footer = function UiModalFooter({ children, style = {} }: FooterProps) {
  return (
    <StyledUiModalFooter style={{ ...style }}>{children}</StyledUiModalFooter>
  );
};
