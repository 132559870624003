import { Flex } from 'antd';
import { Telemetry } from '@entities/vehicle/model/telemetry';
import { FC } from 'react';
import { useData } from '../hooks/use-data';
import { VehicleStatusesRead } from '@shared/api/services/common/api';
import { LightningIcon } from '@shared/icons/lightning';

import {
  StyledContainer,
  VehicleInfoHeader,
  VehicleParams,
  VehicleSoc,
  VehicleExtraInfoParam,
  VehicleExtraInfoParamTitle,
  VehicleExtraInfoParamValue,
  FuelDistanceEndurance,
  BusRouteInfoWrapper,
  BusRouteValue,
  OutputInfoParam,
  DriverInfoContainer,
  DriverInfoKey,
  DriverInfoValue,
  RowBorder,
  StyledCollapse,
  SohValue,
} from './styles';
import { ArrowDownIcon } from '@shared/icons/arrow-down';
import { TrackerStatusBadge } from '@entities/vehicle/ui/tracker-status-badge';
import { VehicleStatusBadge } from '@entities/vehicle/ui/vehicle-status-badge';

type Props = {
  trackerTelemetry: Telemetry;
};

export const VehicleInfoExpanded: FC<Props> = ({ trackerTelemetry }) => {
  const imei = trackerTelemetry?.tracker_imei || '';

  const {
    vehicleManufacturers,
    vehicleModels,
    vehicleStatuses,
    vehicleTypes,
    trackerWithEv,
  } = useData({ imei });

  const { status: trackerStatus, ev } = trackerWithEv || {};

  const {
    garage_number,
    grn,
    group_id,
    manufacture_id,
    model_id,
    status_id,
    type_id,
  } = ev || {};

  const { soc, soh, speed, latitude, longitude } = trackerTelemetry || {};

  const modelName = vehicleModels.find((el) => el.id === model_id)?.name || '';
  const manufacturerName =
    vehicleManufacturers.find((el) => el.id === manufacture_id)?.name || '';
  const typeName = vehicleTypes.find((el) => el.id === type_id)?.name || '';
  const vehicleStatus = vehicleStatuses.find(
    (el) => el.id === status_id
  ) as VehicleStatusesRead;

  const busRouteNumber = 808;

  const DUMMY_ZAPAS_HODA = 60;

  return (
    <StyledContainer>
      <VehicleInfoHeader>
        <BusRouteInfoWrapper>
          <VehicleExtraInfoParamTitle>
            Маршрут {busRouteNumber}:
          </VehicleExtraInfoParamTitle>
          <BusRouteValue>Метро Алтуфьево, 1A → ДК Вперед</BusRouteValue>
        </BusRouteInfoWrapper>
        <VehicleParams>
          <VehicleExtraInfoParam>
            <VehicleExtraInfoParamTitle>
              Процент заряда:
            </VehicleExtraInfoParamTitle>
            <VehicleSoc percent={soc}>{soc} %</VehicleSoc>
          </VehicleExtraInfoParam>
          <VehicleExtraInfoParam>
            <VehicleExtraInfoParamTitle>Запас хода:</VehicleExtraInfoParamTitle>
            <FuelDistanceEndurance percent={DUMMY_ZAPAS_HODA}>
              {DUMMY_ZAPAS_HODA} км
            </FuelDistanceEndurance>
          </VehicleExtraInfoParam>
          <VehicleExtraInfoParam>
            <VehicleExtraInfoParamTitle>Скорость:</VehicleExtraInfoParamTitle>
            <VehicleExtraInfoParamValue>
              {speed} км/ч
            </VehicleExtraInfoParamValue>
          </VehicleExtraInfoParam>
          <OutputInfoParam>
            <VehicleExtraInfoParamTitle>Выход:</VehicleExtraInfoParamTitle>
            <VehicleExtraInfoParamValue>545</VehicleExtraInfoParamValue>
          </OutputInfoParam>
          <VehicleExtraInfoParam>
            <VehicleExtraInfoParamTitle>
              Гаражный номер:
            </VehicleExtraInfoParamTitle>
            <VehicleExtraInfoParamValue>
              {garage_number}
            </VehicleExtraInfoParamValue>
          </VehicleExtraInfoParam>
        </VehicleParams>
      </VehicleInfoHeader>
      <div
        style={{
          border: '1px solid rgba(31,196,139,0.4)',
          borderRadius: '16px',
          marginLeft: '20px',
          marginRight: '20px',
          marginTop: '10px',
        }}
      >
        <StyledCollapse
          collapsible="header"
          defaultActiveKey={['driver-info']}
          items={[
            {
              key: 'driver-info',
              label: 'Водитель',
              children: (
                <DriverInfoContainer>
                  <DriverInfoKey>ФИО</DriverInfoKey>
                  <DriverInfoValue>Фамилия Имя Отчество</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Телефон</DriverInfoKey>
                  <DriverInfoValue>+ 7 (800) 555-35-35</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Путевой лист</DriverInfoKey>
                  <DriverInfoValue>№ 6565656</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Медкомиссия</DriverInfoKey>
                  <DriverInfoValue>Допущен, 13.02.2024</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Табельный номер</DriverInfoKey>
                  <DriverInfoValue>№ 45454</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Наряд</DriverInfoKey>
                  <DriverInfoValue>№ 45454</DriverInfoValue>
                </DriverInfoContainer>
              ),
            },
          ]}
          expandIconPosition="end"
          expandIcon={(_panelProps) => {
            return <ArrowDownIcon />;
          }}
        />
      </div>
      <div
        style={{
          border: '1px solid rgba(31,196,139,0.4)',
          borderRadius: '16px',
          marginLeft: '20px',
          marginRight: '20px',
          marginTop: '10px',
        }}
      >
        <StyledCollapse
          collapsible="header"
          defaultActiveKey={['vehicle-info']}
          items={[
            {
              key: 'vehicle-info',
              label: 'Свойства ТС',
              children: (
                <DriverInfoContainer>
                  <DriverInfoKey>Тип ТС</DriverInfoKey>
                  <DriverInfoValue>{typeName}</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Статус трекера</DriverInfoKey>
                  <DriverInfoValue>
                    {trackerStatus && (
                      <TrackerStatusBadge status={trackerStatus} />
                    )}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Статус ТС</DriverInfoKey>
                  <DriverInfoValue>
                    {vehicleStatus && (
                      <VehicleStatusBadge status={vehicleStatus} />
                    )}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>
                    <Flex align="center">
                      Время последней
                      <span style={{ color: '#1FC48B' }}>
                        <LightningIcon />
                      </span>
                    </Flex>
                  </DriverInfoKey>
                  <DriverInfoValue>11:18 11.11.2024</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>
                    <Flex align="center">
                      Плановое время
                      <span style={{ color: '#1FC48B' }}>
                        <LightningIcon />
                      </span>
                    </Flex>
                  </DriverInfoKey>
                  <DriverInfoValue>11:18 11.11.2024</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Координаты</DriverInfoKey>
                  <DriverInfoValue>
                    {`${latitude} , ${longitude}`}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Производитель</DriverInfoKey>
                  <DriverInfoValue>{manufacturerName}</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Модель</DriverInfoKey>
                  <DriverInfoValue>{modelName}</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Парк</DriverInfoKey>
                  <DriverInfoValue>Красная Пахра</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Выход</DriverInfoKey>
                  <DriverInfoValue>123</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Пробег, км</DriverInfoKey>
                  <DriverInfoValue>50 004</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>SoH</DriverInfoKey>
                  <DriverInfoValue>
                    <SohValue percent={soh}>{`${soh} %`}</SohValue>
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Контрагент</DriverInfoKey>
                  <DriverInfoValue>ПАО «КАМАЗ»</DriverInfoValue>
                </DriverInfoContainer>
              ),
            },
          ]}
          expandIconPosition="end"
          expandIcon={(_panelProps) => {
            return <ArrowDownIcon />;
          }}
        />
      </div>
    </StyledContainer>
  );
};
