export function SmallVehicleIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        id="Ellipse 2487"
        cx="9"
        cy="9"
        r="7.5"
        fill="currentColor"
        stroke="white"
        strokeWidth="3"
      />
    </svg>
  );
}
