import { SECTIONS } from '@widgets/sider/consts';
import React from 'react';

import { MenuContainer, MenuList } from './styles';

export function SiderNavMenu() {
  return (
    <MenuContainer>
      <MenuList>
        {SECTIONS.map((el, idx) => (
          <React.Fragment key={idx}>{el.element}</React.Fragment>
        ))}
      </MenuList>
    </MenuContainer>
  );
}
