import styled from 'styled-components';
import { stationStatusColorMap } from '../../consts/station-status-color-map';
import { StationStatusEnum } from '@shared/api/services/integration/api';

export const StyledBadge = styled.div<{ status: StationStatusEnum }>`
  width: 164px;
  height: 32px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #f7faff;
  border-radius: 16px;
  background-color: ${({ status }) => stationStatusColorMap[status]};
  display: flex;
  justify-content: center;
  align-items: center;
`;
