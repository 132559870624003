import NiceModal from '@ebay/nice-modal-react';

import { EditIcon } from '@shared/icons/edit';
import { EditTrackerModal } from '../modal';

import { StyledButton } from './styles';
import { TrackerOutput } from '@shared/api/services/tracker/api';

type Props = {
  tracker: TrackerOutput; // TODO:
};

export function EditTrackerButton({ tracker }: Props) {
  const showModal = () => {
    NiceModal.show(EditTrackerModal, { tracker });
  };

  return (
    <StyledButton onClick={() => showModal()}>
      <EditIcon />
    </StyledButton>
  );
}
