export const idTitleColumnMap: Record<string, string> = {
  status_id: 'Статус ТС',
  tracker_status: 'Статус трекера',
  garage_number: 'Гаражный номер',
  grn: 'ГРЗ',
  soc: 'SoC',
  soh: 'SoH',
  output_number: 'Номер выхода',
  counterparty: 'Контрагент',
  type_id: 'Тип ТС',
  driver: 'Водитель',
  phone: 'Телефон',
  route: 'Маршрут',
  last_charge_time: 'Время последней зарядки',
  manufacture_id: 'Производитель',
  model_id: 'Модель',
  group_id: 'Парк',
  tracker_id: 'ID трекера',
};
