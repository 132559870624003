import { NavLink } from 'react-router-dom';

import { MenuItemTitle, StyledMenuItem } from './styles';

type Props = {
  path: string;
  title: React.ReactNode;
  content?: React.ReactNode;
};

export function SiderNavMenuItemLayout({ title, content, path }: Props) {
  return (
    <StyledMenuItem>
      <NavLink to={path} key={path}>
        <MenuItemTitle>{title}</MenuItemTitle>
      </NavLink>
      {content}
    </StyledMenuItem>
  );
}
