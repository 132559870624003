import { Table } from '@tanstack/react-table';

import { useTableState } from '../hooks/use-table-state';
import { PaginationControls } from './pagination';
import { ShowRowsControl } from './show-rows';

import { TableControlsContainer } from './styles';

type Props = {
  table: Table<any>;
  rowsCount: number;
  pageSize: number;
};

export function TableControls({ table, rowsCount, pageSize }: Props) {
  const {
    handlePageSizePressEnter,
    handleGoToPagePressEnter,
    nextDisabled,
    prevDisabled,
    onNextClick,
    onPrevClick,
    pagesCount,
    currentPage,
  } = useTableState({
    table,
  });

  return (
    <TableControlsContainer>
      <ShowRowsControl
        handleEnterPress={handlePageSizePressEnter}
        rowsCount={rowsCount}
        size={pageSize}
      />
      <PaginationControls
        currentPage={currentPage}
        handleEnterPress={handleGoToPagePressEnter}
        pagesCount={pagesCount}
        prevDisabled={prevDisabled}
        nextDisabled={nextDisabled}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
      />
    </TableControlsContainer>
  );
}
