import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from '@app/hooks';

import { selectStationsFilters } from '@shared/redux/slices/charging-station/filters';
import {
  EParkSortEnum,
  SortDirection,
  StationStatusEnum,
} from '@shared/api/services/integration/api';
import { useGetChargingStationsQuery } from '@shared/api/services/integration/enhanced';
import { QUERY_PARAM } from '@shared/consts/server';
import { ChargingStationsTableInstance } from '../table';
import { countPageSize } from '@shared/ui/table/lib/count-page-size';

export function ConnectedChargingStationsTable() {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    stationId,
    parkNames,
    stationName,
    stationStatuses,
    portsCount,
    dynamicBalancingMode,
    powerFrom,
    powerTo,
  } = useAppSelector(selectStationsFilters);

  const page = searchParams.get(QUERY_PARAM.page);
  const size = searchParams.get(QUERY_PARAM.limit);
  const ordering = searchParams.get(
    QUERY_PARAM.ordering
  ) as EParkSortEnum | null;
  const type = searchParams.get(QUERY_PARAM.type) as SortDirection | null;

  const defaultPageSize = countPageSize();

  const {
    data: chargingStations,
    isLoading,
    isFetching,
    isSuccess,
    error,
  } = useGetChargingStationsQuery({
    stationId,
    parkNames: (parkNames !== undefined && parkNames.length > 0
      ? parkNames
      : (undefined as string | undefined)) as string | undefined,
    stationName,
    stationStatuses: (stationStatuses !== undefined
      ? stationStatuses
      : undefined) as StationStatusEnum[] | undefined,
    portsCount: (portsCount !== undefined && portsCount.length > 0
      ? portsCount
      : (undefined as string | undefined)) as string | undefined,
    dynamicBalancingMode,
    powerFrom: powerFrom !== undefined ? powerFrom * 1000 : undefined,
    powerTo: powerTo !== undefined ? powerTo * 1000 : undefined,
    sortedField: ordering ?? undefined,
    sortedBy: type ?? undefined,
    page: page ? Number(page) : undefined,
    size: size ? Number(size) : defaultPageSize,
  });

  const tableData = useMemo(() => {
    if (!chargingStations) {
      return [];
    }

    return chargingStations.results;
  }, [chargingStations]);

  if (isLoading) {
    return <div>Идет загрузка....</div>;
  }

  if (error) {
    return <div>Ошибка: не удалось загрузить данные по станциям</div>;
  }

  if (isSuccess) {
    return (
      <ChargingStationsTableInstance
        tableData={tableData}
        fetching={isFetching && !isLoading}
        count={chargingStations?.count ?? 1}
        defaultPageSize={defaultPageSize}
      />
    );
  }

  return <>Не удалось загрузить данные по станциям</>;
}
