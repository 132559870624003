import { notification } from 'antd';

import {
  IconType,
  NotificationPlacement,
} from 'antd/es/notification/interface';

export interface NotificationProps {
  placement: NotificationPlacement;
  type: IconType;
  message: string;
  description: string;
}

export const openNotification = ({
  description,
  message,
  placement,
  type,
}: NotificationProps) => {
  notification.destroy();
  notification[type]({
    message,
    description,
    placement,
  });
};

export const openSuccessNotification = (description: string) => {
  const notification: NotificationProps = {
    message: `Успешно`,
    description,
    placement: 'bottomRight',
    type: 'success',
  };

  openNotification(notification);
};

export const openErrorNotification = (description: string) => {
  const notification: NotificationProps = {
    message: `Ошибка`,
    description,
    placement: 'bottomRight',
    type: 'error',
  };

  openNotification(notification);
};
