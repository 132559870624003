import { UserInfo } from '@entities/user/ui';

import { StyledLayout } from './styles';

type Props = {
  content: JSX.Element;
  isStadardHeader: boolean;
};

export function HeaderLayout({ content, isStadardHeader }: Props) {
  return (
    <StyledLayout isStadardHeader={isStadardHeader}>
      {content}
      <UserInfo />
    </StyledLayout>
  );
}
