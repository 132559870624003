import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import NiceModal from '@ebay/nice-modal-react';

// import 'antd/dist/antd.css';

import { GlobalStyles } from './styles';

import { AppRouter } from '@app/router';
import { persistor, store } from '@app/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <GlobalStyles />
        <NiceModal.Provider>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#1FC48B',
              },
            }}
          >
            <AppRouter />
          </ConfigProvider>
        </NiceModal.Provider>
      </BrowserRouter>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);
