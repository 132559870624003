import styled from 'styled-components';

const dynamicColor = (props: { percent: number }) => {
  const { percent } = props;

  // No information - какой кейс ?

  if (percent <= 30) {
    return '#FE4D4D';
  } else if (percent >= 31 && percent <= 70) {
    return '#FF9A24';
  } else {
    return '#1FC48B';
  }
};

export const StyledCell = styled.span`
  color: ${dynamicColor};
`;

export const NoInfo = styled.p`
  color: #fe4d4d;
`;
