import { NavLink } from 'react-router-dom';

import { MenuItemContainer, StyledMenuItem, NavLinkStyledIcon } from './styles';

type Props = {
  path: string;
  content?: React.ReactNode;
};

export function EBusSiderNavMenuItemLayout({ content, path }: Props) {
  return (
    <StyledMenuItem>
      <NavLinkStyledIcon to={`${path}/details`} key={`${path}/details`} />
      <NavLink to={`${path}`} key={path}>
        <MenuItemContainer>{content}</MenuItemContainer>
      </NavLink>
    </StyledMenuItem>
  );
}
