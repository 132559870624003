import { baseApi as api } from '../../../base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStationsApiEparkStationsGet: build.query<
      GetStationsApiEparkStationsGetApiResponse,
      GetStationsApiEparkStationsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/epark/stations`,
        params: {
          size: queryArg.size,
          station_id: queryArg.stationId,
          park_names: queryArg.parkNames,
          station_name: queryArg.stationName,
          station_statuses: queryArg.stationStatuses,
          ports_count: queryArg.portsCount,
          dynamic_balancing_mode: queryArg.dynamicBalancingMode,
          power_from: queryArg.powerFrom,
          power_to: queryArg.powerTo,
          sorted_field: queryArg.sortedField,
          sorted_by: queryArg.sortedBy,
          page: queryArg.page,
        },
      }),
    }),
    getStationsListApiEparkStationsListGet: build.query<
      GetStationsListApiEparkStationsListGetApiResponse,
      GetStationsListApiEparkStationsListGetApiArg
    >({
      query: () => ({ url: `/api/epark/stations/list` }),
    }),
    getParkNamesApiEparkParksGet: build.query<
      GetParkNamesApiEparkParksGetApiResponse,
      GetParkNamesApiEparkParksGetApiArg
    >({
      query: () => ({ url: `/api/epark/parks` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetStationsApiEparkStationsGetApiResponse =
  /** status 200 Successful Response */ StationsEParkOutput;
export type GetStationsApiEparkStationsGetApiArg = {
  size: number;
  stationId?: string | null;
  /** List park names */
  parkNames?: string | null;
  stationName?: string | null;
  stationStatuses?: string | null;
  portsCount?: string | null;
  dynamicBalancingMode?: boolean | null;
  powerFrom?: number | null;
  powerTo?: number | null;
  sortedField?: EParkSortEnum | null;
  sortedBy?: SortDirection | null;
  page?: number | null;
};
export type GetStationsListApiEparkStationsListGetApiResponse =
  /** status 200 Successful Response */ StationOutput[];
export type GetStationsListApiEparkStationsListGetApiArg = void;
export type GetParkNamesApiEparkParksGetApiResponse =
  /** status 200 Successful Response */ Park[];
export type GetParkNamesApiEparkParksGetApiArg = void;
export type StationStatusEnum =
  | 'READY'
  | 'OFFLINE'
  | 'UNAVAILABLE'
  | 'DEFECTIVE';
export type PortStationEnum =
  | 'READY'
  | 'CHARGING'
  | 'UNAVAILABLE'
  | 'DEFECTIVE'
  | 'OFFLINE';
export type Port = {
  number: number;
  status: PortStationEnum;
};
export type StationOutput = {
  name: string;
  station_manufacturer: string;
  station_id?: string | null;
  location?: string | null;
  max_power?: number | null;
  latitude?: string | null;
  longitude?: string | null;
  park: string;
  park_group: string;
  dynamic_balancing_mode: boolean;
  status: StationStatusEnum;
  ports: Port[];
};
export type StationsEParkOutput = {
  count: number;
  results: StationOutput[];
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type EParkSortEnum =
  | 'station_id'
  | 'park'
  | 'name'
  | 'status'
  | 'ports'
  | 'dynamic_balancing_mode'
  | 'max_power';
export type SortDirection = 'asc' | 'desc';
export type Park = {
  name: string;
};
export const {
  useGetStationsApiEparkStationsGetQuery,
  useGetStationsListApiEparkStationsListGetQuery,
  useGetParkNamesApiEparkParksGetQuery,
} = injectedRtkApi;
