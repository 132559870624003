import { useGetTrackersModelsQuery } from '@shared/api/services/common/enhanced';
import { FormSchemaType } from '@entities/tracker/consts/schema';
import { TrackerOutput } from '@shared/api/services/tracker/api';
import { TrackerForm } from '../form';

type Props = {
  handleFormSubmit: (data: Partial<FormSchemaType>) => void;
  submitButtonLabel: string;
  tracker?: TrackerOutput;
  loading: boolean;
  deleteButton?: JSX.Element;
};

export function ConnectedTrackerForm(props: Props) {
  const {
    data: trackersModels,
    error: trackersModelsError,
    isLoading: areTrackersModelsLoading,
    isSuccess: areTrackersModelsSuccess,
  } = useGetTrackersModelsQuery();

  if (areTrackersModelsLoading) {
    return <div>Идет загрузка....</div>;
  }

  const error = [trackersModelsError].find((err) => err !== undefined);

  if (error) {
    return <div>Ошибка</div>;
  }

  if (areTrackersModelsSuccess) {
    return <TrackerForm models={trackersModels} {...props} />;
  }

  return null;
}
