import { Input } from 'antd';

import { StyledAntdInput } from './styles';
import { UiInputSize } from './types';

type Props = React.ComponentProps<typeof Input> & {
  inputSize?: UiInputSize;
};

export function CustomInput({ inputSize = 'md', ...rest }: Props) {
  return <StyledAntdInput {...rest} inputSize={inputSize} />;
}
