export function PrevPageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M11.5 15.5L4.85355 8.85355C4.65829 8.65829 4.65829 8.34171 4.85355 8.14645L11.5 1.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
