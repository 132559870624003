import { enhancedApi as api } from '../api';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    // getElectricbusesApiElectricbusGet: { providesTags: ['Electricbuses'] },
    apiAuthTokenAuthCreate: (endpoint) => {
      // endpoint.query = ({}) => `electricbus/api/electricbus`;
    },
    apiUsersSelfRetrieve: (endpoint) => {},
  },
});

export const {
  useApiAuthTokenAuthCreateMutation: useLoginMutation,
  useApiUsersSelfRetrieveQuery: useGetUsersInfoQuery,
} = enhancedApi;
