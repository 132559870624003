import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { baseQueryWithReauth } from './base-query-with-reauth';

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Electricbuses',
    'VehicleManufacturers',
    'VehicleTypes',
    'VehicleModels',
    'VehicleGroups',
    'VehicleStatuses',
    'ChargingStations',
    'ParksFromEPark',
    'Trackers',
    'Firmwares',
    'TrackerModels',
    'ListTrackers',
  ],
  endpoints: (builder) => ({}),
});
