import { Collapse } from 'antd';
import styled from 'styled-components';

const baseHeightOfLogoAndFooter = '220px';

export const MenuContainer = styled.nav`
  padding: 28px 10px;
  border-bottom: 1px solid rgba(163, 174, 196, 0.4);
  height: calc(100vh - ${baseHeightOfLogoAndFooter});
  overflow-y: auto;
  width: 100%;
`;

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;

  & a.active > li > div > p {
    background-color: rgba(21, 233, 160, 0.4);
    color: #09fba8;
  }
`;

export const SiderMenuItemLabel = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background-color: transparent;
  padding: 12px 16px;

  &:hover {
    background-color: rgba(163, 174, 196, 0.2);
    transition: background-color 0.3s ease;
  }
`;

export const StyledCollapse = styled(Collapse)`
  width: 100%;

  &.active {
    & > .ant-collapse-item > .ant-collapse-header {
      background-color: rgba(21, 233, 160, 0.4);
      color: #09fba8;
    }
  }

  &:not(.active) {
    & > .ant-collapse-item > .ant-collapse-header:hover {
      background-color: rgba(163, 174, 196, 0.2);
      transition: background-color 0.3s ease;
    }
  }

  & > .ant-collapse-item {
    & > .ant-collapse-header {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      color: #f7faff;
      align-items: center;

      padding: 6px 16px 6px;
      border-radius: 8px !important;

      & .ant-collapse-expand-icon {
        padding: 0;
        height: unset;

        & svg {
          width: 16px;

          @media screen and (max-width: 1920px) {
            width: 14px;
          }

          @media screen and (max-width: 1440px) {
            width: 11px;
          }
        }
      }
    }

    /* & > .ant-collapse-content {
      margin-top: 28px;

      padding: 0 25px 20px 16px;

      & .ant-collapse-content-box {
        padding: 0;
      }
    } */

    & > .ant-collapse-content {
      & .ant-collapse-content-box {
        padding: 5px 16px 10px 30px;
      }
    }

    &.ant-collapse-item-active
      > .ant-collapse-header
      > .ant-collapse-expand-icon {
      color: #09fba8;
      transform: rotate(180deg);
      padding: 0;
    }
  }

  &.no-content > #vehicles-menu > .ant-collapse-content {
    margin: 0;
    padding: 0;
  }
`;
