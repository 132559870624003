import { enhancedApi as api } from '../api';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getStationsApiEparkStationsGet: (endpoint) => {
      endpoint.query = (params) => {
        const {
          stationId,
          parkNames,
          stationName,
          stationStatuses,
          portsCount,
          dynamicBalancingMode,
          powerFrom,
          powerTo,
          sortedField,
          sortedBy,
          page,
          size,
        } = params;

        return {
          url: `integration/api/epark/stations`,
          params: {
            station_id: stationId,
            park_names: parkNames,
            station_name: stationName,
            station_statuses: stationStatuses,
            ports_count: portsCount,
            dynamic_balancing_mode: dynamicBalancingMode,
            power_from: powerFrom,
            power_to: powerTo,
            sorted_field: sortedField,
            sorted_by: sortedBy,
            page: page,
            size: size,
          },
        };
      };
      endpoint.providesTags = ['ChargingStations'];
    },
    getParkNamesApiEparkParksGet: (endpoint) => {
      endpoint.query = (params) => {
        return { url: `integration/api/epark/parks` };
      };
      endpoint.providesTags = ['ParksFromEPark'];
    },
    getStationsListApiEparkStationsListGet: (endpoint) => {
      endpoint.query = () => {
        return { url: `integration/api/epark/stations/list` };
      };
    },
  },
});

export const {
  useGetStationsApiEparkStationsGetQuery: useGetChargingStationsQuery,
  useGetParkNamesApiEparkParksGetQuery: useGetParksFromEPark,
  useGetStationsListApiEparkStationsListGetQuery: useGetStationsListQuery,
} = enhancedApi;
