import { Input } from 'antd';
import styled from 'styled-components';

import { UiInputSize } from './types';

const dynamicPadding = (props: { inputSize: UiInputSize }) => {
  const { inputSize } = props;

  if (inputSize === 'sm') {
    return '6px 10px';
  }

  if (inputSize === 'md') {
    return '10px';
  }
};

export const StyledAntdInput = styled(Input)`
  width: 58px;
  border-radius: 12px;
  border: 2px solid #666e7e;
  padding: ${dynamicPadding};
  // padding: 8px 16px;
  background-color: transparent;
  color: #a3aec4;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  &::placeholder {
    color: #a3aec4;
  }

  &:hover {
    border: 2px solid #a3aec4;
    color: #f7faff;
    background-color: transparent;
  }

  &:focus {
    border: 2px solid #09fba8;
    color: #f7faff;
    outline: none;
    background-color: transparent;
  }
`;
