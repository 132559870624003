import { DatePicker } from 'antd';
import styled from 'styled-components';

export const StyledDateTimePicker = styled(DatePicker)`
  background-color: transparent;
  color: #f7faff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding: 10px;
  border: 2px solid #666e7e;
  border-radius: 12px;

  &:hover {
    border: 2px solid #a3aec4;
    background-color: transparent;
  }

  & div.ant-picker-input {
    & input::placeholder {
      color: #a3aec4;
    }

    & .ant-picker-suffix {
      color: #a3aec4;
    }
  }
`;
