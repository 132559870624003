import { Flex, Tooltip } from 'antd';

import { CloseIcon } from '@shared/icons/close';
import { LightningIcon } from '@shared/icons/lightning';
import { VehicleStatusesRead } from '@shared/api/services/common/api';
import { TrackerWithEvOutput } from '@shared/api/services/tracker/api';
import { ArrowDownIcon } from '@shared/icons/arrow-down';

import { TrackerStatusBadge } from '@entities/vehicle/ui/tracker-status-badge';
import { VehicleStatusBadge } from '@entities/vehicle/ui/vehicle-status-badge';
import { Telemetry } from '@entities/vehicle/model/telemetry';

import {
  CloseButton,
  DriverInfoContainer,
  DriverInfoKey,
  DriverInfoValue,
  RowBorder,
  SohValue,
  StyledCollapse,
  StyledContainer,
  VehicleExtraInfoContainer,
  VehicleExtraInfoParam,
  VehicleGrn,
  VehicleInfoContent,
  VehicleInfoHeader,
  VehicleParams,
  VehicleRouteName,
  VehicleSoc,
  VehicleSocPercent,
  VehicleSpeed,
  VehicleZapasHoda,
  VerticalDivider,
  ZapasHodaValue,
} from './styles';
import { useData } from '../hooks/use-data';

type Props = {
  handleClose: () => void;
  selectedTrackerTelemetry: Telemetry;
};

export function VehicleInfo({ selectedTrackerTelemetry, handleClose }: Props) {
  const imei = selectedTrackerTelemetry?.tracker_imei || '';

  const {
    vehicleManufacturers,
    vehicleModels,
    vehicleStatuses,
    vehicleTypes,
    trackerWithEv,
  } = useData({ imei });

  const { status: trackerStatus, ev } = trackerWithEv || {};

  const {
    garage_number,
    grn,
    group_id,
    manufacture_id,
    model_id,
    status_id,
    type_id,
  } = ev || {};

  const { soc, soh, speed, latitude, longitude } =
    selectedTrackerTelemetry || {};

  const modelName = vehicleModels.find((el) => el.id === model_id)?.name || '';
  const manufacturerName =
    vehicleManufacturers.find((el) => el.id === manufacture_id)?.name || '';
  const typeName = vehicleTypes.find((el) => el.id === type_id)?.name || '';
  const vehicleStatus = vehicleStatuses.find(
    (el) => el.id === status_id
  ) as VehicleStatusesRead;

  const DUMMY_ZAPAS_HODA = 60;

  return (
    <StyledContainer className={imei ? 'open' : ''}>
      <VehicleInfoHeader>
        <CloseButton onClick={() => handleClose()}>
          <CloseIcon />
        </CloseButton>
        <VehicleParams>
          <VehicleGrn>{grn}</VehicleGrn>
          <Tooltip title="Текущий процент заряда">
            <VehicleSoc percent={soc}>
              <LightningIcon />
              <VehicleSocPercent>
                <span>{soc}</span>
                <span>%</span>
              </VehicleSocPercent>
            </VehicleSoc>
          </Tooltip>
          <VerticalDivider height={35} />
          <Tooltip title="Запас хода">
            <VehicleZapasHoda>
              <ZapasHodaValue percent={DUMMY_ZAPAS_HODA}>
                {DUMMY_ZAPAS_HODA}
              </ZapasHodaValue>
              <span>км</span>
            </VehicleZapasHoda>
          </Tooltip>
          <VerticalDivider height={35} />
          <Tooltip title="Текущая скорость">
            <VehicleSpeed>
              <span>{speed}</span>
              <span>км/ч</span>
            </VehicleSpeed>
          </Tooltip>
        </VehicleParams>
        <VehicleRouteName>Метро Алтуфьево, 1A → ДК Вперед</VehicleRouteName>
        <VehicleExtraInfoContainer>
          <VehicleExtraInfoParam>
            <span style={{ color: '#ffffff' }}>Выход</span>
            <span style={{ fontSize: '20px', lineHeight: '30px' }}>545</span>
          </VehicleExtraInfoParam>
          <VerticalDivider height={30} />
          <VehicleExtraInfoParam>
            <span>Маршрут</span>
            <span style={{ fontSize: '20px', lineHeight: '30px' }}>808</span>
          </VehicleExtraInfoParam>
          <VerticalDivider height={30} />
          <VehicleExtraInfoParam>
            <span>Гаражный номер</span>
            <span style={{ fontSize: '20px', lineHeight: '30px' }}>
              {garage_number}
            </span>
          </VehicleExtraInfoParam>
        </VehicleExtraInfoContainer>
      </VehicleInfoHeader>
      <VehicleInfoContent>
        <StyledCollapse
          collapsible="header"
          defaultActiveKey={['driver-info']}
          items={[
            {
              key: 'driver-info',
              label: 'Водитель',
              children: (
                <DriverInfoContainer>
                  <DriverInfoKey>ФИО</DriverInfoKey>
                  <DriverInfoValue>Фамилия Имя Отчество</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Телефон</DriverInfoKey>
                  <DriverInfoValue>+ 7 (800) 555-35-35</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Путевой лист</DriverInfoKey>
                  <DriverInfoValue>№ 6565656</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Медкомиссия</DriverInfoKey>
                  <DriverInfoValue>Допущен, 13.02.2024</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Табельный номер</DriverInfoKey>
                  <DriverInfoValue>№ 45454</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Наряд</DriverInfoKey>
                  <DriverInfoValue>№ 45454</DriverInfoValue>
                </DriverInfoContainer>
              ),
            },
          ]}
          expandIconPosition="end"
          expandIcon={(_panelProps) => {
            return <ArrowDownIcon />;
          }}
        />
        <StyledCollapse
          collapsible="header"
          defaultActiveKey={['vehicle-info']}
          items={[
            {
              key: 'vehicle-info',
              label: 'Свойства ТС',
              children: (
                <DriverInfoContainer>
                  <DriverInfoKey>Тип ТС</DriverInfoKey>
                  <DriverInfoValue>{typeName}</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Статус трекера</DriverInfoKey>
                  <DriverInfoValue>
                    {trackerStatus && (
                      <TrackerStatusBadge status={trackerStatus} />
                    )}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Статус ТС</DriverInfoKey>
                  <DriverInfoValue>
                    {vehicleStatus && (
                      <VehicleStatusBadge status={vehicleStatus} />
                    )}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>
                    <Flex align="center">
                      Время последней
                      <span style={{ color: '#1FC48B' }}>
                        <LightningIcon />
                      </span>
                    </Flex>
                  </DriverInfoKey>
                  <DriverInfoValue>11:18 11.11.2024</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>
                    <Flex align="center">
                      Плановое время
                      <span style={{ color: '#1FC48B' }}>
                        <LightningIcon />
                      </span>
                    </Flex>
                  </DriverInfoKey>
                  <DriverInfoValue>11:18 11.11.2024</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Координаты</DriverInfoKey>
                  <DriverInfoValue>
                    {`${latitude} , ${longitude}`}
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Производитель</DriverInfoKey>
                  <DriverInfoValue>{manufacturerName}</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Модель</DriverInfoKey>
                  <DriverInfoValue>{modelName}</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Парк</DriverInfoKey>
                  <DriverInfoValue>Красная Пахра</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Выход</DriverInfoKey>
                  <DriverInfoValue>123</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Пробег, км</DriverInfoKey>
                  <DriverInfoValue>50 004</DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>SoH</DriverInfoKey>
                  <DriverInfoValue>
                    <SohValue percent={soh}>{`${soh} %`}</SohValue>
                  </DriverInfoValue>

                  <RowBorder />

                  <DriverInfoKey>Контрагент</DriverInfoKey>
                  <DriverInfoValue>ПАО «КАМАЗ»</DriverInfoValue>
                </DriverInfoContainer>
              ),
            },
          ]}
          expandIconPosition="end"
          expandIcon={(_panelProps) => {
            return <ArrowDownIcon />;
          }}
        />
      </VehicleInfoContent>
    </StyledContainer>
  );
}
