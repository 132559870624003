import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { Flex } from 'antd';
import dayjs from 'dayjs';

import { CustomSelect } from '@shared/ui/custom-select-new';
import { getTableSettingsColumn } from '@shared/ui/table/settings-column/get-table-settings-column';
import {
  GroupOutput,
  VehicleManufacturesRead,
  VehicleModelsRead,
  VehicleStatusesRead,
  VehicleTypesRead,
} from '@shared/api/services/common/api';
import { LightningIcon } from '@shared/icons/lightning';
import { Electricbus } from '@shared/api/services/electricbus/types';
import { useUpdateElectricbusMutation } from '@shared/api/services/electricbus/enhanced';
import {
  openErrorNotification,
  openSuccessNotification,
} from '@shared/lib/notification';
import { isErrorWithMessage } from '@shared/lib/http/is-error-with-message';

import { VehicleStatusBadge } from '@entities/vehicle/ui/vehicle-status-badge';
import { TrackerStatusBadge } from '@entities/vehicle/ui/tracker-status-badge';
import { getParkNameByGroupId } from '@entities/vehicle/lib/get-park-name-by-group-id';

import { EditVehicleButton } from '@features/vehicle/edit/ui/button';
import { SearchImeiSelect } from '@entities/vehicle/ui/search-imei-select';

import { StatusStyledSelect } from './styles';
// import { IdCell } from '../ui/id-cell';
import { SocCell } from '../ui/soc-cell';
import { SohCell } from '../ui/soh-cell';
import { idTitleColumnMap } from '../consts/id-title-column-map';

const columnHelper = createColumnHelper<Electricbus>();

type Props = {
  types: VehicleTypesRead[];
  manufacturers: VehicleManufacturesRead[];
  models: VehicleModelsRead[];
  statuses: VehicleStatusesRead[];
  parks: GroupOutput[];
};

export const useColumns = ({
  manufacturers,
  types,
  models,
  statuses,
  parks,
}: Props) => {
  // const dispatch = useDispatch();
  const [trigger, { isLoading }] = useUpdateElectricbusMutation();

  // const settings = useSelector(selectUsersTable);

  const [settings, setSettings] = useState<Record<string, boolean>>({
    status_id: true,
    tracker_status: true,
    garage_number: true,
    grn: true,
    soc: true,
    soh: false,
    output_number: false, // Номер выхода временно прячем, пока нет данных
    counterparty: false,
    type_id: false,
    driver: false, // Водитель временно прячем, пока нет данных
    phone: false, // Телефон временно прячем, пока нет данных
    route: false, // маршрут - временно прячем, пока нет данных
    last_charge_time: false,
    manufacture_id: false,
    model_id: false,
    group_id: false,
    tracker_id: true,
  });

  const DATA_COLUMNS = [
    columnHelper.accessor('status_id', {
      id: 'status_id',
      header: 'Статус ТС',
      cell: (props) => {
        const val = props.getValue();
        const vehicleUuid = props.row.original.id;

        if (val === undefined || val === null) {
          return 'Нет статуса';
        }

        const status = statuses.find((el) => el.id === val);

        if (!status) {
          return 'Неизвестный статус';
        }

        return (
          <StatusStyledSelect
            vehicleStatus={status}
            value={status.id}
            onChange={(id) => {
              try {
                const res = trigger({
                  status_id: id as number,
                  id: vehicleUuid,
                }).unwrap();

                openSuccessNotification('Статус ТС успешно обновлён');
              } catch (err) {
                const hasErrorMessage = isErrorWithMessage(err);

                const errorText = hasErrorMessage
                  ? err.data.detail
                  : 'Не удалось обновить статус ТС';

                openErrorNotification(errorText);
              }
            }}
            options={statuses.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
          />
        );
      },
      size: 200,
      minSize: 200,
      maxSize: 200,
    }),
    columnHelper.accessor('tracker.status', {
      id: 'tracker_status',
      header: 'Статус трекера',
      cell: (props) => {
        const val = props.getValue();

        if (val === 'ONLINE' || val === 'OFFLINE') {
          return <TrackerStatusBadge status={val} />;
        }

        return '';
      },
      size: 180,
      minSize: 180,
      maxSize: 180,
      enableSorting: false,
    }),
    columnHelper.accessor('garage_number', {
      id: 'garage_number',
      header: () => {
        return (
          <p>
            Гаражный <br /> номер
          </p>
        );
      },
      size: 120,
      maxSize: 120,
      minSize: 120,
    }),
    columnHelper.accessor('grn', {
      id: 'grn',
      header: 'ГРЗ',
      size: 70,
      maxSize: 70,
      minSize: 70,
    }),
    // @ts-ignore
    columnHelper.accessor('soc', {
      id: 'soc',
      header: 'SoC',
      cell: SocCell,
      size: 110,
      maxSize: 110,
      minSize: 110,
      enableSorting: false,
    }),
    // @ts-ignore
    columnHelper.accessor('soh', {
      id: 'soh',
      header: 'SoH',
      cell: SohCell,
      size: 110,
      maxSize: 110,
      minSize: 110,
      enableSorting: false,
    }),
    // @ts-ignore
    columnHelper.accessor('', {
      id: 'output_number',
      header: 'Номер выхода',
      size: 135,
      minSize: 135,
      maxSize: 135,
      enableSorting: false,
    }),
    // @ts-ignore
    columnHelper.accessor('', {
      id: 'counterparty',
      header: 'Контрагент',
      size: 110,
      maxSize: 110,
      minSize: 110,
      enableSorting: false,
    }),
    columnHelper.accessor('type_id', {
      id: 'type_id',
      header: 'Тип ТС',
      cell: (props) => {
        const val = props.getValue();

        const type = types.find((el) => el.id === val);

        if (type) {
          return type.name;
        }

        return '';
      },
      size: 94,
      maxSize: 94,
      minSize: 94,
    }),
    // @ts-ignore
    columnHelper.accessor('', {
      id: 'driver',
      header: 'Водитель',
      size: 94,
      maxSize: 94,
      minSize: 94,
      enableSorting: false,
    }),
    // @ts-ignore
    columnHelper.accessor('', {
      id: 'phone',
      header: 'Телефон',
      size: 140,
      maxSize: 140,
      minSize: 140,
      enableSorting: false,
    }),
    // @ts-ignore
    columnHelper.accessor('', {
      id: 'route',
      header: 'Маршрут',
      size: 144,
      maxSize: 144,
      minSize: 144,
      enableSorting: false,
    }),
    columnHelper.accessor('last_charge_time', {
      id: 'last_charge_time',
      header: 'Время последней зарядки',
      cell: (props) => {
        const val = props.getValue();

        if (!val) {
          return '';
        }

        const dateString = dayjs(val).format('HH:mm DD.MM.YYYY');

        const [time, date] = dateString.split(' ');

        return (
          <div>
            <p>{time}</p>
            <p>{date}</p>
          </div>
        );
      },
      size: 234,
      minSize: 234,
      maxSize: 234,
      enableSorting: false,
    }),
    // columnHelper.accessor('', {
    //   header: () => {
    //     return (
    //       <Flex gap={8}>
    //         <p>Плановое время</p>
    //         <LightningIcon />
    //       </Flex>
    //     );
    //   },
    // }),
    // columnHelper.accessor('', {
    //   header: 'Координаты',
    // }),
    columnHelper.accessor('manufacture_id', {
      id: 'manufacture_id',
      header: 'Производитель',
      cell: (props) => {
        const val = props.getValue();

        const manufacturer = manufacturers.find((el) => el.id === val);

        if (manufacturer) {
          return manufacturer.name;
        }

        return '';
      },
      size: 165,
      minSize: 165,
      maxSize: 165,
    }),
    columnHelper.accessor('model_id', {
      id: 'model_id',
      header: 'Модель',
      cell: (props) => {
        const val = props.getValue();

        const model = models.find((el) => el.id === val);

        if (model) {
          return model.name;
        }

        return '';
      },
      size: 100,
      minSize: 100,
      maxSize: 100,
    }),
    columnHelper.accessor('group_id', {
      id: 'group_id',
      header: 'Парк',
      cell: (props) => {
        const val = props.getValue();

        const parkName = getParkNameByGroupId(val, parks);

        return parkName;
      },
      size: 167,
      minSize: 167,
      maxSize: 167,
      enableSorting: false,
    }),
    columnHelper.accessor('tracker', {
      id: 'tracker_id',
      header: 'ID трекера',
      cell: (props) => {
        const value = props.getValue()?.imei;
        const vehicleUuid = props.row.original.id;

        return <SearchImeiSelect value={value} evId={vehicleUuid} />;
      },
      size: 167,
      minSize: 167,
      maxSize: 167,
      enableSorting: false,
    }),
  ];

  const settingsColumn = getTableSettingsColumn({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      const typedId = id!;
      return {
        key: typedId,
        label: idTitleColumnMap[typedId],
        isChecked: settings[typedId],
      };
    }),
    settings,
    renderCell: (props) => {
      const vehicle = props.row.original;

      return <EditVehicleButton vehicle={vehicle} />;
    },
    setVisibleColumns: (cols: Record<string, boolean>) => {
      setSettings(cols);
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter(({ id }) => {
          const typedId = id!;
          return settings[typedId];
        })
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
