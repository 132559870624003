import NiceModal from '@ebay/nice-modal-react';
import { Electricbus } from '@shared/api/services/electricbus/types';
import { DeleteButton } from '@shared/ui/delete-button';
import { TrackerOutput } from '@shared/api/services/tracker/api';
import { DeleteTrackerModal } from '../modal';

type Props = {
  tracker: TrackerOutput;
  closeParentModal: () => void;
};

export function DeleteTrackerButton({ tracker, closeParentModal }: Props) {
  const showModal = () => {
    NiceModal.show(DeleteTrackerModal, { tracker, closeParentModal });
  };

  return <DeleteButton showIcon toShowModal={showModal} />;
}
