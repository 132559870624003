import { SmallVehicleIcon } from '../images/small';
import { StyledPlacemark } from './styles';

type Props = {
  selected: boolean;
  soc: number;
};

export function ElectrobusPlacemarkSmall({ selected, soc }: Props) {
  return (
    <StyledPlacemark percent={soc}>
      <SmallVehicleIcon />
    </StyledPlacemark>
  );
}
