import { Divider, Flex } from 'antd';

import { ArrowRightIcon } from '@shared/icons/arrow-right';
import { LogoutButton } from '@features/auth/logout';

import { ToggleThemeSwitch } from '@features/toggle-theme/ui';

import {
  EditProfileButton,
  StyledContainer,
  UserSettingsTitle,
} from './styles';

export function UserSettings() {
  return (
    <StyledContainer>
      <UserSettingsTitle>Настройки</UserSettingsTitle>
      <EditProfileButton>
        <Flex justify="space-between" align="center">
          Редактировать профиль
          <ArrowRightIcon />
        </Flex>
      </EditProfileButton>
      <Divider
        style={{ margin: '20px 0', borderColor: '#F7FAFF', opacity: 0.5 }}
      />
      <Flex justify="space-between" align="center">
        <ToggleThemeSwitch />
        <LogoutButton />
      </Flex>
    </StyledContainer>
  );
}
