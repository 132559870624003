export const QUERY_PARAM = {
  ordering: 'sortedField',
  type: 'sortedBy',
  dateFrom: 'dateFrom',
  dateTo: 'dateTo',
  page: 'page',
  limit: 'size',
  search: 'search',
  searchText: 'searchText',
};

export const POLLING_INTERVAL = 5000;
