import { FC } from 'react';

import { StationOutput } from '@shared/api/services/integration/api';

import {
  StationPinLabel,
  StationPlacemarkContainer,
  StationPlacemarkWrapper,
  StyledLabelArrow,
  StyledStationSvgSign,
} from './styles';

type Props = {
  ready: number;
  total: number;
  stationsData: StationOutput[];
  selectStations: (stations: StationOutput[]) => void;
};

export const StationPlacemark: FC<Props> = ({
  ready,
  total,
  stationsData,
  selectStations,
}) => {
  let colorOfCluster = '#FF9A24';

  switch (true) {
    case ready === 0:
      colorOfCluster = '#A3AEC4';
      break;
    case ready === total:
      colorOfCluster = '#1FC48B';
      break;
  }

  return (
    <StationPlacemarkContainer>
      <StationPlacemarkWrapper>
        <StationPinLabel fill={colorOfCluster}>
          {ready}/{total}
        </StationPinLabel>
        <StyledLabelArrow
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 14L0.20577 0.5L15.7942 0.500002L8 14Z"
            fill={colorOfCluster}
          />
        </StyledLabelArrow>
        <StyledStationSvgSign
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => selectStations(stationsData)}
        >
          <circle
            cx="17"
            cy="17"
            r="16"
            fill={colorOfCluster}
            stroke="white"
            stroke-width="2"
          />
          <path
            d="M10 18.5L18.3571 7V15.5H23L14.6429 27V18.5H10Z"
            fill="white"
          />
        </StyledStationSvgSign>
      </StationPlacemarkWrapper>
    </StationPlacemarkContainer>
  );
};
