import { QUERY_PARAM } from '@shared/consts/server';
import { Table } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

import { usePaginationQueryParams } from '../pagination/hooks';

type Props = {
  table: Table<any>;
};

export const useTableState = ({ table }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { setPageParamFromControls, setPageParamFromJumper } =
    usePaginationQueryParams();

  const pagesCount = table.getPageCount();

  const prevDisabled = !table.getCanPreviousPage();
  const nextDisabled = !table.getCanNextPage();

  const onPrevClick = () => setPageParamFromControls('prev');
  const onNextClick = () => setPageParamFromControls('next');

  const handleGoToPagePressEnter = (val: number) => {
    setPageParamFromJumper(String(val));
  };

  const handlePageSizePressEnter = (val: number) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    updatedSearchParams.set(QUERY_PARAM.limit, String(val));
    updatedSearchParams.set(QUERY_PARAM.page, String(1));

    setSearchParams(updatedSearchParams.toString());
  };

  const pageParam = searchParams.get(QUERY_PARAM.page);

  const currentPage = pageParam === null ? String(1) : pageParam;

  return {
    pagesCount,
    prevDisabled,
    nextDisabled,
    handleGoToPagePressEnter,
    handlePageSizePressEnter,
    onPrevClick,
    onNextClick,
    currentPage,
  };
};
