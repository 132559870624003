import { useState } from 'react';

import { DarkThemeIcon } from '../icons/dark-theme';
import { LightThemeIcon } from '../icons/light-theme';
import { Input, Slider, Switch, ThemeIconContainer } from './styles';

export function ToggleThemeSwitch() {
  const [isDarkTheme, setIsDarkTheme] = useState(true);

  const handleChange = () => setIsDarkTheme((currState) => !currState);

  return (
    <Switch>
      <Input type="checkbox" />
      <Slider onClick={handleChange}>
        <ThemeIconContainer>
          {isDarkTheme ? <DarkThemeIcon /> : <LightThemeIcon />}
        </ThemeIconContainer>
      </Slider>
    </Switch>
  );
}
