import styled from 'styled-components';

export const MapContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100%;

  overflow: hidden;

  & .ymaps3x0--marker {
    transition: z-index 0.5s ease;
  }

  & .ymaps3x0--marker:hover {
    z-index: 4 !important;
  }
`;

export const StyledMonitoringLayout = styled.div`
  overflow: auto;
  background-color: #1f2126;
`;
