import { PaginationState } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

import { QUERY_PARAM } from '@shared/consts/server';

//
const INCREASED_TABLE_PAGE_SIZE = 20;

const { page, limit } = QUERY_PARAM;

export const usePaginationState = (): PaginationState => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pageParam = searchParams.get(page);
  const limitParam = searchParams.get(limit);

  if (pageParam === null) {
    return {
      pageIndex: 0,
      pageSize: INCREASED_TABLE_PAGE_SIZE, //TODO:
    };
  }

  return {
    pageIndex: Number(pageParam) - 1,
    pageSize: Number(limitParam),
  };
};
