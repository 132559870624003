import { CompanyLogo } from '@widgets/sider/images/company-logo';

import { SiderNavMenu } from '../nav-menu';
import { LogoContainer, SiderFooter, StyledLayout } from './styles';

export function SiderLayout() {
  return (
    <StyledLayout>
      <LogoContainer>
        <CompanyLogo />
      </LogoContainer>
      <SiderNavMenu />
      <SiderFooter></SiderFooter>
    </StyledLayout>
  );
}
