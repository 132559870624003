import {
  useGetManufacturersQuery,
  useGetVehicleModelsQuery,
  useGetVehicleStatusesQuery,
  useGetVehicleTypesQuery,
  useGetGroupsQuery,
} from '@shared/api/services/common/enhanced';

export function useData() {
  const {
    data: vehicleManufacturers = [],
    isLoading: isManufacturersLoading,
    error: manufacturersError,
    isSuccess: isManufacturersSuccess,
  } = useGetManufacturersQuery();

  const {
    data: vehicleTypes = [],
    isLoading: isVehicleTypesLoading,
    error: vehicleTypesError,
    isSuccess: isVehicleTypesSuccess,
  } = useGetVehicleTypesQuery();

  const {
    data: vehicleModels = [],
    isLoading: isVehicleModelsLoading,
    error: vehicleModelsError,
    isSuccess: isVehicleModelsSuccess,
  } = useGetVehicleModelsQuery();

  const {
    data: vehicleStatuses = [],
    isLoading: isVehicleStatusesLoading,
    error: vehicleStatusesError,
    isSuccess: isVehicleStatusesSuccess,
  } = useGetVehicleStatusesQuery();

  const {
    data: parks = [],
    isLoading: isGroupsLoading,
    error: groupsError,
    isSuccess: isGroupsSuccess,
  } = useGetGroupsQuery();

  const error = [
    manufacturersError,
    vehicleTypesError,
    vehicleModelsError,
    vehicleStatusesError,
    groupsError,
  ].find((err) => err !== undefined);

  const isLoading =
    isManufacturersLoading ||
    isVehicleModelsLoading ||
    isVehicleTypesLoading ||
    isVehicleStatusesLoading ||
    isGroupsLoading;

  return {
    isLoading,
    error,
    vehicleManufacturers,
    vehicleTypes,
    vehicleModels,
    vehicleStatuses,
    parks,
  };
}
