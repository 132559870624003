import NiceModal from '@ebay/nice-modal-react';

import { FiltersIcon } from '@shared/icons/filters';
import { selectHasFilters } from '@shared/redux/slices/tracker/filters';

import { useAppSelector } from '@app/hooks';

import { FiltersModal } from '../modal';

import { StyledButton, ButtonLabel } from './styles';
import { useParams } from 'react-router-dom';

export function FiltersButton() {
  const { eParkId, convoyId } = useParams();

  const showFiltersModal = () => {
    NiceModal.show(FiltersModal, { eParkId, convoyId });
  };

  const hasFilters = useAppSelector(selectHasFilters);

  return (
    <StyledButton onClick={() => showFiltersModal()} hasFilters={hasFilters}>
      <ButtonLabel>Фильтры</ButtonLabel>
      <FiltersIcon />
    </StyledButton>
  );
}
