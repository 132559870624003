import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 20px 12px;
  border-radius: 20px;
  background-color: #09a56f;
  width: 260px;
  color: #f7faff;
`;

export const UserSettingsTitle = styled.p`
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;

  margin-bottom: 20px;
`;

export const EditProfileButton = styled.button`
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: inherit;
  width: 100%;
`;
