import {
  useLazyGetTrackersQuery,
  useUpdateTrackerMutation,
} from '@shared/api/services/tracker/enhanced';
import { Select } from 'antd';
import { FC } from 'react';
import { StyledAntdSelect } from '@shared/ui/custom-select-new/styles';

type Props = {
  value?: string;
  evId?: string | null;
};

export const SearchImeiSelect: FC<Props> = ({ value, evId }) => {
  const [trackersTrigger, { data, isLoading: isLoadingTrackers }] =
    useLazyGetTrackersQuery();

  const [update, { isLoading: isLoadingNewTracker, isSuccess, error }] =
    useUpdateTrackerMutation();

  const handleSearch = (newValue: string) => {
    trackersTrigger({ imeiLike: newValue });
  };

  const handleChange = (newValue: string) => {
    update({
      imei: newValue,
      ev_id: evId,
      ev_type_id: 1,
    });
  };

  return (
    <div
      onDoubleClick={(e) => {
        e.stopPropagation();
      }}
    >
      <StyledAntdSelect
        showSearch
        value={value ?? 'Нет данных'}
        defaultActiveFirstOption={false}
        suffixIcon={null}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={null}
        options={(data?.items ?? []).map((d) => ({
          value: d.imei,
          label: d.imei,
        }))}
      />
    </div>
  );
};
