import { useEffect, useState } from 'react';

import { UiInput } from '@shared/ui/ui-input';

// import { getInputHtmlSize } from 'shared/lib';

import { PaginationControlsContainer } from './styles';
import { PaginationButtons } from './buttons';

type Props = {
  handleEnterPress: (val: number) => void;
  pagesCount: number;
  prevDisabled: boolean;
  nextDisabled: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
  currentPage: string;
};

export function PaginationControls({
  handleEnterPress,
  pagesCount,
  onNextClick,
  nextDisabled,
  onPrevClick,
  prevDisabled,
  currentPage,
}: Props) {
  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(currentPage);
  }, [currentPage]);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > 0 || e.target.value === '') {
      setValue(e.target.value);
    }
  };

  return (
    <PaginationControlsContainer>
      <span>Страница</span>
      <UiInput
        inputSize="sm"
        value={value}
        onChange={handleValueChange}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            const val = Number((e.target as HTMLInputElement).value);

            if (val > 0) {
              handleEnterPress(val);
            }
          }
        }}
      />
      <span>из</span>
      {pagesCount}
      <PaginationButtons
        prevDisabled={prevDisabled}
        nextDisabled={nextDisabled}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
      />
    </PaginationControlsContainer>
  );
}
