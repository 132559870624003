import styled from 'styled-components';

export const StyledButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  color: #a3aec4;

  margin-right: auto;

  &:hover {
    color: #f7faff;
  }
`;

export const ButtonLabel = styled.p`
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
