import { useUpdateElectricbusMutation } from '@shared/api/services/electricbus/enhanced';
import {
  openErrorNotification,
  openSuccessNotification,
} from '@shared/lib/notification';

import { FormSchemaType } from '@entities/tracker/consts/schema';

import { EDIT_SUCCESS, EDIT_ERROR } from '../../consts';
import { isErrorWithMessage } from '@shared/lib/http/is-error-with-message';
import { TrackerOutput } from '@shared/api/services/tracker/api';
import { ConnectedTrackerForm } from '@entities/tracker/ui/connected-form';
import { useUpdateTrackerMutation } from '@shared/api/services/tracker/enhanced';
import { DeleteTrackerButton } from '@features/trackers/delete/ui/button';

type Props = {
  tracker: TrackerOutput;
  closeModal: () => void;
};

export function EditTracker({ tracker, closeModal }: Props) {
  const [update, { isLoading, isSuccess, error }] = useUpdateTrackerMutation();

  const handleSubmit = async (data: Partial<FormSchemaType>) => {
    const oldImei = tracker.imei;
    const newImei = data.imei;

    try {
      let res;

      if (oldImei !== newImei) {
        res = await update({
          ...data,
          imei: oldImei,
          new_imei: newImei,
        }).unwrap();
      } else {
        res = await update(data).unwrap();
      }

      openSuccessNotification(`${EDIT_SUCCESS} ${res.imei}`);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.detail : EDIT_ERROR;

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  return (
    <ConnectedTrackerForm
      submitButtonLabel="Сохранить"
      handleFormSubmit={handleSubmit}
      tracker={tracker}
      loading={isLoading}
      deleteButton={
        <DeleteTrackerButton tracker={tracker} closeParentModal={closeModal} />
      }
    />
  );
}
