import { SortDirection } from '@tanstack/react-table';
import { useSortingChange } from '../hooks/use-sorting-change';

import { AscendIcon } from './images/AscendIcon';
import { DescendIcon } from './images/DescendIcon';
import { SortingButton, SortingButtonsContainer } from './styles';

type Props = {
  sortingType: false | SortDirection;
  headerId: string;
};

export function SortingButtons({ sortingType, headerId }: Props) {
  const onSortingChange = useSortingChange();

  const handleSortChange = (type: SortDirection) => {
    onSortingChange(headerId, type);
  };

  const isAsc = sortingType === 'asc';
  const isDesc = sortingType === 'desc';

  return (
    <SortingButtonsContainer>
      <SortingButton
        sorted={isAsc}
        className={isAsc ? 'sorted' : ''}
        onClick={(e) => {
          handleSortChange('asc');
        }}
      >
        <AscendIcon />
      </SortingButton>
      <SortingButton
        sorted={isDesc}
        className={isDesc ? 'sorted' : ''}
        onClick={(e) => {
          handleSortChange('desc');
        }}
      >
        <DescendIcon />
      </SortingButton>
    </SortingButtonsContainer>
  );
}
