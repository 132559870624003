import { FC } from 'react';
import { Spin } from 'antd';
import { FormSchema, FormSchemaType } from '@entities/tracker/consts/schema';
import { GetGroupsApiTrackersModelsGetApiResponse } from '@shared/api/services/common/api';
import { TrackerOutput } from '@shared/api/services/tracker/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { FormButtonsContainer, FormLayout } from './styles';
import { UiButton } from '@shared/ui/ui-button';
import {
  FieldErrorContainer,
  FormColumn,
  FormItemContainer,
  FormItemLabel,
} from '@shared/ui/form/styles';
import { CustomSelect } from '@shared/ui/custom-select-new';
import { CustomInput } from '@shared/ui/custom-input';

type Props = {
  models: GetGroupsApiTrackersModelsGetApiResponse;
  tracker?: TrackerOutput;
  submitButtonLabel: string;
  handleFormSubmit: (data: Partial<FormSchemaType>) => void;
  deleteButton?: JSX.Element;
  loading: boolean;
};

export const TrackerForm: FC<Props> = ({
  models,
  tracker,
  submitButtonLabel,
  handleFormSubmit,
  deleteButton,
  loading,
}) => {
  const defaultValues = tracker
    ? {
        imei: tracker.imei,
        model_id: tracker.model_id as number | undefined,
      }
    : {};

  const {
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    control,
    setValue,
  } = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  const onSubmit = async (data: FormSchemaType) => {
    handleFormSubmit(data);
  };

  const handleReset = () => {
    setValue('imei', defaultValues.imei as string);
    setValue('model_id', defaultValues.model_id);
  };

  return (
    <Spin spinning={loading}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormLayout>
          <FormColumn>
            <FormItemContainer>
              <FormItemLabel>ID</FormItemLabel>
              <Controller
                name="imei"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    inputSize="md"
                    placeholder="Введите значение"
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.imei && <p>{errors.imei.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>
            <FormItemContainer>
              <FormItemLabel>Модель</FormItemLabel>
              <Controller
                name="model_id"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomSelect
                    {...field}
                    options={models.map((el) => ({
                      value: el.id,
                      label: el.name,
                    }))}
                    allowClear
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.model_id && <p>{errors.model_id.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>
          </FormColumn>
        </FormLayout>

        <FormButtonsContainer>
          {deleteButton}
          <UiButton onClick={handleReset} variant="outline">
            Сбросить
          </UiButton>
          <UiButton variant="primary" type="submit">
            {submitButtonLabel}
          </UiButton>
        </FormButtonsContainer>
      </form>
    </Spin>
  );
};
