import { Electricbus } from '@shared/api/services/electricbus/types';

import {
  ElectrobusLeafExtraInfo,
  ElectrobusLeafExtraInfoParam,
  ElectrobusLeafTitle,
  StyledContainer,
  VerticalDivider,
} from './styles';

type Props = {
  vehicle: Electricbus;
};

export function ElectrobusLeaf({ vehicle }: Props) {
  const { grn, garage_number } = vehicle;

  return (
    <StyledContainer>
      <ElectrobusLeafTitle>{`Электробус ${grn}`}</ElectrobusLeafTitle>
      <ElectrobusLeafExtraInfo>
        <ElectrobusLeafExtraInfoParam>№735</ElectrobusLeafExtraInfoParam>
        <VerticalDivider />
        <ElectrobusLeafExtraInfoParam>
          {garage_number}
        </ElectrobusLeafExtraInfoParam>
      </ElectrobusLeafExtraInfo>
    </StyledContainer>
  );
}
