import styled from 'styled-components';

export const StyledButton = styled.button`
  cursor: pointer;
  color: #a3aec4;

  &:hover {
    color: #f7faff;
  }
`;
