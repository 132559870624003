import { StationOutput } from '@shared/api/services/integration/api';
import { ChargingStationInfoCardWrapper, Title, Property } from './styles';
import { PortsMarkers } from '../ports-markers';
import { FC } from 'react';
import { Tooltip } from 'antd';

type Props = {
  station: StationOutput;
  selectedStationId?: string;
};

export const ChargingStationInfoCard: FC<Props> = ({
  station,
  selectedStationId,
}) => {
  const { name, max_power, dynamic_balancing_mode, ports, station_id, status } =
    station;
  const maxPower = max_power ? max_power / 1000 : '-';
  const balancingStatus = dynamic_balancing_mode ? (
    <span style={{ color: '#23C990' }}>Да</span>
  ) : (
    <span style={{ color: '#FE4D4D' }}>Нет</span>
  );

  return (
    <ChargingStationInfoCardWrapper
      status={status}
      isSelected={selectedStationId === station_id}
    >
      <Tooltip title={name}>
        <Title>{name}</Title>
      </Tooltip>
      <Tooltip title={`Раз. мощность: ${maxPower} кВт`}>
        <Property>Раз. мощность: {maxPower} кВт</Property>
      </Tooltip>
      <Property>Балансировка: {balancingStatus}</Property>
      <PortsMarkers ports={ports} stationId={station_id} size={'11px'} />
    </ChargingStationInfoCardWrapper>
  );
};
