import { useForm, Controller, type FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { resetPageByFiltering } from '@shared/utils/table/reset-page-by-filtering';
import { CustomInput } from '@shared/ui/custom-input';
import {
  FieldErrorContainer,
  FormColumn,
  FormItemContainer,
  FormItemLabel,
} from '@shared/ui/form/styles';
import { CustomDatetimepicker } from '@shared/ui/custom-datetimepicker';
import { CustomSelectMultiple } from '@shared/ui/custom-select-new/components/multiple-select';
import { UiButton } from '@shared/ui/ui-button';
import {
  selectVehicleFilters,
  setFilters,
} from '@shared/redux/slices/vehicle/filters';
import { TrackerStatus } from '@shared/api/services/tracker/api';
import { CustomSelect } from '@shared/ui/custom-select-new';

import { useAppDispatch, useAppSelector } from '@app/hooks';

import { useData } from './use-data';
import {
  FormButtonsContainer,
  FormLayout,
  InputGroupContent,
  StyledAntdSelect,
} from './styles';

import { FormSchema, FormSchemaType } from '../../consts/schema';

const trackerStatusOptions: Array<{ label: string; value: TrackerStatus }> = [
  { label: 'Online', value: 'ONLINE' },
  { label: 'Offline', value: 'OFFLINE' },
];

type Props = {
  closeModal: () => void;
  eParkId?: string;
  convoyId?: string;
};

export function FiltersForm({ closeModal, eParkId, convoyId }: Props) {
  const filters = useAppSelector(selectVehicleFilters);
  const [searchParams, setSearchParams] = useSearchParams();

  const isTransportTableFilter =
    eParkId === undefined && convoyId === undefined;
  const isParkTableFilter = eParkId !== undefined && convoyId === undefined;
  const isConvoyTableFilter = eParkId !== undefined && convoyId !== undefined;

  const dispatch = useAppDispatch();

  const {
    vehicleManufacturers,
    vehicleModels,
    vehicleStatuses,
    vehicleTypes,
    parks,
  } = useData();

  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: filters,
  });

  const typeId = watch('typeId');
  const parkId = watch('parkId');
  const groupIds = watch('groupIds');
  const sohFrom = watch('sohFrom');
  const sohTo = watch('sohTo');
  const socFrom = watch('socFrom');
  const socTo = watch('socTo');
  const powerReserveFrom = watch('powerReserveFrom');
  const powerReserveTo = watch('powerReserveTo');
  const mileageFrom = watch('mileageFrom');
  const mileageTo = watch('mileageTo');

  useEffect(() => {
    if (isTransportTableFilter) {
      if (parkId === undefined) {
        return;
      }

      const park = parks.find((park) => park.id === parkId);

      if (park && park.children?.[0].id) {
        const groupIds = park?.children?.map((el) => el.id) ?? [];

        setValue('groupIds', groupIds);
      }
    }
  }, [parkId]);

  const onSubmit = async (data: FieldValues) => {
    resetPageByFiltering(searchParams, setSearchParams);

    if (isParkTableFilter && data.parkId && data.groupIds.length === 0) {
      const park = parks.find((park) => park.id === parkId);

      dispatch(
        setFilters({
          ...data,
          groupIds: park?.children?.map((el) => el.id) ?? [],
        })
      );
    } else {
      dispatch(setFilters(data));
    }

    closeModal();
  };

  const handleReset = () => {
    setValue('typeId', undefined);
    setValue('statusEvIds', []);
    setValue('modelsIds', []);
    setValue('manufactureIds', []);

    setValue('sohFrom', undefined);
    setValue('sohTo', undefined);
    setValue('socFrom', undefined);
    setValue('socTo', undefined);
    setValue('powerReserveFrom', undefined);
    setValue('powerReserveTo', undefined);
    setValue('mileageFrom', undefined);
    setValue('mileageTo', undefined);

    if (isTransportTableFilter) {
      setValue('parkId', undefined);
      setValue('groupIds', []);
    }

    if (isParkTableFilter) {
      const park = parks.find((park) => park.id === parkId);

      setValue('groupIds', park?.children?.map((el) => el.id) ?? []);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="password">
      <FormLayout>
        <FormColumn>
          <FormItemContainer>
            <FormItemLabel>Тип ТС</FormItemLabel>
            <Controller
              name="typeId"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <CustomSelect
                    {...field}
                    value={typeId}
                    options={vehicleTypes.map((el) => ({
                      value: el.id,
                      label: el.name,
                    }))}
                    allowClear
                    onClear={() => setValue('typeId', undefined)}
                  />
                );
              }}
            />
            <FieldErrorContainer>
              {errors?.typeId && <p>{errors.typeId.message}</p>}
            </FieldErrorContainer>
          </FormItemContainer>

          <FormItemContainer>
            <FormItemLabel>Статус ТС</FormItemLabel>
            <Controller
              name="statusEvIds"
              control={control}
              render={({ field, fieldState }) => (
                <CustomSelectMultiple
                  {...field}
                  options={vehicleStatuses.map((el) => ({
                    value: el.id,
                    label: el.name,
                  }))}
                />
              )}
            />
            <FieldErrorContainer>
              {errors?.statusEvIds && <p>{errors.statusEvIds.message}</p>}
            </FieldErrorContainer>
          </FormItemContainer>

          <FormItemContainer>
            <FormItemLabel>Модель ТС</FormItemLabel>
            <Controller
              name="modelsIds"
              control={control}
              render={({ field, fieldState }) => (
                <CustomSelectMultiple
                  {...field}
                  options={vehicleModels.map((el) => ({
                    value: el.id,
                    label: el.name,
                  }))}
                />
              )}
            />
            <FieldErrorContainer>
              {errors?.modelsIds && <p>{errors.modelsIds.message}</p>}
            </FieldErrorContainer>
          </FormItemContainer>

          <FormItemContainer>
            <FormItemLabel>Производитель</FormItemLabel>
            <Controller
              name="manufactureIds"
              control={control}
              render={({ field, fieldState }) => (
                <CustomSelectMultiple
                  {...field}
                  options={vehicleManufacturers.map((el) => ({
                    value: el.id,
                    label: el.name,
                  }))}
                />
              )}
            />
            <FieldErrorContainer>
              {errors?.manufactureIds && <p>{errors.manufactureIds.message}</p>}
            </FieldErrorContainer>
          </FormItemContainer>

          <FormItemContainer>
            <FormItemLabel>Контрагент</FormItemLabel>
            <CustomSelectMultiple options={[]} value={[]} disabled />
            <FieldErrorContainer></FieldErrorContainer>
          </FormItemContainer>

          <FormItemContainer>
            <FormItemLabel>Статус трекера</FormItemLabel>
            <Controller
              name="tracker_statuses"
              control={control}
              render={({ field, fieldState }) => (
                <CustomSelectMultiple
                  disabled
                  {...field}
                  options={trackerStatusOptions}
                />
              )}
            />
            <FieldErrorContainer></FieldErrorContainer>
          </FormItemContainer>
        </FormColumn>

        <FormColumn>
          <FormItemContainer>
            <FormItemLabel>SoH, %</FormItemLabel>
            <InputGroupContent>
              <Controller
                name="sohFrom"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    value={sohFrom}
                    inputSize="md"
                    placeholder="От"
                  />
                )}
              />
              {'-'}
              <Controller
                name="sohTo"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    value={sohTo}
                    inputSize="md"
                    placeholder="До"
                  />
                )}
              />
            </InputGroupContent>
            <FieldErrorContainer>
              {(errors?.sohFrom || errors?.sohTo) && (
                <p>{errors.sohFrom?.message || errors.sohTo?.message}</p>
              )}
            </FieldErrorContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormItemLabel>SoС, %</FormItemLabel>
            <InputGroupContent>
              <Controller
                name="socFrom"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    value={socFrom}
                    inputSize="md"
                    placeholder="От"
                  />
                )}
              />
              {'-'}
              <Controller
                name="socTo"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    value={socTo}
                    inputSize="md"
                    placeholder="До"
                  />
                )}
              />
            </InputGroupContent>
            <FieldErrorContainer>
              {(errors?.socFrom || errors?.socTo) && (
                <p>{errors.socFrom?.message || errors.socTo?.message}</p>
              )}
            </FieldErrorContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormItemLabel>Запас хода, км</FormItemLabel>
            <InputGroupContent>
              <Controller
                name="powerReserveFrom"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    value={powerReserveFrom}
                    inputSize="md"
                    placeholder="От"
                  />
                )}
              />
              {'-'}
              <Controller
                name="powerReserveTo"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    value={powerReserveTo}
                    inputSize="md"
                    placeholder="До"
                  />
                )}
              />
            </InputGroupContent>
            <FieldErrorContainer>
              {(errors?.powerReserveFrom || errors?.powerReserveTo) && (
                <p>
                  {errors.powerReserveFrom?.message ||
                    errors.powerReserveTo?.message}
                </p>
              )}
            </FieldErrorContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormItemLabel>Пробег, км</FormItemLabel>
            <InputGroupContent>
              <Controller
                name="mileageFrom"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    value={mileageFrom}
                    inputSize="md"
                    placeholder="От"
                  />
                )}
              />
              {'-'}
              <Controller
                name="mileageTo"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    value={mileageTo}
                    inputSize="md"
                    placeholder="До"
                  />
                )}
              />
            </InputGroupContent>
            <FieldErrorContainer>
              {(errors?.mileageFrom || errors?.mileageTo) && (
                <p>
                  {errors.mileageFrom?.message || errors.mileageTo?.message}
                </p>
              )}
            </FieldErrorContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormItemLabel>Время последней зарядки</FormItemLabel>
            <InputGroupContent>
              <CustomDatetimepicker disabled />
              {'-'}
              <CustomDatetimepicker disabled />
            </InputGroupContent>
            <FieldErrorContainer></FieldErrorContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormItemLabel>Плановое время подзарядки</FormItemLabel>
            <InputGroupContent>
              <CustomDatetimepicker disabled />
              {'-'}
              <CustomDatetimepicker disabled />
            </InputGroupContent>
            <FieldErrorContainer></FieldErrorContainer>
          </FormItemContainer>
        </FormColumn>

        <FormColumn>
          <FormItemContainer>
            <FormItemLabel>Парк</FormItemLabel>
            <Controller
              name="parkId"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <CustomSelect
                    {...field}
                    value={parkId}
                    disabled={eParkId !== undefined}
                    options={parks.map((el) => ({
                      value: el.id,
                      label: el.name,
                    }))}
                    allowClear
                    onClear={() => setValue('groupIds', [])}
                  />
                );
              }}
            />
            <FieldErrorContainer></FieldErrorContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormItemLabel>Автоколонна</FormItemLabel>
            <Controller
              name="groupIds"
              control={control}
              render={({ field, fieldState }) => (
                <CustomSelectMultiple
                  {...field}
                  value={groupIds}
                  disabled={
                    parkId === undefined ||
                    (eParkId !== undefined && convoyId !== undefined)
                  }
                  onClear={() => {
                    const park = parks.find((park) => park.id === parkId);
                    if (isTransportTableFilter) {
                      setValue('groupIds', []);
                      return;
                    }
                    setValue(
                      'groupIds',
                      park?.children?.map((el) => el.id) ?? []
                    );
                  }}
                  options={
                    parks
                      .find((park) => park.id === parkId)
                      ?.children?.map((el) => ({
                        value: el.id,
                        label: el.name,
                      })) ?? []
                  }
                />
              )}
            />
            <FieldErrorContainer></FieldErrorContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormItemLabel>Маршрут</FormItemLabel>
            <Controller
              name="route"
              control={control}
              render={({ field, fieldState }) => (
                <CustomInput
                  {...field}
                  inputSize="md"
                  placeholder="Введите номер маршрута"
                  disabled
                />
              )}
            />
            <FieldErrorContainer></FieldErrorContainer>
          </FormItemContainer>

          <FormItemContainer>
            <FormItemLabel>Выход</FormItemLabel>
            <Controller
              name="exit"
              control={control}
              render={({ field, fieldState }) => (
                <CustomInput
                  {...field}
                  inputSize="md"
                  placeholder="Введите номер выхода"
                  disabled
                />
              )}
            />
            <FieldErrorContainer></FieldErrorContainer>
          </FormItemContainer>
        </FormColumn>
      </FormLayout>

      <FormButtonsContainer>
        <UiButton variant="outline" onClick={handleReset} type="button">
          Сбросить
        </UiButton>
        <UiButton variant="primary" type="submit">
          Применить
        </UiButton>
      </FormButtonsContainer>
    </form>
  );
}
