import { FC } from 'react';
import { StyledBadge } from './styles';
import { StationStatusEnum } from '@shared/api/services/integration/api';
import { stationStatusNamingMap } from '@entities/charging-station/consts/station-status-naming-map';

type Props = {
  status: StationStatusEnum;
};

export const ChargingStationStatusBadge: FC<Props> = ({ status }) => {
  return (
    <StyledBadge status={status}>{stationStatusNamingMap[status]}</StyledBadge>
  );
};
