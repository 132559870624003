import { PortStationEnum } from '@shared/api/services/integration/api';
import { colorMap } from '@shared/consts/colorMap';

export const portStatusColorMap: Record<PortStationEnum, string> = {
  CHARGING: colorMap.khaki,
  READY: colorMap.green,
  UNAVAILABLE: colorMap.darkGrey,
  DEFECTIVE: colorMap.red,
  OFFLINE: colorMap.grey,
};
