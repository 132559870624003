import { useParams } from 'react-router-dom';
import { ConnectedVehiclesTable } from '@features/vehicle/vehicles-table/ui/connected-table';

import { TransportHeader } from '@widgets/header/ui/transport';

import { StandardPageLayout } from '../styles';

export function TransportPage() {
  const { eParkId, convoyId } = useParams();

  let tableType: 'TRANSPORT' | 'PARK' | 'CONVOY' = 'TRANSPORT';

  if (eParkId && convoyId) {
    tableType = 'CONVOY';
  }

  if (eParkId && !convoyId) {
    tableType = 'PARK';
  }

  return (
    <StandardPageLayout>
      <TransportHeader tableType={tableType} />
      <ConnectedVehiclesTable tableType={tableType} />
    </StandardPageLayout>
  );
}
