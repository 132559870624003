export function DeleteIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 7C5 6.44772 4.55228 6 4 6C3.44772 6 3 6.44772 3 7H5ZM19 7C19 6.44772 18.5523 6 18 6C17.4477 6 17 6.44772 17 7H19ZM14 10C14 9.44771 13.5523 9 13 9C12.4477 9 12 9.44771 12 10H14ZM12 16C12 16.5523 12.4477 17 13 17C13.5523 17 14 16.5523 14 16H12ZM10 10C10 9.44771 9.55228 9 9 9C8.44772 9 8 9.44772 8 10H10ZM8 16C8 16.5523 8.44772 17 9 17C9.55229 17 10 16.5523 10 16H8ZM20 5C20.5523 5 21 4.55228 21 4C21 3.44772 20.5523 3 20 3V5ZM2 3C1.44772 3 1 3.44772 1 4C1 4.55228 1.44772 5 2 5V3ZM13.5937 1.8906L12.7617 2.4453L13.5937 1.8906ZM8.40627 1.8906L7.57422 1.3359L8.40627 1.8906ZM8 22H14V20H8V22ZM3 7V17H5V7H3ZM19 17V7H17V17H19ZM14 22C16.7614 22 19 19.7614 19 17H17C17 18.6569 15.6569 20 14 20V22ZM8 20C6.34315 20 5 18.6569 5 17H3C3 19.7614 5.23858 22 8 22V20ZM12 10V16H14V10H12ZM8 10L8 16H10V10H8ZM10.0704 2H11.9296V0H10.0704V2ZM12.7617 2.4453L14.1679 4.5547L15.8321 3.4453L14.4258 1.3359L12.7617 2.4453ZM15 3H7V5H15V3ZM7.83205 4.5547L9.23832 2.4453L7.57422 1.3359L6.16795 3.4453L7.83205 4.5547ZM15 5H20V3H15V5ZM7 3H2V5H7V3ZM11.9296 2C12.264 2 12.5762 2.1671 12.7617 2.4453L14.4258 1.3359C13.8694 0.501303 12.9327 0 11.9296 0V2ZM10.0704 0C9.06731 0 8.13061 0.501303 7.57422 1.3359L9.23832 2.4453C9.42378 2.1671 9.73601 2 10.0704 2V0Z"
        fill="currentColor"
      />
    </svg>
  );
}
