import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from '@shared/ui/ui-modal';
import { Electricbus } from '@shared/api/services/electricbus/types';

import { EditTracker } from '../edit-tracker';
import { TrackerOutput } from '@shared/api/services/tracker/api';

type Props = {
  tracker: TrackerOutput;
};

export const EditTrackerModal = NiceModal.create(({ tracker }: Props) => {
  const modal = useModal();

  return (
    <UiModal isOpen={modal.visible} onClose={modal.hide}>
      <UiModal.Header onClose={modal.hide}>Редактировать трекер</UiModal.Header>
      <UiModal.Body>
        <EditTracker tracker={tracker} closeModal={modal.hide} />
      </UiModal.Body>
      {/* <UiModal.Footer>
        <DeleteVehicleButton vehicle={vehicle} />
        <UiButton variant="outline">Сбросить</UiButton>
        <UiButton variant="primary">Сохранить</UiButton>
      </UiModal.Footer> */}
    </UiModal>
  );
});
