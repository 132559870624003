import styled from 'styled-components';

const dynamicColor = (props: { hasFilters: boolean }) => {
  const { hasFilters } = props;

  if (hasFilters) {
    return '#1FC48B';
  }

  return '#a3aec4';
};

const dynamicLabelColor = (props: { hasFilters: boolean }) => {
  const { hasFilters } = props;

  if (hasFilters) {
    return '#09FBA8';
  }

  return '#F7FAFF';
};

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${dynamicColor};
  cursor: pointer;

  & p {
    color: ${dynamicLabelColor};
  }
`;

export const ButtonLabel = styled.p`
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;
