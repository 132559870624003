import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiModal } from '@shared/ui/ui-modal';

import { FiltersForm } from '../form';

type Props = {
  eParkId?: string;
  convoyId?: string;
};

export const FiltersModal = NiceModal.create(({ eParkId, convoyId }: Props) => {
  const modal = useModal();

  return (
    <UiModal isOpen={modal.visible} onClose={modal.hide}>
      <UiModal.Header onClose={modal.hide}>Фильтры</UiModal.Header>
      <UiModal.Body>
        <FiltersForm
          closeModal={modal.hide}
          eParkId={eParkId}
          convoyId={convoyId}
        />
      </UiModal.Body>
      {/* <UiModal.Footer>
        <UiButton variant="outline">Сбросить</UiButton>
        <UiButton variant="primary">Применить</UiButton>
      </UiModal.Footer> */}
    </UiModal>
  );
});
