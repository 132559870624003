import { ColumnFiltersState } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

import { QUERY_PARAM } from '@shared/consts/server';

const { search, searchText } = QUERY_PARAM;

export const useFiltersState = (): ColumnFiltersState => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParam = searchParams.get(search);
  const searchTextParam = searchParams.get(searchText);

  if (searchParam === null || searchTextParam === null) {
    return [];
  }

  return [{ id: searchParam, value: searchTextParam }];
};
