import styled from 'styled-components';

import { UiButtonVariant } from './types';

const dynamicBgColor = (props: { variant: UiButtonVariant }) => {
  const { variant } = props;

  if (variant === 'primary') {
    return '#23C990';
  }

  if (variant === 'outline') {
    return 'transparent';
  }

  if (variant === 'danger') {
    return '#FE4D4D';
  }
};

const dynamicBorderColor = (props: { variant: UiButtonVariant }) => {
  const { variant } = props;

  if (variant === 'primary') {
    return '#23C990';
  }

  if (variant === 'outline') {
    return '#A3AEC4';
  }

  if (variant === 'danger') {
    return '#FE4D4D';
  }
};

export const StyledUiButton = styled.button`
  cursor: pointer;
  border-radius: 12px;
  color: #ffffff;
  padding: 8px 16px;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  background-color: ${dynamicBgColor};
  border: 2px solid ${dynamicBorderColor};
`;
