import Checkbox from 'antd/es/checkbox/Checkbox';
import styled from 'styled-components';

export const StyledCheckbox = styled(Checkbox)`
  padding: 10px;
  margin-left: 0 !important;

  & .ant-checkbox-checked .ant-checkbox-inner {
    border: none;
    background-color: #23c990;
  }

  & .ant-checkbox-inner {
    border: none;
    background-color: #32353f;
    border: 1px solid white;

    &:hover {
      border: 1px solid white;
    }
  }

  &:hover .ant-checkbox-checked .ant-checkbox-inner {
    border: none;
    background-color: #077850 !important;
  }

  & span:last-child {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: white;
  }

  //  &:hover .ant-checkbox-inner {
  //    background-color: #00784e;
  //  }
  //
  //  & .ant-checkbox-checked .ant-checkbox-inner {
  //    border: none;
  //    background-color: #23c990;
  //  }
  //
  //  &:hover {
  //    background-color: #00784e;
  //  }
  //
  //  & .ant-checkbox-checked::after {
  //  }
  //
  //  & .ant-checkbox-input:focus + .ant-checkbox-inner {
  //  }
`;
