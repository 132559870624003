import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef } from 'react';

import {
  GroupOutput,
  VehicleManufacturesRead,
  VehicleModelsRead,
  VehicleStatusesRead,
  VehicleTypesRead,
} from '@shared/api/services/common/api';
import { CustomInput } from '@shared/ui/custom-input';
import {
  FieldErrorContainer,
  FormColumn,
  FormItemContainer,
  FormItemLabel,
} from '@shared/ui/form/styles';
import { CustomSelect } from '@shared/ui/custom-select-new/components/select';
import { UiButton } from '@shared/ui/ui-button';
import { Electricbus } from '@shared/api/services/electricbus/types';
import { filterObject } from '@shared/lib/transormation/pick';

import { FormSchema, FormSchemaType } from '@entities/vehicle/consts/schema';

import { FormButtonsContainer, FormLayout } from './styles';
import { Spin } from 'antd';

type Props = {
  types: VehicleTypesRead[];
  manufacturers: VehicleManufacturesRead[];
  models: VehicleModelsRead[];
  statuses: VehicleStatusesRead[];
  parks: GroupOutput[];
  submitButtonLabel: string;
  handleFormSubmit: (data: Partial<FormSchemaType>) => void;
  vehicle?: Electricbus;
  deleteButton?: JSX.Element;
  loading: boolean;
};

const getFormDefaultValues = (
  vehicle: Electricbus,
  parks: GroupOutput[]
): FormSchemaType => {
  const park = parks.find((elem) =>
    elem.children?.find((el) => el.id === vehicle.group_id)
  ) as GroupOutput;

  return {
    ...vehicle,
    garage_number: String(vehicle.garage_number),
    park_id: park?.id,
  };
};

export function VehicleForm({
  types,
  manufacturers,
  models,
  statuses,
  parks,
  submitButtonLabel,
  handleFormSubmit,
  vehicle,
  deleteButton,
  loading,
}: Props) {
  const {
    formState: { errors, isValid, dirtyFields },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: vehicle ? getFormDefaultValues(vehicle, parks) : {},
  });

  const parkId = watch('park_id');

  useEffect(() => {
    if (parkId === undefined) {
      return;
    }

    const park = parks.find((park) => park.id === parkId);

    if (park && park.children?.[0].id) {
      setValue('group_id', park.children?.[0].id, { shouldDirty: true });
    }
  }, [parkId]);

  const onSubmit = async (data: FormSchemaType) => {
    const updatedFields = filterObject(data, dirtyFields);

    handleFormSubmit(updatedFields);
  };

  return (
    <Spin spinning={loading}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormLayout>
          <FormColumn>
            <FormItemContainer>
              <FormItemLabel>Тип ТС</FormItemLabel>
              <Controller
                name="type_id"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomSelect
                    {...field}
                    options={types.map((el) => ({
                      value: el.id,
                      label: el.name,
                    }))}
                    allowClear
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.type_id && <p>{errors.type_id.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>

            <FormItemContainer>
              <FormItemLabel>Модель ТС</FormItemLabel>
              <Controller
                name="model_id"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomSelect
                    {...field}
                    options={models.map((el) => ({
                      value: el.id,
                      label: el.name,
                    }))}
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.model_id && <p>{errors.model_id.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>
            <FormItemContainer>
              <FormItemLabel>Производитель</FormItemLabel>
              <Controller
                name="manufacture_id"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomSelect
                    {...field}
                    options={manufacturers.map((el) => ({
                      value: el.id,
                      label: el.name,
                    }))}
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.manufacture_id && (
                  <p>{errors.manufacture_id.message}</p>
                )}
              </FieldErrorContainer>
            </FormItemContainer>

            <FormItemContainer>
              <FormItemLabel>Статус ТС</FormItemLabel>
              <Controller
                name="status_id"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomSelect
                    {...field}
                    options={statuses.map((el) => ({
                      value: el.id,
                      label: el.name,
                    }))}
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.status_id && <p>{errors.status_id.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>
          </FormColumn>

          <FormColumn>
            <FormItemContainer>
              <FormItemLabel>Парк</FormItemLabel>
              <Controller
                name="park_id"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomSelect
                    {...field}
                    options={parks.map((el) => ({
                      value: el.id,
                      label: el.name,
                    }))}
                  />
                )}
              />
              <FieldErrorContainer></FieldErrorContainer>
            </FormItemContainer>

            <FormItemContainer>
              <FormItemLabel>Автоколонна</FormItemLabel>
              <Controller
                name="group_id"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomSelect
                    {...field}
                    disabled={parkId === undefined}
                    options={
                      parks
                        .find((park) => park.id === parkId)
                        ?.children?.map((el) => ({
                          value: el.id,
                          label: el.name,
                        })) || []
                    }
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.group_id && <p>{errors.group_id.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>
          </FormColumn>

          <FormColumn>
            <FormItemContainer>
              <FormItemLabel>Гаражный номер</FormItemLabel>
              <Controller
                name="garage_number"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    inputSize="md"
                    placeholder="Введите значение"
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.garage_number && <p>{errors.garage_number.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>

            <FormItemContainer>
              <FormItemLabel>ГРЗ</FormItemLabel>
              <Controller
                name="grn"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    inputSize="md"
                    placeholder="Введите номер ТС"
                  />
                )}
              />
              <FieldErrorContainer>
                {errors?.grn && <p>{errors.grn.message}</p>}
              </FieldErrorContainer>
            </FormItemContainer>
          </FormColumn>
        </FormLayout>

        <FormButtonsContainer>
          {deleteButton}
          <UiButton variant="outline">Сбросить</UiButton>
          <UiButton variant="primary" type="submit">
            {submitButtonLabel}
          </UiButton>
        </FormButtonsContainer>
      </form>
    </Spin>
  );
}
