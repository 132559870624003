import { useGetTrackersModelsQuery } from '@shared/api/services/common/enhanced';
import { useGetFirmwaresQuery } from '@shared/api/services/tracker/enhanced';

export function useData() {
  const {
    data: trackersModels,
    error: trackersModelsError,
    isLoading: areTrackersModelsLoading,
    isSuccess: areTrackersModelsSuccess,
  } = useGetTrackersModelsQuery();

  const {
    data: firmwaresData,
    error: firmwaresError,
    isLoading: areFirmwaresLoading,
    isSuccess: areFirmwaresDataSuccess,
  } = useGetFirmwaresQuery();

  const error = [trackersModelsError, firmwaresError].find(
    (err) => err !== undefined
  );
  const isLoading = areTrackersModelsLoading || areFirmwaresLoading;

  const isSuccess = areTrackersModelsSuccess && areFirmwaresDataSuccess;

  return {
    isSuccess,
    isLoading,
    error,
    trackersModels,
    firmwaresData,
  };
}
