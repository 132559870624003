import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { UiModal } from '@shared/ui/ui-modal';
import { RowSelectionState } from '@tanstack/react-table';
import { ConnectedGroupOperationsForm } from '../form';

type Props = {
  selectedImeis: string[];
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
};

export const GroupOperationsModal = NiceModal.create(
  ({ selectedImeis, setRowSelection }: Props) => {
    const modal = useModal();

    return (
      <UiModal isOpen={modal.visible} onClose={modal.hide}>
        <UiModal.Header onClose={modal.hide}>
          Изменение параметров трекеров
        </UiModal.Header>
        <UiModal.Body>
          <ConnectedGroupOperationsForm
            selectedImeis={selectedImeis}
            setRowSelection={setRowSelection}
          />
        </UiModal.Body>
      </UiModal>
    );
  }
);
