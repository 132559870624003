import styled from 'styled-components';

export const Switch = styled.label`
  // Temp
  visibility: hidden;
  //

  position: relative;
  display: inline-block;
  width: 52px;
  height: 24px;
  border-radius: 44px;
  border: 1px solid #fff;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  transition: 0.4s;
`;

export const ThemeIconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: 0.4s;
  color: '#1FC48B';
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} ${ThemeIconContainer} {
    transform: translateX(28px);
  }
`;
