export function FiltersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
    >
      <path
        d="M1.83398 3.84375C1.2817 3.84375 0.833984 4.29147 0.833984 4.84375C0.833984 5.39603 1.2817 5.84375 1.83398 5.84375V3.84375ZM1.83398 14.1562C1.2817 14.1562 0.833984 14.604 0.833984 15.1562C0.833984 15.7085 1.2817 16.1562 1.83398 16.1562V14.1562ZM7.9451 16.1562C8.49738 16.1562 8.9451 15.7085 8.9451 15.1562C8.9451 14.604 8.49738 14.1562 7.9451 14.1562V16.1562ZM20.1673 16.1562C20.7196 16.1562 21.1673 15.7085 21.1673 15.1562C21.1673 14.604 20.7196 14.1562 20.1673 14.1562V16.1562ZM14.0562 3.84375C13.5039 3.84375 13.0562 4.29147 13.0562 4.84375C13.0562 5.39603 13.5039 5.84375 14.0562 5.84375V3.84375ZM20.1673 5.84375C20.7196 5.84375 21.1673 5.39603 21.1673 4.84375C21.1673 4.29147 20.7196 3.84375 20.1673 3.84375V5.84375ZM1.83398 5.84375H4.88954V3.84375H1.83398V5.84375ZM1.83398 16.1562H7.9451V14.1562H1.83398V16.1562ZM17.1118 16.1562H20.1673V14.1562H17.1118V16.1562ZM14.0562 5.84375H20.1673V3.84375H14.0562V5.84375ZM16.1118 15.1562C16.1118 16.3243 15.1798 17.25 14.0562 17.25V19.25C16.3077 19.25 18.1118 17.4054 18.1118 15.1562H16.1118ZM14.0562 17.25C12.9326 17.25 12.0007 16.3243 12.0007 15.1562H10.0007C10.0007 17.4054 11.8047 19.25 14.0562 19.25V17.25ZM12.0007 15.1562C12.0007 13.9882 12.9326 13.0625 14.0562 13.0625V11.0625C11.8047 11.0625 10.0007 12.9071 10.0007 15.1562H12.0007ZM14.0562 13.0625C15.1798 13.0625 16.1118 13.9882 16.1118 15.1562H18.1118C18.1118 12.9071 16.3077 11.0625 14.0562 11.0625V13.0625ZM10.0007 4.84375C10.0007 6.01184 9.06867 6.9375 7.9451 6.9375V8.9375C10.1966 8.9375 12.0007 7.09292 12.0007 4.84375H10.0007ZM7.9451 6.9375C6.82152 6.9375 5.88954 6.01184 5.88954 4.84375H3.88954C3.88954 7.09292 5.6936 8.9375 7.9451 8.9375V6.9375ZM5.88954 4.84375C5.88954 3.67566 6.82152 2.75 7.9451 2.75V0.75C5.6936 0.75 3.88954 2.59458 3.88954 4.84375H5.88954ZM7.9451 2.75C9.06867 2.75 10.0007 3.67566 10.0007 4.84375H12.0007C12.0007 2.59458 10.1966 0.75 7.9451 0.75V2.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
