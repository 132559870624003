import NiceModal from '@ebay/nice-modal-react';

import { EditIcon } from '@shared/icons/edit';
import { EditVehicleModal } from '../modal';

import { StyledButton } from './styles';

type Props = {
  vehicle: {}; // TODO:
};

export function EditVehicleButton({ vehicle }: Props) {
  const showModal = () => {
    NiceModal.show(EditVehicleModal, { vehicle });
  };

  return (
    <StyledButton onClick={() => showModal()}>
      <EditIcon />
    </StyledButton>
  );
}
