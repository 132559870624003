import styled from 'styled-components';
import { StyledCheckbox } from '@shared/ui/custom-checkbox/styled';

export const HeaderCheckbox = styled(StyledCheckbox)`
  padding-bottom: 0px;
`;

export const SelectTrackerCheckbox = styled(StyledCheckbox)`
  padding-top: 0px;
  padding-bottom: 0px;
`;
