import { createSlice } from '@reduxjs/toolkit';
import { StationStatusEnum } from '@shared/api/services/integration/api';

type State = {
  stationId?: string;
  /** List park names */
  parkNames?: string[];
  stationName?: string;
  stationStatuses?: StationStatusEnum; // временно строка
  portsCount?: number[];
  dynamicBalancingMode?: boolean;
  powerFrom?: number;
  powerTo?: number;
};

const initialState: State & { type?: 'УБЗС' } = {
  stationId: undefined,
  parkNames: [],
  stationName: undefined,
  stationStatuses: undefined,
  portsCount: [],
  dynamicBalancingMode: undefined,
  powerFrom: undefined,
  powerTo: undefined,
  type: 'УБЗС',
};

const slice = createSlice({
  name: 'stationsFilters',
  initialState,
  reducers: {
    setFilters: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const { setFilters } = slice.actions;

export const selectStationsFilters = (state: RootState) =>
  state.stationsFilters;

export const selectHasFilters = (state: RootState) => {
  const {
    stationId,
    parkNames,
    stationName,
    stationStatuses,
    portsCount,
    dynamicBalancingMode,
    powerFrom,
    powerTo,
  } = state.stationsFilters;

  if (
    [
      stationId,
      parkNames,
      stationName,
      stationStatuses,
      portsCount,
      dynamicBalancingMode,
      powerFrom,
      powerTo,
    ].some((el) => el !== undefined)
  ) {
    return true;
  }

  return false;
};

export default slice.reducer;
