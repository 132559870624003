import { GetGroupsApiGroupsGetApiResponse } from '@shared/api/services/common/api';
import { VehicleTableType } from '../model/vehicle-table-type';
import { GetHotStorageApiHotStorageGetApiResponse } from '@shared/api/services/telemetry/api';

export const getLngLat = ({
  mapType,
  parks,
  data,
  eParkId,
  convoyId,
  eBusId,
}: {
  mapType: VehicleTableType | null;
  parks?: GetGroupsApiGroupsGetApiResponse;
  data?: GetHotStorageApiHotStorageGetApiResponse;
  eParkId?: string;
  convoyId?: string;
  eBusId?: string;
}): { initialLatitude?: number; initialLongitude?: number } => {
  let firstEbus;
  if (mapType === 'PARK') {
    const currentElectricBusPark = parks?.find(
      ({ id }) => String(id) === String(eParkId)
    );
    const [firstConvoy] = currentElectricBusPark?.children ?? [];
    const [firstEbusValue] = firstConvoy?.vehicles ?? [];
    firstEbus = firstEbusValue;
  }

  if (mapType === 'CONVOY') {
    const currentConvoy = parks
      ?.find(({ id }) => String(id) === String(eParkId))
      ?.children?.find(({ id }) => String(id) === convoyId);
    const [firstEbusValue] = currentConvoy?.vehicles ?? [];
    firstEbus = firstEbusValue;
  }

  if (mapType === 'ELECTROBUS') {
    firstEbus = parks
      ?.find(({ id }) => String(id) === String(eParkId))
      ?.children?.find(({ id }) => String(id) === convoyId)
      ?.vehicles?.find(({ id }) => id === eBusId);
  }

  const targetGrn = firstEbus?.grn;
  const initialLatitude = data?.find(
    ({ ev }) => ev?.grn === targetGrn
  )?.latitude;
  const initialLongitude = data?.find(
    ({ ev }) => ev?.grn === targetGrn
  )?.longitude;

  return {
    initialLatitude,
    initialLongitude,
  };
};
