import { InputHTMLAttributes } from 'react';

import { StyledInput } from './styles';
import { UiInputSize } from './types';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  inputSize: UiInputSize;
};

export function UiInput({ inputSize, ...props }: Props) {
  return <StyledInput {...props} inputSize={inputSize} />;
}
