import { CellContext } from '@tanstack/react-table';

import { Transport } from '@entities/transport/model/types';

import { NoInfo, StyledCell } from './styles';

// TODO: accessor
export function SocCell(props: CellContext<Transport, Transport['soc']>) {
  const val = props.getValue();

  if (!val) {
    return <NoInfo>No information</NoInfo>;
  }

  return <StyledCell percent={val}>{val} %</StyledCell>;
}
