import styled from 'styled-components';

import { UiButton } from '@shared/ui/ui-button';
import { Input } from 'antd';

export const FormItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

// export const LoginErrorMessage = styled.div`
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 15px;
//   color: ${COLOR.red};
// `;

export const StyledButton = styled(UiButton)`
  padding: 17px 16px;
`;

export const StyledPasswordInput = styled(Input.Password)`
  background-color: rgba(239, 241, 249, 0.6);
  padding: 8px 16px;
`;

export const StyledInput = styled(Input)`
  background-color: rgba(239, 241, 249, 0.6);
  padding: 8px 16px;
`;

export const ButtonContainer = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
`;
