import { FC, useEffect, useState } from 'react';
import {
  PortStationEnum,
  StationOutput,
} from '@shared/api/services/integration/api';
import { CloseIcon } from '@shared/icons/close';
import { ChargingStationInfoCard } from '@entities/charging-station/ui/charging-station-info-card';
import {
  CloseButton,
  StyledContainer,
  StationsListContainer,
  StationsList,
  ClusterHeader,
  TitleOfWidget,
  ClusterCommonProperty,
  LocationProperty,
} from './styles';

type Props = {
  handleClose: () => void;
  stations: StationOutput[];
};

export const StationsInfo: FC<Props> = ({ handleClose, stations }) => {
  const [selectedStation, selectStation] = useState<StationOutput | null>(null);

  useEffect(() => {
    const isSelectedStation = stations.some(
      (station) => station.station_id === selectedStation?.station_id
    );
    if (!isSelectedStation) {
      selectStation(null);
    }
  }, [stations]);

  const totalStations = stations.length;
  const readyStations = stations.filter(
    ({ status }) => status === 'READY'
  ).length;

  const ports = stations.reduce((acc, curr) => {
    const currPorts =
      (curr.ports as Array<{
        number: number;
        status: PortStationEnum;
      }>) ?? [];
    return [...acc, ...currPorts];
  }, [] as Array<{ number: number; status: PortStationEnum }>);
  const totalPorts = ports.length;
  const readyPorts = ports.filter(({ status }) => status === 'READY').length;

  const renderedCards = stations.map((station) => {
    return (
      <li
        key={station.station_id}
        style={{ width: '48%', marginBottom: '5px', marginRight: '5px' }}
        onClick={() => selectStation(station)}
      >
        <ChargingStationInfoCard
          station={station}
          selectedStationId={selectedStation?.station_id ?? undefined}
        />
      </li>
    );
  });

  return (
    <StyledContainer className={stations.length ? 'open' : ''}>
      <ClusterHeader>
        <CloseButton onClick={() => handleClose()}>
          <CloseIcon />
        </CloseButton>
        <TitleOfWidget>Кластер</TitleOfWidget>
        <ClusterCommonProperty>
          Станций в работе: {readyStations}/{totalStations}
        </ClusterCommonProperty>
        <ClusterCommonProperty>
          Куполов свободно: {readyPorts}/{totalPorts}
        </ClusterCommonProperty>
        {selectedStation !== null && (
          <LocationProperty>
            {selectedStation?.location
              ? selectedStation?.location
              : 'Адрес не известен'}
          </LocationProperty>
        )}
        <ClusterCommonProperty></ClusterCommonProperty>
      </ClusterHeader>
      <StationsListContainer>
        <StationsList>{renderedCards}</StationsList>
      </StationsListContainer>
    </StyledContainer>
  );
};
