import { useUpdateElectricbusMutation } from '@shared/api/services/electricbus/enhanced';
import {
  openErrorNotification,
  openSuccessNotification,
} from '@shared/lib/notification';
import { Electricbus } from '@shared/api/services/electricbus/types';

import { ConnectedVehicleForm } from '@entities/vehicle/ui/connected-form';
import { FormSchemaType } from '@entities/vehicle/consts/schema';

import { DeleteVehicleButton } from '@features/vehicle/delete/ui/button';

import { EDIT_SUCCESS, EDIT_ERROR } from '../../consts';
import { isErrorWithMessage } from '@shared/lib/http/is-error-with-message';

type Props = {
  vehicle: Electricbus;
  closeModal: () => void;
};

export function EditVehicle({ vehicle, closeModal }: Props) {
  const [trigger, { isLoading }] = useUpdateElectricbusMutation();

  const handleSubmit = async (data: Partial<FormSchemaType>) => {
    const { park_id, ...rest } = data;

    const req = {
      ...rest,
      id: vehicle.id,
    };

    try {
      const res = await trigger(req).unwrap();

      openSuccessNotification(EDIT_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.detail : EDIT_ERROR;

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  return (
    <ConnectedVehicleForm
      submitButtonLabel="Сохранить"
      handleFormSubmit={handleSubmit}
      vehicle={vehicle}
      loading={isLoading}
      deleteButton={
        <DeleteVehicleButton vehicle={vehicle} closeParentModal={closeModal} />
      }
    />
  );
}
