import styled from 'styled-components';

// костыль
const dynamicBgColor = (props: { status: 'ONLINE' | 'OFFLINE' }) => {
  const { status } = props;

  switch (status) {
    case 'ONLINE':
      return '#1FC48B';
    case 'OFFLINE':
      return '#A3AEC4';
  }
};

export const StyledBadge = styled.div`
  width: 164px;
  height: 30px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #f7faff;
  border-radius: 16px;
  background-color: ${dynamicBgColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;
