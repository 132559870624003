export function LogoutIcon() {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4144 13H16.0139M1 18.9779V7.02216M21.0186 18.9779C21.0186 20.2985 19.5248 21.369 17.6821 21.369H11.0093M21.0186 7.02216C21.0186 5.70157 19.5248 4.63101 17.6821 4.63101H11.0093M2.48571 22.0789L5.82214 23.673C8.03937 24.7324 11.0093 23.5932 11.0093 21.6834V4.31662C11.0093 2.40682 8.03938 1.26769 5.82214 2.32706L2.48571 3.92116C1.55752 4.36464 1 5.11123 1 5.91072V20.0893C1 20.8888 1.55752 21.6354 2.48571 22.0789Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
}
