type Entry<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T];

// type Nullable<T> = { [K in keyof T]: T[K] | null };

export function filterObject<T extends object>(
  obj: T,
  dirtyObj: Partial<Record<keyof T, boolean>>
) {
  return Object.fromEntries(
    (Object.entries(obj) as Entry<T>[]).filter(([k]) =>
      Object.hasOwn(dirtyObj, k)
    )
  ) as Partial<T>;
}
