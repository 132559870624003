import { z } from 'zod';

import { numberInString } from '@shared/consts/schemas';

export const FormSchema = z.object({
  grn: z.string().trim().min(1),
  garage_number: z.string().trim().min(1), //
  type_id: z.number(),
  model_id: z.number(),
  group_id: z.number(),
  manufacture_id: z.number(),
  status_id: z.number(),
  // for dependant group_id field
  park_id: z.number(),
});

export type FormSchemaType = z.infer<typeof FormSchema>;
