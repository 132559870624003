import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;

  & > button {
    flex-grow: 1;
  }
`;
