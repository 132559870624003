import { enhancedApi as api, HotStorageWithExtentFields } from '../api';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getHotStorageApiHotStorageGet: (endpoint) => {
      (endpoint.query = (queryArg) => {
        return {
          url: `telemetry/api/hot_storage`,
          params: {
            park_id: queryArg.parkId,
            model_id: queryArg.modelId,
            type_id: queryArg.typeId,
            manufacture_id: queryArg.manufactureId,
            status_id: queryArg.statusId,
            soc_from: queryArg.socFrom,
            soc_to: queryArg.socTo,
            soh_from: queryArg.sohFrom,
            soh_to: queryArg.sohTo,
            ev_id: queryArg.evId,
            group_ids: queryArg.groupIds,
          },
        };
      }),
        (endpoint.transformResponse = (
          response: HotStorageWithExtentFields[]
        ) => response.filter(({ ev }) => ev && ev.grn));
    },
  },
});

export const {
  useGetHotStorageApiHotStorageGetQuery: useGetHotStorageTelemetryQuery,
} = enhancedApi;
