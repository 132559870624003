import { FiltersButton } from '@features/charging-station/filters';
import { HeaderLayout } from '../layout';
import { ContentWrapper, Outer, ChargingStationsTitle } from './styles';

export function ChargingStationHeader() {
  const content = (
    <ContentWrapper>
      <ChargingStationsTitle>Зарядные станции</ChargingStationsTitle>
      <FiltersButton />
    </ContentWrapper>
  );

  return (
    <Outer>
      <HeaderLayout content={content} isStadardHeader={true} />
    </Outer>
  );
}
