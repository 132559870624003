import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 411px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  // overflow: auto;
  background-color: #32353f;
  transform: translateX(100%);
  padding-top: 10px;
  padding-left: 5px;

  transition: transform 0.7s ease;

  &.open {
    transform: translateX(0%);
  }

  @media screen and (max-width: 1920px) {
    width: 378px;
  }

  @media screen and (max-width: 1440px) {
    width: 329px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  margin-left: auto;
  align-self: start;
  color: #a3aec4;

  &:hover {
    color: #f7faff;
  }
`;

export const StationsListContainer = styled.div`
  height: calc(100vh - 110px);
  max-height: calc(100vh - 110px);
  margin-top: 15px;
  overflow: auto;
`;

export const StationsList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

export const ClusterHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: inherit;
  height: 85px;
`;

export const TitleOfWidget = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: #f7faff;

  @media screen and (max-width: 1440px) {
    font-size: 17px;
  }
`;

export const ClusterCommonProperty = styled.p`
  font-weight: 500;
  font-size: 15px;
`;

export const LocationProperty = styled(ClusterCommonProperty)`
  color: #a3aec4;
`;
