export function ArrowDownIcon() {
  return (
    <svg
      width="16"
      height="9"
      viewBox="0 0 16 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 1L8.35355 7.64645C8.15829 7.84171 7.84171 7.84171 7.64645 7.64645L1 0.999999"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
