import { TrackerHeader } from '@widgets/header/ui/tracker';

import { StandardPageLayout } from '../styles';
import { ConnectedTrackersTable } from '@features/trackers/trackers-table/ui/connected-table';
import { useSelectTrackers } from '@features/trackers/group-operations/hooks/use-select-trackers';

export function TrackersPage() {
  const { selectedImeis, rowSelection, setRowSelection } = useSelectTrackers();

  return (
    <StandardPageLayout>
      <TrackerHeader
        setRowSelection={setRowSelection}
        selectedImeis={selectedImeis}
      />
      <ConnectedTrackersTable
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />
    </StandardPageLayout>
  );
}
