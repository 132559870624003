import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const StyledMenuItem = styled.li`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1px;

  font-style: normal;
  font-weight: 600;
  //  line-height: 120%;
  color: white;

  & .ant-collapse-header-text {
    font-size: 17px;
    line-height: normal;

    @media screen and (max-width: 1920px) {
      font-size: 15px;
    }

    @media screen and (max-width: 1440px) {
      font-size: 12px;
    }
  }

  & a.active > div {
    color: #09fba8;
  }
`;

export const MenuItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background-color: transparent;
  /* padding: 12px 16px; */

  /* &:hover {
    background-color: rgba(163, 174, 196, 0.2);
    transition: background-color 0.3s ease;
  } */

  & .menu-item-title {
    background-color: transparent;

    /* &:hover {
      background-color: rgba(163, 174, 196, 0.2);
      transition: background-color 0.3s ease;
    } */
  }
`;

export const NavLinkStyledIcon = styled(NavLink)`
  width: 10px;
  height: 12px;
  position: absolute;
  top: 6.5px;
  left: -18px;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='12' viewBox='0 0 20 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1' y='3' width='18' height='20' rx='1' stroke='%23A3AEC4' stroke-width='2'/%3E%3Cline x1='5' y1='1' x2='5' y2='5' stroke='%23A3AEC4' stroke-width='2' stroke-linecap='round'/%3E%3Cline x1='15' y1='10' x2='8' y2='10' stroke='%23A3AEC4' stroke-width='2' stroke-linecap='round'/%3E%3Cline x1='15' y1='14' x2='8' y2='14' stroke='%23A3AEC4' stroke-width='2' stroke-linecap='round'/%3E%3Cline x1='15' y1='18' x2='8' y2='18' stroke='%23A3AEC4' stroke-width='2' stroke-linecap='round'/%3E%3Cline x1='15' y1='1' x2='15' y2='5' stroke='%23A3AEC4' stroke-width='2' stroke-linecap='round'/%3E%3Cline x1='10' y1='1' x2='10' y2='5' stroke='%23A3AEC4' stroke-width='2' stroke-linecap='round'/%3E%3Ccircle cx='5' cy='10' r='1' fill='%23A3AEC4'/%3E%3Ccircle cx='5' cy='14' r='1' fill='%23A3AEC4'/%3E%3Ccircle cx='5' cy='18' r='1' fill='%23A3AEC4'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;

  @media screen and (max-width: 1920px) {
    top: 5px;
  }

  @media screen and (max-width: 1440px) {
    top: 2.1px;
  }
`;
