import { createColumnHelper } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { StationOutput } from '@shared/api/services/integration/api';
import { ChargingStationStatusBadge } from '@entities/charging-station/ui/charging-station-status-badge';
import { PortsMarkers } from '@entities/charging-station/ui/ports-markers';
import { getTableSettingsColumn } from '@shared/ui/table/settings-column/get-table-settings-column';

const columnHelper = createColumnHelper<
  StationOutput & { type?: 'УБЗС' | 'ЭЗС'; park_group?: string }
>();

export const useColumns = () => {
  const [settings, setSettings] = useState<Record<string, boolean>>({
    status: true,
    ports: true,
    station_id: true,
    name: true,
    park: true,
    park_group: true,
    location: true,
    max_power: true,
    dynamic_balancing_mode: false,
    type: false,
  });

  const DATA_COLUMNS = [
    columnHelper.accessor('status', {
      id: 'status',
      header: 'Статус станции',
      size: 214,
      maxSize: 214,
      minSize: 214,
      cell: (props) => {
        return <ChargingStationStatusBadge status={props.getValue()} />;
      },
    }),
    columnHelper.accessor('ports', {
      id: 'ports',
      header: 'Купола',
      size: 180,
      maxSize: 180,
      minSize: 180,
      cell: (props) => {
        const ports = props.getValue();
        return (
          <PortsMarkers
            ports={ports}
            stationId={props.row.original.station_id}
            size="16px"
          />
        );
      },
    }),
    columnHelper.accessor('station_id', {
      id: 'station_id',
      header: 'ID',
      size: 235,
      maxSize: 235,
      minSize: 235,
      cell: (props) => {
        const stationId = props.getValue();
        return stationId ?? 'Нет данных';
      },
    }),
    columnHelper.accessor('name', {
      id: 'name',
      header: 'Название',
      size: 160,
      maxSize: 160,
      minSize: 160,
      cell: (props) => {
        const name = props.getValue();
        return name ?? 'Нет данных';
      },
    }),
    columnHelper.accessor('park', {
      id: 'park',
      header: 'Парк', // выяснить у Володи группу
      size: 204,
      maxSize: 204,
      minSize: 204,
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
    }),
    columnHelper.accessor('park_group', {
      id: 'park_group',
      header: 'Парковая группа', // выяснить у Володи группу
      size: 170,
      maxSize: 170,
      minSize: 170,
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      enableSorting: false,
    }),
    columnHelper.accessor('location', {
      id: 'location',
      header: 'Адрес',
      size: 320,
      maxSize: 320,
      minSize: 320,
      cell: (props) => {
        return props.getValue() ?? 'Нет данных';
      },
      enableSorting: false,
    }),
    columnHelper.accessor('max_power', {
      id: 'max_power',
      header: (
        <>
          <p>Разрешенная</p>
          <p>мощность (кВт)</p>
        </>
      ) as unknown as string,
      size: 208,
      maxSize: 208,
      minSize: 208,
      cell: (props) => {
        const maxPower = props.getValue();

        if (maxPower === undefined || maxPower === null) {
          return 'Нет данных';
        }

        return maxPower / 1000;
      },
    }),
    columnHelper.accessor('dynamic_balancing_mode', {
      id: 'dynamic_balancing_mode',
      header: 'Балансировка',
      size: 184,
      maxSize: 184,
      minSize: 184,
      cell: (props) => {
        return props.getValue() ? 'Да' : 'Нет';
      },
    }),
    columnHelper.accessor('type', {
      // вот здесь должно быть поле type (ЭЗС или УБЗС), у Володи запросить надо
      id: 'type',
      header: 'Тип',
      size: 80,
      maxSize: 80,
      minSize: 80,
      cell: (props) => {
        return 'УБЗС';
      },
      enableSorting: false,
    }),
  ];

  const settingsColumn = getTableSettingsColumn({
    columnHelper,
    columns: DATA_COLUMNS.map(({ id, header }) => {
      const typedId = id!;
      return {
        key: id as string,
        label: header as string,
        isChecked: settings[typedId],
      };
    }),
    settings,
    renderCell: (props) => null,
    setVisibleColumns: (cols: Record<string, boolean>) => {
      setSettings(cols);
    },
  });

  const visibleColumns = useMemo(() => {
    const dataCols = settings
      ? DATA_COLUMNS.filter(({ id }) => {
          const typedId = id!;
          return settings[typedId];
        })
      : DATA_COLUMNS;

    return [...dataCols, settingsColumn];
  }, [settings]);

  return visibleColumns;
};
