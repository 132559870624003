export function AscendIcon() {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75 9.17578L6.64303 4.18681C6.83905 3.98695 7.16095 3.98695 7.35697 4.18681L12.25 9.17578"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
