import { enhancedApi as api } from '../api';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getGroupsApiGroupsGet: (endpoint) => {
      endpoint.query = () => `common/api/groups`;
      endpoint.providesTags = ['VehicleGroups'];
    },
    // getGroupsApiGroupsGet: (endpoint) => {
    //   endpoint.query = () => `common/api/groups`;
    // },
    // getElectricbusesApiElectricbusGet: { providesTags: ['Electricbuses'] },
    getManufacturesApiVehiclesManufacturesGet: (endpoint) => {
      endpoint.query = () => `common/api/vehicles/manufactures`;
      endpoint.providesTags = ['VehicleManufacturers'];
    },
    getStatusesApiVehiclesTypesGet: (endpoint) => {
      endpoint.query = () => `common/api/vehicles/types`;
      endpoint.providesTags = ['VehicleTypes'];
    },
    getManufacturesApiVehiclesModelsGet: (endpoint) => {
      endpoint.query = () => `common/api/vehicles/models`;
      endpoint.providesTags = ['VehicleModels'];
    },
    getStatusesApiVehiclesStatusesGet: (endpoint) => {
      endpoint.query = () => `common/api/vehicles/statuses`;
      endpoint.providesTags = ['VehicleStatuses'];
    },
    getGroupsApiTrackersModelsGet: (endpoint) => {
      endpoint.query = () => `common/api/trackers/models`;
      endpoint.providesTags = ['TrackerModels'];
    },
  },
});

export const {
  useGetGroupsApiGroupsGetQuery: useGetGroupsQuery,
  useGetManufacturesApiVehiclesManufacturesGetQuery: useGetManufacturersQuery,
  useGetStatusesApiVehiclesTypesGetQuery: useGetVehicleTypesQuery,
  useGetManufacturesApiVehiclesModelsGetQuery: useGetVehicleModelsQuery,
  useGetStatusesApiVehiclesStatusesGetQuery: useGetVehicleStatusesQuery,
  useGetGroupsApiTrackersModelsGetQuery: useGetTrackersModelsQuery,
} = enhancedApi;
