import { Spin } from 'antd';

import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller, type FieldValues } from 'react-hook-form';

import { setCredentials } from '@shared/redux/slices/auth/storage-slice';
import { UiButton } from '@shared/ui/ui-button';

import { useAppDispatch } from '@app/hooks';

import {
  ButtonContainer,
  FormItemsContainer,
  StyledButton,
  StyledInput,
  StyledPasswordInput,
} from './styles';

import { UserIcon } from '../../images/user';
import { LockIcon } from '../../images/lock';
import { openErrorNotification } from '@shared/lib/notification';
import { useLoginMutation } from '@shared/api/services/user/enhanced';

type Props = {
  // loading: boolean;
  // handleSubmit: (values: LoginFormData) => Promise<void>;
  // errorMsg: string;
  // clearErrorMsg: () => void;
};

export function LoginForm({}: // handleSubmit,
// loading,
// errorMsg,
// clearErrorMsg,
Props) {
  const {
    formState: { errors, isValid },
    handleSubmit,

    control,
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const [trigger, { isLoading }] = useLoginMutation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || '/';

  const onSubmit = async (data: FieldValues) => {
    try {
      const res = await trigger(data).unwrap();

      dispatch(setCredentials(res));

      navigate(from);
    } catch (err) {
      const errorText = err?.data?.detail;

      if (errorText) {
        openErrorNotification(errorText);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="password">
      <FormItemsContainer>
        <Controller
          name="username"
          control={control}
          render={({ field, fieldState }) => (
            <StyledInput
              {...field}
              placeholder="Логин"
              prefix={<UserIcon />}
              className={
                fieldState.invalid ? 'custom-input error' : 'custom-input'
              }
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }) => (
            <StyledPasswordInput
              {...field}
              placeholder="Пароль"
              prefix={<LockIcon />}
              className={
                fieldState.invalid ? 'custom-input error' : 'custom-input'
              }
            />
          )}
        />
      </FormItemsContainer>
      <ButtonContainer>
        <Spin spinning={isLoading}>
          <UiButton
            variant="primary"
            disabled={!isValid}
            style={{ padding: '17px 16px' }}
          >
            Отправить
          </UiButton>
        </Spin>
      </ButtonContainer>
    </form>
  );
}
