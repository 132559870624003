import styled from 'styled-components';

export const StationPlacemarkContainer = styled.div`
  position: relative;
  width: 70px;
  height: 73px;
`;

export const StationPlacemarkWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70px;
  height: 73px;
  bottom: 56px;
  right: 35px;
`;

export const StyledStationSvgSign = styled.svg`
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
`;

export const StyledLabelArrow = styled.svg`
  margin-left: auto;
  margin-right: auto;
  margin-top: -2px;
`;

export const StationPinLabel = styled.div<{ fill: string }>`
  align-self: center;
  width: auto;
  height: 25px;
  padding: 4px 5px 0px;
  background-color: ${({ fill }) => fill};
  border-radius: 4px;
`;
