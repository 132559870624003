export const colorMap = {
  red: '#F86767',
  green: '#23c98ff3',
  grey: '#BEBEBE',
  darkGrey: '#555555',
  khaki: '#F2B71F',
  yellow: '#ff8400',
  blue: '#1500ff',
  darkRed: '#ff0000',
  white: '#ffffff',
  black: '#000000',
};
