import styled from 'styled-components';

export const StyledMenuItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  // line-height: 120%;

  & a.active > div {
    background-color: rgba(21, 233, 160, 0.4);
    color: #09fba8;
  }

  @media screen and (max-width: 1920px) {
    font-size: 15px;
  }

  @media screen and (max-width: 1440px) {
    font-size: 12px;
  }
`;

export const MenuItemTitle = styled.div`
  // здесь
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  background-color: transparent;
  padding: 6px 16px 6px;
  line-height: 120%;
  border-radius: 8px !important;

  &:hover {
    background-color: rgba(163, 174, 196, 0.2);
    transition: background-color 0.3s ease;
  }
`;

export const MenuItemContent = styled.div`
  display: flex;
  gap: 8px;
`;

export const MenuItemLabel = styled.p`
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;
