import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';

import { MenuItemContainer, StyledMenuItem } from './styles';

type Props = {
  path: string;
  content?: React.ReactNode;
};

export function SiderNavMenuItemLayout({ content, path }: Props) {
  return (
    <StyledMenuItem>
      <NavLink to={path} key={path}>
        <MenuItemContainer>{content}</MenuItemContainer>
      </NavLink>
    </StyledMenuItem>
  );
}
