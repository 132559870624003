import { TrackerOutput } from '@shared/api/services/electricbus/api';
import { TrackerStatusMap } from '@entities/vehicle/consts/tracker-status-map';

import { StyledBadge } from './styles';

type Props = {
  status: TrackerOutput['status'];
};

export function TrackerStatusBadge({ status }: Props) {
  return <StyledBadge status={status}>{TrackerStatusMap[status]}</StyledBadge>;
}
