import styled from 'styled-components';

export const SortingButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 14px;
  height: 22px;
  margin-top: auto;
`;

const dynamicColor = (props: { sorted: boolean }) => {
  const { sorted } = props;
  if (sorted) {
    return '#09FBA8';
  }

  return '#a3aec4';
};

export const SortingButton = styled.button`
  color: ${dynamicColor};
  cursor: pointer;
  height: 50%;

  &:not(.sorted):hover {
    color: #f7faff;
  }

  /* &:hover {
    color: #f7faff;
  } */
`;
