import { Popover } from 'antd';
import styled from 'styled-components';

export const StyledUserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 240px;

  cursor: pointer;
`;

export const AvatarMock = styled.span`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: #1fc48b;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UserInfoContainer = styled.div`
  padding-right: 8px;
`;

export const UserFullName = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const UserRole = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #a3aec4;
`;

export const StyledPopover = styled(Popover)`
  background-color: grey;
`;
