import { useAddElectricbusMutation } from '@shared/api/services/electricbus/enhanced';
import {
  openErrorNotification,
  openSuccessNotification,
} from '@shared/lib/notification';
import { isErrorWithMessage } from '@shared/lib/http/is-error-with-message';

import { ConnectedVehicleForm } from '@entities/vehicle/ui/connected-form';
import { FormSchemaType } from '@entities/vehicle/consts/schema';

import { ADD_SUCCESS, ADD_ERROR } from '../../consts';

type Props = {
  closeModal: () => void;
};

export function AddVehicle({ closeModal }: Props) {
  const [trigger, { isLoading }] = useAddElectricbusMutation();

  const handleSubmit = async (data: FormSchemaType) => {
    const { park_id, ...req } = data;

    try {
      const res = await trigger(req).unwrap();

      openSuccessNotification(ADD_SUCCESS);
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage ? err.data.detail : ADD_ERROR;

      openErrorNotification(errorText);
    } finally {
      closeModal();
    }
  };

  return (
    <ConnectedVehicleForm
      submitButtonLabel="Добавить"
      handleFormSubmit={handleSubmit}
      loading={isLoading}
    />
  );
}
