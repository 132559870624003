import { createSlice } from '@reduxjs/toolkit';
import { StatusTracker } from '@shared/api/services/tracker/api';

type State = {
  modelIds: number[];
  statuses: StatusTracker[];
  firmware?: string;
};

const initialState: State = {
  modelIds: [],
  statuses: [],
  firmware: undefined,
};

const slice = createSlice({
  name: 'trackersFilters',
  initialState,
  reducers: {
    setFilters: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const { setFilters } = slice.actions;

export const selectTrackersFilters = (state: RootState) =>
  state.trackersFilters;

export const selectHasFilters = (state: RootState) => {
  const { modelIds, statuses, firmware } = state.trackersFilters;

  if (
    [modelIds, statuses, firmware].some(
      (el) => el !== undefined && el?.length > 0
    )
  ) {
    return true;
  }

  return false;
};

export default slice.reducer;
