import { StyledLayout } from './styles';

type Props = {
  children: JSX.Element;
  sidebar: JSX.Element;
};

export function RootLayout({ sidebar, children }: Props) {
  return (
    <StyledLayout>
      {sidebar}
      {children}
    </StyledLayout>
  );
}
