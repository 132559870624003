import { Spin } from 'antd';

import { UiButton } from '../ui-button';
import { ButtonsContainer } from './styles';

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  loading: boolean;
};

export function ConfirmDialog({ onCancel, onConfirm, loading }: Props) {
  return (
    <Spin spinning={loading}>
      <ButtonsContainer>
        <UiButton variant="danger" onClick={onConfirm}>
          Удалить
        </UiButton>
        <UiButton variant="outline" onClick={onCancel}>
          Отмена
        </UiButton>
      </ButtonsContainer>
    </Spin>
  );
}
