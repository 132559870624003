import { Button, SelectProps } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { UiRoundedCheckbox } from '../../ui-rounded-checkbox';
import { SelectAllButton, SelectAllLabel } from './styles';

type Props = Pick<SelectProps, 'options' | 'onChange' | 'value'> & {
  showSelectAll: boolean;
};

export function useSelectAll({
  showSelectAll,
  options = [],
  value,
  onChange,
}: Props) {
  const handleSelectAll = useCallback(() => {
    onChange?.(
      options.map((option) => option.value),
      options
    );
  }, [onChange, options]);

  const handleUnselectAll = useCallback(() => {
    onChange?.([], []);
  }, [onChange]);

  const enchancedOptions = useMemo(() => {
    if (!showSelectAll) return options;

    const toggleAllOption = value?.length ? (
      <SelectAllButton onClick={() => handleUnselectAll()}>
        <UiRoundedCheckbox checked={value.length === options.length} disabled />
        <SelectAllLabel>Все</SelectAllLabel>
      </SelectAllButton>
    ) : (
      <SelectAllButton onClick={() => handleSelectAll()}>
        <UiRoundedCheckbox
          checked={value?.length === options.length}
          disabled
        />
        <SelectAllLabel>Все</SelectAllLabel>
      </SelectAllButton>
    );

    return [
      {
        label: toggleAllOption,
        options,
      },
    ];
  }, [
    handleSelectAll,
    handleUnselectAll,
    options,
    showSelectAll,
    value?.length,
  ]);

  return {
    options: enchancedOptions,
    value,
    onChange,
    handleUnselectAll,
  };
}
