import { URLSearchParamsInit } from 'react-router-dom';

export const resetPageByFiltering = (
  searchParams: URLSearchParamsInit,
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          state?: any;
        }
      | undefined
  ) => void
) => {
  const updatedSearchParams = new URLSearchParams(searchParams.toString());
  updatedSearchParams.set('page', '1');
  setSearchParams(updatedSearchParams.toString());
};
