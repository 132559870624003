import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';

import { QUERY_PARAM } from '@shared/consts/server';
import { usePaginationState, useSortingState } from '@shared/ui/table/hooks';
import { ServerTableLayout } from '@shared/ui/table/layout';
import { StationOutput } from '@shared/api/services/integration/api';
import { useColumns } from '../../hooks/use-columns';
type Props = {
  count: number | undefined;
  fetching: boolean;
  tableData: StationOutput[];
  defaultPageSize: number;
};

export const ChargingStationsTableInstance: FC<Props> = ({
  count,
  fetching,
  tableData,
  defaultPageSize,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const columns = useColumns();

  const size = searchParams.get(QUERY_PARAM.limit);
  const sorting = useSortingState();
  const pagination = usePaginationState();

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      sorting,
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (size ? Number(size) : defaultPageSize))
      : undefined,
  });

  return (
    <ServerTableLayout
      loading={fetching}
      table={table}
      rowsCount={count}
      defaultPageSize={defaultPageSize}
    />
  );
};
