import NiceModal from '@ebay/nice-modal-react';

import { AddIcon } from '@shared/icons/add';

import { AddTrackerModal } from '@features/trackers/add/ui/modal';
import { ButtonLabel, StyledButton } from './styles';

export function AddTrackerButton() {
  const showAddModal = () => {
    NiceModal.show(AddTrackerModal, {});
  };

  return (
    <StyledButton
      onClick={() => {
        showAddModal();
      }}
    >
      <ButtonLabel>Добавить трекер</ButtonLabel>
      <AddIcon />
    </StyledButton>
  );
}
