import { useParams } from 'react-router-dom';

import { FiltersButton } from '@features/vehicle/filters';

import { useGetGroupsQuery } from '@shared/api/services/common/enhanced';
import { HeaderLayout } from '../layout';
import { ContentWrapper, Outer, TransportTitle } from './styles';

type Props = {
  showVehicleInfo: boolean;
};

export function ElectrobusPageHeader({ showVehicleInfo }: Props) {
  const { eParkId, convoyId, eBusId } = useParams();

  const { data, isLoading, error, isSuccess } = useGetGroupsQuery(undefined, {
    skip: false,
  });

  const park = data?.find(({ id }) => Number(id) === Number(eParkId));
  const convoy = park?.children?.find(({ id }) => String(id) === convoyId);
  const ebus = convoy?.vehicles?.find(({ id }) => id === eBusId);

  const title = `${park?.name ?? ''}, ${convoy?.name ?? ''}, ${
    ebus?.grn ?? ''
  }`;

  const content = (
    <ContentWrapper>
      <TransportTitle>{title}</TransportTitle>
    </ContentWrapper>
  );

  return (
    <Outer showVehicleInfo={showVehicleInfo}>
      <HeaderLayout content={content} isStadardHeader={false} />
    </Outer>
  );
}
