export function CloseIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.70711 1.29289C2.31658 0.902369 1.68342 0.902369 1.29289 1.29289C0.902369 1.68342 0.902369 2.31658 1.29289 2.70711L2.70711 1.29289ZM13.2929 14.7071C13.6834 15.0976 14.3166 15.0976 14.7071 14.7071C15.0976 14.3166 15.0976 13.6834 14.7071 13.2929L13.2929 14.7071ZM14.7071 2.70711C15.0976 2.31658 15.0976 1.68342 14.7071 1.29289C14.3166 0.902369 13.6834 0.902369 13.2929 1.29289L14.7071 2.70711ZM1.29289 13.2929C0.902369 13.6834 0.902369 14.3166 1.29289 14.7071C1.68342 15.0976 2.31658 15.0976 2.70711 14.7071L1.29289 13.2929ZM1.29289 2.70711L13.2929 14.7071L14.7071 13.2929L2.70711 1.29289L1.29289 2.70711ZM13.2929 1.29289L1.29289 13.2929L2.70711 14.7071L14.7071 2.70711L13.2929 1.29289Z"
        fill="currentColor"
      />
    </svg>
  );
}
