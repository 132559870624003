import type { SelectProps } from 'antd';
import { useState } from 'react';

import { UiRoundedCheckbox } from '@shared/ui/ui-rounded-checkbox';

import {
  CustomSelectContainer,
  SelectOptionLabel,
  StyledAntdSelect,
} from './styles';

type Props = SelectProps<string | number>;

export function CustomSelect(props: Props) {
  const [open, setOpen] = useState(false);

  const restProps: Props = {
    open,
    onDropdownVisibleChange: (visible) => {
      setOpen(visible);
    },
    placeholder: 'Выберите из списка',
    showSearch: false,
    // Отступ между селектом и дропдауном
    dropdownAlign: { offset: [0, 0] },
    menuItemSelectedIcon: null,
    removeIcon: null,
    optionRender: (option) => {
      const checked = Boolean(props.value === option.value);

      return (
        <CustomSelectContainer>
          <UiRoundedCheckbox checked={checked} disabled />
          <SelectOptionLabel>{option.label}</SelectOptionLabel>
        </CustomSelectContainer>
      );
    },
  };

  return <StyledAntdSelect {...props} {...restProps} />;
}
