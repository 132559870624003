import { ConnectedChargingStationsTable } from '@features/charging-station/charging-station-table/ui/connected-table';
import { ChargingStationHeader } from '@widgets/header/ui/charging-station';

import { StandardPageLayout } from '../styles';

export function ChargingStationsPage() {
  return (
    <StandardPageLayout>
      <ChargingStationHeader />
      <ConnectedChargingStationsTable />
    </StandardPageLayout>
  );
}
