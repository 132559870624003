import { baseApi as api } from '../../../base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getHotStorageApiHotStorageGet: build.query<
      GetHotStorageApiHotStorageGetApiResponse,
      GetHotStorageApiHotStorageGetApiArg
    >({
      query: (queryArg) => ({
        url: `/api/hot_storage`,
        params: {
          park_id: queryArg.parkId,
          model_id: queryArg.modelId,
          type_id: queryArg.typeId,
          manufacture_id: queryArg.manufactureId,
          status_id: queryArg.statusId,
          soc_from: queryArg.socFrom,
          soc_to: queryArg.socTo,
          soh_from: queryArg.sohFrom,
          soh_to: queryArg.sohTo,
          ev_id: queryArg.evId,
          group_ids: queryArg.groupIds,
        },
      }),
    }),
    getTelemetryByFilterSrvTelemetriesGet: build.query<
      GetTelemetryByFilterSrvTelemetriesGetApiResponse,
      GetTelemetryByFilterSrvTelemetriesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/srv/telemetries`,
        params: {
          soc_from: queryArg.socFrom,
          soc_to: queryArg.socTo,
          soh_from: queryArg.sohFrom,
          soh_to: queryArg.sohTo,
          mileage_from: queryArg.mileageFrom,
          mileage_to: queryArg.mileageTo,
          power_reserve_from: queryArg.powerReserveFrom,
          power_reserve_to: queryArg.powerReserveTo,
          is_with_tracker: queryArg.isWithTracker,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type GetHotStorageApiHotStorageGetApiResponse =
  /** status 200 Successful Response */ HotStorageWithExtentFields[] | null;
export type GetHotStorageApiHotStorageGetApiArg = {
  parkId: string;
  modelId?: number | null;
  typeId?: number | null;
  manufactureId?: number | null;
  statusId?: number | null;
  socFrom?: number | null;
  socTo?: number | null;
  sohFrom?: number | null;
  sohTo?: number | null;
  evId?: string | null;
  groupIds?: string;
};
export type GetTelemetryByFilterSrvTelemetriesGetApiResponse =
  /** status 200 Successful Response */
    | (TelemetryWithTrackerOutput | TelemetryOutput)[]
    | null;
export type GetTelemetryByFilterSrvTelemetriesGetApiArg = {
  socFrom?: number;
  socTo?: number;
  sohFrom?: number;
  sohTo?: number;
  mileageFrom?: number;
  mileageTo?: number;
  powerReserveFrom?: number;
  powerReserveTo?: number;
  isWithTracker?: boolean;
};
export type EvSchema = {
  id: string;
  type_id?: number | null;
  group_id?: number | null;
  model_id?: number | null;
  manufacture_id?: number | null;
  status_id?: number | null;
  grn?: string | null;
};
export type HotStorageWithExtentFields = {
  time: string;
  latitude: number;
  pre_latitude: number | null;
  longitude: number;
  pre_longitude: number | null;
  tracker_imei: string;
  speed: number;
  soh: number;
  soc: number;
  milleage?: number | null;
  power_reserve?: number | null;
  ev?: EvSchema | null;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type StatusTracker = 'ONLINE' | 'OFFLINE';
export type TrackerInfoOutput = {
  id: string;
  imei: string;
  status: StatusTracker;
  serial_number: string;
  firmware?: string | null;
  ip_address?: string | null;
  ev_id: string;
  ev_type_id: number;
};
export type TelemetryWithTrackerOutput = {
  time: string;
  latitude: number;
  longitude: number;
  speed: number;
  soh: number;
  soc: number;
  tracker_imei: string;
  milleage?: number | null;
  power_reserve?: number | null;
  tracker?: TrackerInfoOutput | null;
};
export type TelemetryOutput = {
  time: string;
  latitude: number;
  pre_latitude: number | null;
  longitude: number;
  pre_longitude: number | null;
  tracker_imei: string;
  speed: number;
  soh: number;
  soc: number;
  milleage?: number | null;
  power_reserve?: number | null;
};
export const {
  useGetHotStorageApiHotStorageGetQuery,
  useGetTelemetryByFilterSrvTelemetriesGetQuery,
} = injectedRtkApi;
