import { GroupOutput } from '@shared/api/services/common/api';

export const getParkNameByGroupId = (
  groupId: number,
  parks: GroupOutput[]
): string => {
  const park = parks.find((park) =>
    park.children?.find((el) => el.id === groupId)
  );

  if (park) {
    return park.name;
  }

  return '';
};

// ПОЛЕЗНО
