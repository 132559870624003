import styled from 'styled-components';
import { PortStationEnum } from '@shared/api/services/integration/api';
import { portStatusColorMap } from '@entities/charging-station/consts/port-status-color-map';

export const PortMarkersWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 16px;
`;

export const PortMarker = styled.div<{
  portStatus: PortStationEnum;
  size: string;
}>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: ${({ portStatus }) => portStatusColorMap[portStatus]};
  margin-right: 12px;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    border: 2px solid white;
  }
`;
