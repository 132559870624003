import { SortingState } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';

import { QUERY_PARAM } from '@shared/consts/server';

const { ordering, type } = QUERY_PARAM;

export const useSortingState = (): SortingState => {
  const [searchParams, setSearchParams] = useSearchParams();

  const orderingParam = searchParams.get(ordering);
  const typeParam = searchParams.get(type);

  if (typeParam === null || orderingParam === null) {
    return [];
  }

  return [
    {
      id: orderingParam,
      desc: typeParam === 'desc',
    },
  ];
};
