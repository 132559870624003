import styled from 'styled-components';

export const StyledButton = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  color: #f7faff;
`;

export const ButtonLabel = styled.p`
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;
