import { AnalyticsMenu } from '../ui/nav-menu/analytics';
import { ChargePointsMenu } from '../ui/nav-menu/charge-points';
import { DriversMenu } from '../ui/nav-menu/drivers';
import { MonitoringMenu } from '../ui/nav-menu/monitoring';
import { RoutesMenu } from '../ui/nav-menu/routes';
import { ScheduleMenu } from '../ui/nav-menu/schedule';
import { SettingsMenu } from '../ui/nav-menu/settings';
import { VehiclesMenu } from '../ui/nav-menu/vehicles';

type Section = {
  element: React.ReactNode;
};

export const SECTIONS: Section[] = [
  //  {
  //    element: <MonitoringMenu />,
  //  },
  // {
  //   element: <ChargePointsMenu />,
  // },
  {
    element: <VehiclesMenu />,
  },
  {
    element: <DriversMenu />,
  },
  {
    element: <ScheduleMenu />,
  },
  {
    element: <RoutesMenu />,
  },
  {
    element: <AnalyticsMenu />,
  },
  {
    element: <SettingsMenu />,
  },
];
