import { GroupOperationsForm } from '@entities/tracker/ui/group-operation-form';
import {
  TrackerCommandBase,
  TrackerCommandWithParams,
} from '@shared/api/services/tracker/api';
import {
  useGetFirmwaresQuery,
  useGetTrackersQuery,
  useGetAllOfTheTrackersQuery,
  useCommandTrackersMutation,
  useCommandTrackersExtendedMutation,
} from '@shared/api/services/tracker/enhanced';
import { isErrorWithMessage } from '@shared/lib/http/is-error-with-message';
import {
  openErrorNotification,
  openSuccessNotification,
} from '@shared/lib/notification';
import { RowSelectionState } from '@tanstack/react-table';
import { FC } from 'react';

type Props = {
  selectedImeis: string[];
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
};

export const ConnectedGroupOperationsForm: FC<Props> = ({
  selectedImeis,
  setRowSelection,
}) => {
  const {
    data: firmwaresData,
    isLoading: areFirmwaresLoading,
    isSuccess: areFirmwaresDataSuccess,
    error: firmwaresError,
  } = useGetFirmwaresQuery();

  const {
    data: trackersOutput,
    isLoading: isTrackersOutputLoading,
    isFetching: isTrackersOutputFetching,
    isSuccess: isTrackersOutputSuccess,
    error: isTrackersError,
  } = useGetAllOfTheTrackersQuery();

  const [
    triggerCommandTrackers,
    {
      data: commandTrackersDataResponce,
      error: commandTrackersDataError,
      isLoading: isCommandTrackersDataErrorLoading,
    },
  ] = useCommandTrackersMutation();
  const [
    triggerExtendCommandTrackers,
    {
      data: commandExtendTrackersDataResponce,
      error: commandExtendTrackersDataError,
      isLoading: isCommandExtendTrackersDataErrorLoading,
    },
  ] = useCommandTrackersExtendedMutation();

  if (areFirmwaresLoading || isTrackersOutputLoading) {
    return <div>Идет загрузка....</div>;
  }

  const error = [isTrackersError, firmwaresError].find(
    (err) => err !== undefined
  );

  if (error) {
    return (
      <>
        <div>Ошибка</div>
        <p>{JSON.stringify(error)}</p>
      </>
    );
  }

  const handleUpdateInformation = async (data: TrackerCommandWithParams) => {
    const { command } = data;

    try {
      if (command === 'RESET' || command === 'INFO') {
        const res = await triggerCommandTrackers(data).unwrap();

        command === 'RESET'
          ? openSuccessNotification(`Трекеры успешно перезагружены!`)
          : openSuccessNotification(
              `Информация по трекерам успешно обновлена!`
            );
      }

      if (command === 'UPDATE_FIRMWARE' || command === 'UPDATE_INTERVAL') {
        const res = await triggerExtendCommandTrackers(data).unwrap();
        command === 'UPDATE_FIRMWARE'
          ? openSuccessNotification(`Установлены прошивки трекеров`)
          : openSuccessNotification(
              `Интервал отправки сообщений для трекеров изменен`
            );
      }
    } catch (err) {
      if (isErrorWithMessage(err)) {
        openErrorNotification(err.data.detail);
        return;
      }

      switch (command) {
        case 'RESET':
          openErrorNotification(`Не удалось перезагрузить трекеры`);
          break;
        case 'INFO':
          openErrorNotification(`Не удалось обновить информацию по трекерам`);
          break;
        case 'UPDATE_FIRMWARE':
          openErrorNotification(`Не удалось установить прошивки для трекеров`);
          break;
        case 'UPDATE_INTERVAL':
          openErrorNotification(`Не удалось назначить интервал трекерам`);
          break;
        default:
          openErrorNotification(`Ошибка неизвестного происхождения`);
      }
    }
  };

  if (areFirmwaresDataSuccess && isTrackersOutputSuccess) {
    return (
      <GroupOperationsForm
        selectedImeis={selectedImeis}
        trackers={trackersOutput}
        firmwaresData={firmwaresData ?? []}
        setRowSelection={setRowSelection}
        handleUpdateInformation={handleUpdateInformation}
      />
    );
  } else {
    return null;
  }
};
