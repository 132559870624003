import { FC } from 'react';
import { GroupOperationsButtonStyled } from './styles';
import NiceModal from '@ebay/nice-modal-react';
import { GroupOperationsModal } from '../modal';
import { RowSelectionState } from '@tanstack/react-table';

type Props = {
  selectedImeis: string[];
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
};

export const GroupOperationsButton: FC<Props> = ({
  selectedImeis,
  setRowSelection,
}) => {
  const isToBeDisabled = !selectedImeis.length;

  const showGroupOperationsModal = () => {
    NiceModal.show(GroupOperationsModal, { selectedImeis, setRowSelection });
  };
  return (
    <GroupOperationsButtonStyled
      variant={isToBeDisabled ? 'outline' : 'primary'}
      onClick={() => {
        if (isToBeDisabled) return;
        showGroupOperationsModal();
      }}
    >
      Изменить
    </GroupOperationsButtonStyled>
  );
};
