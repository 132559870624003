import { stationStatusColorMap } from '../../consts/station-status-color-map';
import { StationStatusEnum } from '@shared/api/services/integration/api';
import { TruncatedPTag } from '@shared/utils/ui/styles';
import styled from 'styled-components';

export const ChargingStationInfoCardWrapper = styled.div<{
  status: StationStatusEnum;
  isSelected: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${({ status }) => stationStatusColorMap[status]};
  height: 90px;
  border-radius: 12px;
  padding: 5px;
  ${({ isSelected, status }) => {
    if (isSelected) {
      return `
        box-shadow: -1px 1px 8px -1px ${stationStatusColorMap[status]}};
      `;
    }
    return '';
  }}
  cursor: pointer;
`;

export const Title = styled(TruncatedPTag)`
  font-size: 17px;
`;

export const Property = styled(TruncatedPTag)`
  font-size: 14px;

  @media screen and (max-width: 1440px) {
    font-size: 12px;
  }
`;
