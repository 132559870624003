import { baseApi as api } from '../../../base-api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    apiAuthTokenAuthCreate: build.mutation<
      ApiAuthTokenAuthCreateApiResponse,
      ApiAuthTokenAuthCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/token-auth/`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    apiAuthTokenRefreshCreate: build.mutation<
      ApiAuthTokenRefreshCreateApiResponse,
      ApiAuthTokenRefreshCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/token-refresh/`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    apiAuthTokenVerifyCreate: build.mutation<
      ApiAuthTokenVerifyCreateApiResponse,
      ApiAuthTokenVerifyCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/auth/token-verify/`,
        method: 'POST',
        body: queryArg,
      }),
    }),
    apiUsersSelfRetrieve: build.query<
      ApiUsersSelfRetrieveApiResponse,
      ApiUsersSelfRetrieveApiArg
    >({
      query: () => ({ url: `/api/users/self` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type ApiAuthTokenAuthCreateApiResponse =
  /** status 200  */ TokenObtainPairRead;
export type ApiAuthTokenAuthCreateApiArg = TokenObtainPairWrite;
export type ApiAuthTokenRefreshCreateApiResponse =
  /** status 200  */ TokenRefreshRead;
export type ApiAuthTokenRefreshCreateApiArg = TokenRefreshWrite;
export type ApiAuthTokenVerifyCreateApiResponse =
  /** status 200  */ TokenVerify;
export type ApiAuthTokenVerifyCreateApiArg = TokenVerifyWrite;
export type ApiUsersSelfRetrieveApiResponse =
  /** status 200  */ UserBaseInfoRead;
export type ApiUsersSelfRetrieveApiArg = void;
export type TokenObtainPair = {};
export type TokenObtainPairRead = {
  access: string;
  refresh: string;
};
export type TokenObtainPairWrite = {
  username: string;
  password: string;
};
export type TokenRefresh = {};
export type TokenRefreshRead = {
  access: string;
};
export type TokenRefreshWrite = {
  refresh: string;
};
export type TokenVerify = {};
export type TokenVerifyWrite = {
  token: string;
};
export type UserBaseInfo = {
  first_name?: string;
  last_name?: string;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  surname?: string;
  counterparty_id?: string | null;
};
export type UserBaseInfoRead = {
  first_name?: string;
  last_name?: string;
  /** Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only. */
  username: string;
  surname?: string;
  role_id: number | null;
  counterparty_id?: string | null;
};
export const {
  useApiAuthTokenAuthCreateMutation,
  useApiAuthTokenRefreshCreateMutation,
  useApiAuthTokenVerifyCreateMutation,
  useApiUsersSelfRetrieveQuery,
} = injectedRtkApi;
