import { enhancedApi as api } from '../api';

export const enhancedApi = api.enhanceEndpoints({
  endpoints: {
    getElectricbusesApiElectricbusesGet: (endpoint) => {
      (endpoint.query = (params) => {
        const {
          sortedBy,
          sortedField,
          groupIds,
          manufactureIds,
          modelIds,
          statusEvIds,
          typeId,
          page,
          size,
          sohFrom,
          sohTo,
          socFrom,
          socTo,
          powerReserveFrom,
          powerReserveTo,
          mileageFrom,
          mileageTo,
        } = params;

        return {
          url: `electricbus/api/electricbuses`,
          params: {
            sorted_field: sortedField,
            sorted_by: sortedBy,
            type_id: typeId,
            group_ids: groupIds,
            model_ids: modelIds,
            manufacture_ids: manufactureIds,
            status_ev_ids: statusEvIds,
            soc_from: socFrom,
            soc_to: socTo,
            soh_from: sohFrom,
            soh_to: sohTo,
            power_reserve_from: powerReserveFrom,
            power_reserve_to: powerReserveTo,
            mileage_from: mileageFrom,
            mileage_to: mileageTo,
            page,
            size,
          },
        };
      }),
        (endpoint.providesTags = ['Electricbuses']);
    },
    createElectricbusApiElectricbusesPost: (endpoint) => {
      endpoint.query = (body) => ({
        method: 'POST',
        url: `electricbus/api/electricbuses`,
        body,
      });
      // endpoint.invalidatesTags = (result, error) => error ? [] : ['Electricbuses'],
      endpoint.invalidatesTags = ['Electricbuses'];
    },
    updateElectricbusApiElectricbusesPatch: (endpoint) => {
      (endpoint.query = (body) => ({
        method: 'PATCH',
        url: `electricbus/api/electricbuses`,
        body,
      })),
        // (endpoint.invalidatesTags = (result, error) => error ? [] : ['Electricbuses']),
        (endpoint.invalidatesTags = ['Electricbuses']);
    },
    deleteElectricbusApiElectricbusesEvIdDelete: (endpoint) => {
      (endpoint.query = (queryArg) => ({
        method: 'DELETE',
        url: `electricbus/api/electricbuses/${queryArg}`,
      })),
        // (endpoint.invalidatesTags = (result, error) => error ? [] : ['Electricbuses']),
        (endpoint.invalidatesTags = ['Electricbuses']);
    },
  },
});

export const {
  useGetElectricbusesApiElectricbusesGetQuery: useGetElectricbusesQuery,
  useCreateElectricbusApiElectricbusesPostMutation: useAddElectricbusMutation,
  useUpdateElectricbusApiElectricbusesPatchMutation:
    useUpdateElectricbusMutation,
  useDeleteElectricbusApiElectricbusesEvIdDeleteMutation:
    useDeleteElectricbusMutation,
} = enhancedApi;
