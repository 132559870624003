export function LightThemeIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="7.00003" r="2.625" stroke="currentColor" />
      <path
        d="M7 1.75003V2.80003"
        stroke="currentColor"
        stroke-linecap="round"
      />
      <path d="M7 11.2V12.25" stroke="currentColor" stroke-linecap="round" />
      <path
        d="M11.5449 4.37503L10.6356 4.90003"
        stroke="currentColor"
        stroke-linecap="round"
      />
      <path
        d="M3.36133 9.10002L2.452 9.62502"
        stroke="currentColor"
        stroke-linecap="round"
      />
      <path
        d="M2.45312 4.37508L3.36245 4.90008"
        stroke="currentColor"
        stroke-linecap="round"
      />
      <path
        d="M10.6367 9.10002L11.546 9.62502"
        stroke="currentColor"
        stroke-linecap="round"
      />
    </svg>
  );
}
