import styled from 'styled-components';

export const FormLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 240px);
  column-gap: 80px;

  padding-bottom: 156px;
`;

export const FormButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 24px 0 40px 0;

  border-top: 1px solid rgba(163, 174, 196, 0.4);
`;
