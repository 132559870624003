import NiceModal, { useModal } from '@ebay/nice-modal-react';

import { UiButton } from '@shared/ui/ui-button';
import { UiModal } from '@shared/ui/ui-modal';

import { AddTracker } from '../add-tracker';

export const AddTrackerModal = NiceModal.create(() => {
  const modal = useModal();

  return (
    <UiModal isOpen={modal.visible} onClose={modal.hide}>
      <UiModal.Header onClose={modal.hide}>Добавить трекер</UiModal.Header>
      <UiModal.Body>
        <AddTracker closeModal={modal.hide} />
      </UiModal.Body>
      {/* <UiModal.Footer>
        <UiButton variant="outline">Сбросить</UiButton>
        <UiButton variant="primary">Добавить</UiButton>
      </UiModal.Footer> */}
    </UiModal>
  );
});
