import styled from 'styled-components';

const dynamicWidth = (
  props: { showVehicleInfo: boolean },
  duration: number
) => {
  const { showVehicleInfo } = props;

  if (showVehicleInfo) {
    return `calc(100% - ${duration}px)`;
  }

  return '100%';
};

export const Outer = styled.div<{ showVehicleInfo: boolean }>`
  position: absolute;
  width: ${(props) => dynamicWidth(props, 401)};
  top: 40px;
  transition: width 0.7s ease;

  @media screen and (max-width: 1920px) {
    width: ${(props) => dynamicWidth(props, 368)};
  }

  @media screen and (max-width: 1440px) {
    width: ${(props) => dynamicWidth(props, 319)};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const TransportTitle = styled.p`
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
`;
