import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import {
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { Mutex } from 'async-mutex';

import { AuthState } from '@shared/model/auth';
import {
  clearSessionCredentials,
  setSessionCredentials,
} from '@shared/redux/slices/auth/session-slice';
import {
  clearCredentials,
  setCredentials,
} from '@shared/redux/slices/auth/storage-slice';

import { baseQuery } from './base-query';

const mutex = new Mutex();

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  const refreshToken =
    (api.getState() as RootState).auth.refresh ||
    (api.getState() as RootState).authSession.refresh;

  const rememberUser = (api.getState() as RootState).auth.access;

  if (result.error?.status === 403) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await baseQuery(
          {
            url: 'api/auth/token-refresh/',
            method: 'POST',
            body: { refresh: refreshToken },
            // headers: {
            //   Authorization: `Bearer ${refreshToken}`,
            // },
          },
          api,
          extraOptions
        );

        if (refreshResult.data) {
          const credentials = refreshResult.data as Pick<AuthState, 'access'>;

          rememberUser
            ? api.dispatch(setCredentials(credentials))
            : api.dispatch(setSessionCredentials(credentials));

          result = await baseQuery(args, api, extraOptions);
        } else {
          rememberUser
            ? api.dispatch(clearCredentials())
            : api.dispatch(clearSessionCredentials());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();

      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};
