import { useSearchParams } from 'react-router-dom';

import { QUERY_PARAM } from '@shared/consts/server';
import { SortDirection } from '@tanstack/react-table';

const { ordering, type, page } = QUERY_PARAM;

export const useSortingChange = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // newOrdering => headerId

  const onSortingChange = (newOrdering: string, newType: SortDirection) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());

    const currentOrdering = searchParams.get(ordering);
    const currentType = searchParams.get(type);

    // const dateFrom = searchParams.get('dateFrom');
    // const dateTo = searchParams.get('dateTo');

    if (currentOrdering === null) {
      updatedSearchParams.set(ordering, newOrdering);
      updatedSearchParams.set(type, newType);

      updatedSearchParams.delete(page);
    } else if (currentOrdering === newOrdering) {
      if (newType === currentType) {
        updatedSearchParams.delete(ordering);
        updatedSearchParams.delete(type);
      } else {
        updatedSearchParams.set(type, newType);
      }
    } else if (currentOrdering !== newOrdering) {
      updatedSearchParams.set(ordering, newOrdering);
      updatedSearchParams.set(type, newType);
    }

    // Надо ли занулять page всегда?

    // if (newOrdering === currentOrdering && currentType === '0') {
    //   updatedSearchParams.set(type, '1');

    //   updatedSearchParams.delete(page);
    // } else if (newOrdering === currentOrdering && currentType === '1') {
    //   updatedSearchParams.delete(type);
    //   updatedSearchParams.delete(page);

    //   if (dateFrom === null && dateTo === null) {
    //     updatedSearchParams.delete(ordering);
    //   }
    // } else if (newOrdering !== currentOrdering) {
    //   updatedSearchParams.delete('dateFrom');
    //   updatedSearchParams.delete('dateTo');

    //   updatedSearchParams.set(ordering, newOrdering);
    //   updatedSearchParams.set(type, '0');
    //   updatedSearchParams.delete(page);
    // } else {
    //   // Mb redundant
    //   updatedSearchParams.set(ordering, newOrdering);
    //   updatedSearchParams.set(type, '0');

    //   updatedSearchParams.delete(page);
    // }

    setSearchParams(updatedSearchParams.toString());
  };

  return onSortingChange;
};
