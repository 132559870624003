import { FormColumn } from '@shared/ui/form/styles';
import { Select } from 'antd';
import styled from 'styled-components';
import { StyledUiButton } from '@shared/ui/ui-button/styles';
import Radio from 'antd/es/radio';
import { CustomInput } from '@shared/ui/custom-input';

export const FormLayout = styled.div`
  display: grid;
  grid-template-columns: 240px 240px 315px;
  column-gap: 40px;

  padding-bottom: 64px;
  padding-right: 10px;
`;

export const InputGroupContent = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;

  & > input {
    width: 100%;
  }
`;

// TODO: Delete redundant
export const StyledAntdSelect = styled(Select)`
  // border: 2px solid #666e7e;
  color: #a3aec4;

  & .ant-select-selector {
    border: 2px solid #666e7e !important;
    color: #f7faff;
    border-radius: 12px;
    padding: 12px;
  }

  &:hover .ant-select-selector {
    border: 2px solid #a3aec4 !important;
    color: #f7faff;
    border-radius: 12px;
  }

  & .ant-select-selection-placeholder {
    color: #a3aec4;
  }

  /* &.ant-select-open:hover .ant-select-selector {
    border: none;
  } */

  &.ant-select-open .ant-select-selector {
    border: 2px solid #09fba8 !important;
    border-radius: 12px 12px 0 0;
  }

  &.ant-select-open span.ant-select-arrow {
    color: #09fba8;
    transform: rotate(180deg);
  }

  &.ant-select-open.ant-select-focused {
    box-shadow: none;

    & .ant-select-selector {
      box-shadow: none !important;
      border: 2px solid #09fba8;
      border-radius: 12px 12px 0 0;
      border-color: #09fba8 !important;
    }

    & {
      border-radius: 12px 12px 0 0;
    }
  }

  /* &.ant-select-focused {
    box-shadow: none;

    & .ant-select-selector {
      box-shadow: none !important;
      border: 2px solid #09fba8;
      border-radius: 12px 12px 0 0;
      border-color: #09fba8 !important;
    }

    & {
      border-radius: 12px 12px 0 0;
    }
  } */

  & .ant-select-selector {
    background-color: transparent !important;
    // border: none !important;
  }

  & .ant-select-selector span.ant-select-selection-item {
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 18px */
    color: #f7faff;
  }

  & span.ant-select-arrow {
    color: #a3aec4;
  }

  &:hover {
    // border: 2px solid #a3aec4;
    color: #f7faff;
  }

  &:focus {
    // border: 2px solid #09fba8;
    color: #f7faff;
    outline: none;
  }

  & .ant-select-selector:after {
    display: none;
  }

  &.ant-select-open
    .ant-select-selector
    .ant-select-selection-overflow
    .ant-select-selection-overflow-item
    .ant-select-selection-item {
    padding: 0;
    margin: 0;
    background-color: #32353f;
  }

  &
    .ant-select-selector
    .ant-select-selection-overflow
    .ant-select-selection-overflow-item
    .ant-select-selection-item
    .ant-select-selection-item-content {
    margin-inline-end: 0;
  }

  &
    .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest
    .ant-select-selection-item {
    padding: 0;
    margin: 0;
    border: none;
    background-color: #32353f;
    display: flex;
    align-items: center;
  }

  &:not(.ant-select-open)
    .ant-select-selector
    .ant-select-selection-overflow
    .ant-select-selection-overflow-item:not(.ant-select-selection-overflow-item-suffix):not(.ant-select-selection-overflow-item-rest) {
    &:has(~ .ant-select-selection-overflow-item):after {
      // content: ',';
    }
  }

  // Только для опен

  // Мб добавить isOpen класс
  /* &
    .ant-select-selector
    .ant-select-selection-overflow
    .ant-select-selection-overflow-item-rest,
  .ant-select-selector
    .ant-select-selection-overflow
    .ant-select-selection-overflow-item-suffix {
    display: none;
  } */
`;

export const FormButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 24px 10px 40px 0;

  border-top: 1px solid rgba(163, 174, 196, 0.4);
`;

export const CustomFormColumn = styled(FormColumn)`
  min-height: 256px;
  max-height: 416px;
`;

export const AbstractTrackersList = styled.ul`
  min-height: 123px;
  max-height: 282px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 8px;
    height: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: '#22B0F8';
    border-radius: 8px;
  }
`;

export const TrackersList = styled(AbstractTrackersList)``;

export const FirmwaresList = styled(AbstractTrackersList)``;

export const TrackersContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CustomUiButton = styled(StyledUiButton)<{ disabled?: boolean }>`
  margin-top: 8px;
  ${({ disabled }) => {
    if (disabled) {
      return 'cursor: not-allowed;';
    }
  }}
`;

export const UpdateFirmwareButton = styled(CustomUiButton)`
  margin-top: auto;
`;

export const CustomRadioButton = styled(Radio)`
  color: #f7faff;
  width: calc(100% - 9px);

  &.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: #09fba8;
    background-color: #09fba8;
  }
`;

export const IntevalInputField = styled(CustomInput)<{
  isErrorValue?: boolean;
}>`
  width: 100%;

  ${({ isErrorValue }) => {
    if (isErrorValue) {
      return `
        border: 2px solid #fb0909;
        &:focus {
          border: 2px solid #fb0909;
        }
      `;
    }
  }}
`;
