export const countPageSize = (innerGapSize = 270, rowSize = 35): number => {
  const innerHeight = window.innerHeight;
  const contentSize = innerHeight - innerGapSize;

  if (contentSize <= rowSize) {
    return 1;
  }

  return Math.floor((innerHeight - innerGapSize) / rowSize);
};
