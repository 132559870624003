import { RowSelectionState } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

export const useSelectTrackers = () => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  useEffect(() => {
    return () => {
      setRowSelection({});
    };
  }, []);

  return {
    selectedImeis: Object.keys(rowSelection),
    rowSelection,
    setRowSelection,
  };
};
