import { Collapse } from 'antd';
import styled from 'styled-components';

export const MenuItemContainer = styled.div`
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  color: #f7faff;
  align-items: center;

  padding: 12px 16px;
  border-radius: 16px !important;

  background-color: transparent;

  &.active {
    background-color: rgba(21, 233, 160, 0.4);
    color: #09fba8;
  }

  &:not(.active):hover {
    background-color: rgba(163, 174, 196, 0.2);
    transition: background-color 0.3s ease;
  }
`;

export const StyledParkCollapses = styled(Collapse)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 400px;
  width: 100%;

  & > .ant-collapse-item {
    &:not(:last-child) {
      // margin-bottom: 28px;
    }
  }

  & .ant-collapse-item {
    border-bottom: none;

    & .ant-collapse-header.ant-collapse-header-collapsible-only {
      border-radius: 16px;
      align-items: center;

      & .ant-collapse-expand-icon {
        padding: 0;
        height: unset;
      }
    }

    & svg {
      width: 16px;

      @media screen and (max-width: 1920px) {
        width: 14px;
      }

      @media screen and (max-width: 1440px) {
        width: 11px;
      }
    }

    & .ant-collapse-content {
      // margin-top: 28px; //
    }

    & .ant-collapse-content {
      background-color: transparent !important;
      border-top: none !important;

      & .ant-collapse-content-box {
        // padding: 0 0 0 16px !important;
        padding: 14px 0 0 16px !important;
      }
    }

    & .ant-collapse-content.ant-collapse-content-active {
      & .ant-collapse-content-box {
        // padding: 0 0 0 16px !important;
        padding: 10px 0 0 16px !important;
      }
    }
  }

  & .ant-collapse-content-box .ant-collapse-item {
    &:not(:last-child) {
      // margin-bottom: 28px;
    }
  }

  &
    .ant-collapse-item.ant-collapse-item-active
    > .ant-collapse-header
    > .ant-collapse-expand-icon {
    color: #09fba8;
    transform: rotate(180deg);
    padding: 0;
  }

  & .ant-collapse-item {
    & .ant-collapse-header {
      padding: 0;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      color: #f7faff;
      border-radius: 16px;
    }
  }

  & .ant-collapse-item.empty {
    & > .ant-collapse-content > .ant-collapse-content-box {
      padding-top: 0 !important;
    }

    &
      > .ant-collapse-content.ant-collapse-content-active
      > .ant-collapse-content-box {
      padding-top: 0 !important;
    }
  }
`;

export const StyledConvoyCollapses = styled(StyledParkCollapses)``;

export const LeafsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  //
  // padding: 28px 0;
`;
