import { FC } from 'react';
import { Select } from 'antd';
import { useForm, Controller, type FieldValues } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import { useAppDispatch, useAppSelector } from '@app/hooks';

import { CustomInput } from '@shared/ui/custom-input';
import {
  selectStationsFilters,
  setFilters,
} from '@shared/redux/slices/charging-station/filters';
import { resetPageByFiltering } from '@shared/utils/table/reset-page-by-filtering';
import {
  CustomSelect,
  CustomSelectMultiple,
} from '@shared/ui/custom-select-new';

import { useData } from './use-data';

import { FormSchema, FormSchemaType } from '../../consts/schema';
import { UiButton } from '@shared/ui/ui-button';
import {
  FieldErrorContainer,
  FormColumn,
  FormItemContainer,
  FormItemLabel,
} from '@shared/ui/form/styles';

import {
  FormButtonsContainer,
  FormLayout,
  InputGroupContent,
  StyledAntdSelect,
} from './styles';

type Props = {
  closeModal: () => void;
};

export const FiltersForm: FC<Props> = ({ closeModal }) => {
  const filters = useAppSelector(selectStationsFilters);
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, error, parksFromEPark } = useData();

  const {
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema),
    defaultValues: filters,
  });

  const stationName = watch('stationName');
  const stationId = watch('stationId');
  const stationStatuses = watch('stationStatuses');
  const powerFrom = watch('powerFrom');
  const powerTo = watch('powerTo');
  const portsCount = watch('portsCount');
  const parkNames = watch('parkNames');
  const dynamicBalancingMode = watch('dynamicBalancingMode');
  const type = watch('type');

  const onSubmit = async (data: FieldValues) => {
    resetPageByFiltering(searchParams, setSearchParams);
    dispatch(setFilters(data));

    closeModal();
  };

  const handleReset = () => {
    setValue('stationName', undefined);
    setValue('stationId', undefined);
    setValue('stationStatuses', undefined);
    setValue('powerFrom', undefined);
    setValue('powerTo', undefined);
    setValue('portsCount', []);
    setValue('parkNames', []);
    setValue('dynamicBalancingMode', undefined);
  };

  const mockParks = [
    { value: 'Энергия', label: 'Энергия' },
    { value: 'Красная Пахра', label: 'Красная Пахра' },
    { value: 'Салтыковка', label: 'Салтыковка' },
    { value: 'Волгодонск', label: 'Волгодонск' },
    { value: 'Пермь', label: 'Пермь' },
    { value: 'Курск', label: 'Курск' },
    { value: 'Митино Тесты', label: 'Митино Тесты' },
    { value: 'Митино', label: 'Митино' },
    { value: 'Глобус', label: 'Глобус' },
    { value: 'Щербинка', label: 'Щербинка' },
    { value: 'Электросуда', label: 'Электросуда' },
    { value: 'Волгоград', label: 'Волгоград' },
    { value: 'Таганрог', label: 'Таганрог' },
    { value: 'Ростов-на-Дону', label: 'Ростов-на-Дону' },
    { value: 'Ярославль', label: 'Ярославль' },
    { value: 'Волгоград(ЕПРОМ)', label: 'Волгоград(ЕПРОМ)' },
    { value: 'Санкт-Петербург(ЕПРОМ)', label: 'Санкт-Петербург(ЕПРОМ)' },
    { value: 'Липецк', label: 'Липецк' },
    { value: 'ГРПЗ', label: 'ГРПЗ' },
    { value: 'Нижний Новгород', label: 'Нижний Новгород' },
    { value: 'Обнинск', label: 'Обнинск' },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="password">
      <FormLayout>
        <FormColumn>
          <FormItemContainer>
            <FormItemLabel>ID станции</FormItemLabel>
            <Controller
              name="stationId"
              control={control}
              render={({ field, fieldState }) => (
                <CustomInput
                  {...field}
                  value={stationId}
                  inputSize="md"
                  placeholder="ID станции"
                />
              )}
            />
            <FieldErrorContainer></FieldErrorContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormItemLabel>По названию</FormItemLabel>
            <Controller
              name="stationName"
              control={control}
              render={({ field, fieldState }) => (
                <CustomInput
                  {...field}
                  value={stationName}
                  inputSize="md"
                  placeholder="Название станции"
                />
              )}
            />
            <FieldErrorContainer></FieldErrorContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormItemLabel>По парку</FormItemLabel>
            <Controller
              name="parkNames"
              control={control}
              render={({ field, fieldState }) => (
                <CustomSelectMultiple {...field} options={mockParks} />
              )}
            />
            <FieldErrorContainer>
              {errors?.parkNames && <p>{errors.parkNames.message}</p>}
            </FieldErrorContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormItemLabel>По типу</FormItemLabel>
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <CustomSelect
                    {...field}
                    value={type}
                    options={[{ value: 'УБЗС', label: 'УБЗС' }]}
                  />
                );
              }}
            />
            <FieldErrorContainer></FieldErrorContainer>
          </FormItemContainer>
        </FormColumn>
        <FormColumn>
          <FormItemContainer>
            <FormItemLabel>По статусу</FormItemLabel>
            <Controller
              name="stationStatuses"
              control={control}
              render={({ field, fieldState }) => (
                <CustomSelect
                  {...field}
                  value={stationStatuses}
                  options={[
                    { value: 'READY', label: 'В работе' },
                    { value: 'OFFLINE', label: 'Нет связи' },
                    { value: 'UNAVAILABLE', label: 'Ремонт' },
                    { value: 'DEFECTIVE', label: 'Неисправна' },
                  ]}
                  allowClear
                  onClear={() => setValue('stationStatuses', undefined)}
                />
              )}
            />
            <FieldErrorContainer>
              {errors?.stationStatuses && (
                <p>{errors.stationStatuses.message}</p>
              )}
            </FieldErrorContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormItemLabel>По количеству портов</FormItemLabel>
            <Controller
              name="portsCount"
              control={control}
              render={({ field, fieldState }) => (
                <CustomSelectMultiple
                  {...field}
                  options={[
                    { value: 1, label: '1' },
                    { value: 2, label: '2' },
                    { value: 3, label: '3' },
                    { value: 4, label: '4' },
                    { value: 5, label: '5' },
                    { value: 6, label: '6' },
                  ]}
                />
              )}
            />
            <FieldErrorContainer>
              {errors?.portsCount && <p>{errors.portsCount.message}</p>}
            </FieldErrorContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormItemLabel>Балансировка включена</FormItemLabel>
            <Controller
              name="dynamicBalancingMode"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <CustomSelect
                    {...field}
                    value={dynamicBalancingMode as unknown as string}
                    options={[
                      { value: true as unknown as string, label: 'Да' },
                      { value: false as unknown as string, label: 'Нет' },
                    ]}
                    allowClear
                    onClear={() => setValue('dynamicBalancingMode', undefined)}
                  />
                );
              }}
            />
            <FieldErrorContainer></FieldErrorContainer>
          </FormItemContainer>
          <FormItemContainer>
            <FormItemLabel>По разрешённой мощности</FormItemLabel>
            <InputGroupContent>
              <Controller
                name="powerFrom"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    value={powerFrom}
                    inputSize="md"
                    placeholder="От"
                  />
                )}
              />
              {'-'}
              <Controller
                name="powerTo"
                control={control}
                render={({ field, fieldState }) => (
                  <CustomInput
                    {...field}
                    value={powerTo}
                    inputSize="md"
                    placeholder="До"
                  />
                )}
              />
            </InputGroupContent>
            <FieldErrorContainer>
              {(errors?.powerFrom || errors?.powerTo) && (
                <p>{errors.powerFrom?.message || errors.powerTo?.message}</p>
              )}
            </FieldErrorContainer>
          </FormItemContainer>
        </FormColumn>
      </FormLayout>
      <FormButtonsContainer>
        <UiButton variant="outline" onClick={handleReset} type="button">
          Сбросить
        </UiButton>
        <UiButton variant="primary" type="submit">
          Применить
        </UiButton>
      </FormButtonsContainer>
    </form>
  );
};
