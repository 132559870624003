export function LightningIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path d="M3 11.5L12 0V8.5H17L8 20V11.5H3Z" fill="currentColor" />
    </svg>
  );
}
