import { Flex } from 'antd';

import { LightningIcon } from '@shared/icons/lightning';
import { VehicleStatusesRead } from '@shared/api/services/common/api';

import { MaintenanceIcon } from './icons/maintenance';
import { IconWrapper, StyledBadge } from './styles';

const STATUS_ICON: Record<number, JSX.Element> = {
  4: (
    <IconWrapper>
      <LightningIcon />
    </IconWrapper>
  ),
  3: <MaintenanceIcon />,
};

type Props = {
  status: VehicleStatusesRead;
};

export function VehicleStatusBadge({ status }: Props) {
  const { id, name } = status;

  return (
    <StyledBadge status_id={id}>
      <Flex gap={2} align="center">
        {STATUS_ICON[id]}
        {name}
      </Flex>
    </StyledBadge>
  );
}
