import { FiltersButton } from '@features/charging-station/filters';
import { HeaderLayout } from '../layout';
import { ContentWrapper, Outer, ChargingStationsTitle } from './styles';
import { useParams } from 'react-router-dom';
import { useGetGroupsQuery } from '@shared/api/services/common/enhanced';

export function ElectrobusExpandedInfoPageHeader() {
  const { eParkId, convoyId, eBusId } = useParams();

  const { data, isLoading, error, isSuccess } = useGetGroupsQuery(undefined, {
    skip: false,
  });

  const park = data?.find(({ id }) => Number(id) === Number(eParkId));
  const convoy = park?.children?.find(({ id }) => String(id) === convoyId);
  const ebus = convoy?.vehicles?.find(({ id }) => id === eBusId);

  const title = `${park?.name ?? ''}, ${convoy?.name ?? ''}, ${
    ebus?.grn ?? ''
  }`;

  const content = (
    <ContentWrapper>
      <ChargingStationsTitle>{isSuccess ? title : ''}</ChargingStationsTitle>
    </ContentWrapper>
  );

  return (
    <Outer>
      <HeaderLayout content={content} isStadardHeader={true} />
    </Outer>
  );
}
