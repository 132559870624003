import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { Spin } from 'antd';

import { useDeleteElectricbusMutation } from '@shared/api/services/electricbus/enhanced';
import { Electricbus } from '@shared/api/services/electricbus/types';
import {
  openErrorNotification,
  openSuccessNotification,
} from '@shared/lib/notification';
import { ConfirmDialog } from '@shared/ui/confirm-dialog';
import { UiModal } from '@shared/ui/ui-modal';

import { DELETE_ERROR, DELETE_SUCCESS } from '../../consts';
import {
  DeleteModalContainer,
  DeleteModalInfo,
  DeleteModalText,
  DeleteModalTitle,
} from '@shared/ui/ui-modal/styles';
import { TrackerOutput } from '@shared/api/services/tracker/api';
import { useDeleteTrackerMutation } from '@shared/api/services/tracker/enhanced';

type Props = {
  tracker: TrackerOutput;
  closeParentModal: () => void;
};

export const DeleteTrackerModal = NiceModal.create(
  ({ tracker, closeParentModal }: Props) => {
    const modal = useModal();

    const { imei } = tracker;

    const [trigger, { isLoading }] = useDeleteTrackerMutation();

    const handleSubmit = async () => {
      try {
        const res = await trigger(imei).unwrap();

        openSuccessNotification(`${DELETE_SUCCESS} ${imei}`);
      } catch (err) {
        const errorText = `${DELETE_ERROR} ${imei}`;

        openErrorNotification(errorText);
      } finally {
        modal.hide();

        closeParentModal();
      }
    };

    return (
      <UiModal isOpen={modal.visible} onClose={modal.hide}>
        <UiModal.Body style={{ margin: '0', padding: '0', border: 'none' }}>
          <Spin spinning={isLoading}>
            <DeleteModalContainer>
              <DeleteModalInfo>
                <DeleteModalTitle>Подтверждение</DeleteModalTitle>
                <DeleteModalText>{`Вы уверены, что хотите удалить трекер № ${imei} ?`}</DeleteModalText>
              </DeleteModalInfo>
              <ConfirmDialog
                onCancel={modal.hide}
                onConfirm={handleSubmit}
                loading={false}
              />
            </DeleteModalContainer>
          </Spin>
        </UiModal.Body>
      </UiModal>
    );
  }
);
