import { clearCredentials } from '@shared/redux/slices/auth/storage-slice';

import { useAppDispatch } from '@app/hooks';

import { LogoutIcon } from '../icons/logout';
import { ButtonLabel, StyledButton } from './styles';
import { clearSessionCredentials } from '@shared/redux/slices/auth/session-slice';

export function LogoutButton() {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(clearCredentials());
    dispatch(clearSessionCredentials());
  };

  return (
    <StyledButton onClick={handleClick}>
      <LogoutIcon />
      <ButtonLabel>Выход</ButtonLabel>
    </StyledButton>
  );
}
